import { AdvantageProps } from "./Advantage";

export const PAYROLL_HR_ADVANTAGES: AdvantageProps[] = [
  {
    iconName: "IconPercentage",
    description: "Custo ZERO para as empresas.",
  },
  {
    iconName: "IconCreditCard",
    description:
      "Benefício de saúde financeira para todos os colaboradores com 6 meses de casa.",
  },
  {
    iconName: "IconChartBar",
    description: "Melhora dos indicadores de produtividade e retenção.",
  },
];

export const PAYROLL_EMPLOYEE_ADVANTAGES: AdvantageProps[] = [
  {
    iconName: "IconDevices",
    description: "Contratação 100% digital, fácil e segura, sem burocracia.",
  },
  {
    iconName: "IconPercentage",
    description: "Pelo menos 30% mais barato que outras modalidades.",
  },
  {
    iconName: "IconCircleCheck",
    description: "Válido também para negativados.",
  },
];
