export enum LoanRequestStatus {
  APPROVED = "APPROVED",
  REJECTED = "REJECTED",
  ANALYSING = "ANALYSING",
}

export interface LoanRequest {
  id: string;
  createdAt: string;
  status: LoanRequestStatus;
  value: number;
  documentNumber: string;
  name: string;
}
