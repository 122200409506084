import Typography from "../../../../components/Typography";
import styled from "styled-components";

export const DefinitionBackgroundStyled = styled.div`
  background-color: ${({ theme }) => theme.colors.secondary[99]};
  padding: ${({ theme }) => theme.spacings.l};
`;

export const DefinitionTitleStyled = styled(Typography.Headline5)`
  display: inline;

  & + & {
    margin-left: ${({ theme }) => theme.spacings.xs4};
  }
`;
