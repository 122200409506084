import { EmployeeStatus } from "../../types/employees";
import styled from "styled-components";

interface IStatusTag {
  status;
}

export const StyledTag = styled.div<IStatusTag>`
  border-radius: 24px;
  width: fit-content;
  font-size: 12px;
  padding: 4px 12px;
  ${({ status }) => {
    switch (status) {
      case EmployeeStatus.Active:
        return `
          color: #094338;
          background: #D7F9F3;
        `;

      case EmployeeStatus.Inactive:
        return `
          color: #1D161B;
          background: #EBE6E9;
        `;

      case EmployeeStatus.Expired:
        return `
          color: #4C2900;
          background: #FFECD6;
        `;
    }
  }}
`;
