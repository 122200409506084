import { RouterOutput } from "server/src/routers/trpc";
import { trpc } from "../../api/client";

export enum TemplateTypes {
  Full = "FULL",
  ActiveOnly = "ACTIVE_ONLY",
  Empty = "EMPTY",
}

type GetUpsertEmployeeTemplateFileOutput =
  RouterOutput["fileProcessing"]["getUpsertEmployeeTemplateFileLink"];

interface GetUpsertEmployeeTemplateFileUseCaseInput {
  companyId: string;
  template: TemplateTypes;
  keepPreviousData?: boolean;
  onSuccess?: (data: GetUpsertEmployeeTemplateFileOutput) => void;
  onError?: () => void;
  enabled?: boolean;
}

export const getUpsertEmployeeTemplateFileLinkUseCase = ({
  companyId,
  template,
  keepPreviousData = false,
  onSuccess,
  onError,
  enabled = true,
}: GetUpsertEmployeeTemplateFileUseCaseInput) => {
  const { data, isLoading, isFetching, isRefetching } =
    trpc.fileProcessing.getUpsertEmployeeTemplateFileLink.useQuery(
      { companyId, template },
      {
        keepPreviousData,
        retry: false,
        retryOnMount: false,
        refetchOnWindowFocus: false,
        onSuccess,
        onError,
        enabled,
      }
    );

  return {
    data,
    isLoading: isLoading || isFetching || isRefetching,
  };
};
