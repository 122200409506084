import { RouterOutput } from "server/src/routers/trpc";
import { trpc } from "../../../api/client";

export type GetEmployeesCountOutput =
  RouterOutput["employees"]["getEmployeesCount"];

export const getEmployeesCountUseCase = (companyId: string) => {
  const { data, isFetching, isLoading, refetch } =
    trpc.employees.getEmployeesCount.useQuery(
      { companyId },
      { refetchOnWindowFocus: false }
    );

  return { data, isFetching, isLoading, refetch };
};
