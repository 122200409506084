import { useSelectedCompany } from "@flash-tecnologia/hros-web-utility";
import { useRef, useState } from "react";
import { deactivateEmployeesUseCase } from "../useCases/deactivateEmployeesUseCase";

export interface DeactivationCandidate {
  employeeId: string;
  name: string;
  documentNumber: string;
  companyId: string;
}

export interface HandleDeactivateClickInput {
  deactivationCandidates: DeactivationCandidate[];
}

export function useDeactivateEmployeesViewModel() {
  const [deactivationCandidates, setDeactivationCandidates] = useState<
    DeactivationCandidate[]
  >([]);

  const onDeactivateSuccess = useRef<() => void>();

  const [confirmDeactivationModalTitle, setConfirmDeactivationModalTitle] =
    useState("");

  const [
    confirmDeactivationModalVisibility,
    setConfirmDeactivationModalVisibility,
  ] = useState(false);

  const hideConfirmDeactivationModal = () => {
    setDeactivationCandidates([]);
    setConfirmDeactivationModalTitle("");
    setConfirmDeactivationModalVisibility(false);
  };

  const openConfirmDeactivationModal = () => {
    setConfirmDeactivationModalVisibility(true);
  };
  const { selectedCompany } = useSelectedCompany();
  const handleConfirmDeactivationSuccess = () => {
    hideConfirmDeactivationModal();
    onDeactivateSuccess.current?.();
  };

  const {
    isLoading: isDeactivationLoading,
    deactivateEmployeesMutation: handleDeactivateEmployees,
  } = deactivateEmployeesUseCase({
    companyId: selectedCompany.id,
    onSuccess: handleConfirmDeactivationSuccess,
  });

  const handleDeactivateClick = (
    deactivationCandidates: DeactivationCandidate[] = [],
    handleDeactivateSuccess?: () => void
  ) => {
    const deactivationCandidatesCount = deactivationCandidates.length;
    if (!!deactivationCandidatesCount) {
      const isSingleEmployeeDeactivation = deactivationCandidatesCount === 1;
      if (isSingleEmployeeDeactivation) {
        const employeeName = deactivationCandidates[0].name;
        const singleCandidateModalTitle = `Tem certeza que deseja desabilitar ${employeeName}?`;
        setConfirmDeactivationModalTitle(singleCandidateModalTitle);
      } else {
        const multipleCandidatesModalTitle = `Tem certeza que deseja desabilitar ${deactivationCandidatesCount} pessoas?`;
        setConfirmDeactivationModalTitle(multipleCandidatesModalTitle);
      }
      setDeactivationCandidates(deactivationCandidates);
      onDeactivateSuccess.current = handleDeactivateSuccess;
      openConfirmDeactivationModal();
    }
  };

  const handleConfirmDeactivation = () => {
    const employees = deactivationCandidates.map(
      ({ employeeId, documentNumber, companyId }) => ({
        employeeId,
        documentNumber,
        companyId,
      })
    );
    handleDeactivateEmployees(employees);
  };

  return {
    confirmDeactivationModalTitle,
    confirmDeactivationModalVisibility,
    handleDeactivateCancel: hideConfirmDeactivationModal,
    handleDeactivateClick,
    handleConfirmDeactivation,
    isDeactivationLoading,
    onDeactivateClick: handleDeactivateClick,
  };
}
