import { Grid } from "@mui/material";
import { useTheme } from "styled-components";
import Typography from "../../../../components/Typography";
import PartnerAdvantage, { PartnerAdvantageProps } from "./PartnerAdvantage";
import PartnerLogo from "./assets/PartnerLogo";
import { PartnerAdvantagesWrapper } from "./styled";

interface PartnerAdvantagesProps {
  partnerAdvantages: PartnerAdvantageProps[];
}

const PartnerAdvantages = ({ partnerAdvantages }: PartnerAdvantagesProps) => {
  const theme = useTheme();
  return (
    <PartnerAdvantagesWrapper>
      <Grid
        alignItems={"flex-start"}
        columnSpacing={{ xs: theme.spacings.l }}
        container
        justifyContent={"center"}
        rowSpacing={theme.spacings.m}
      >
        <Grid
          columnSpacing={{ xs: theme.spacings.xs4 }}
          container
          justifyContent={"center"}
          item
        >
          <Grid item xs={"auto"}>
            <Typography.Headline6>{"Uma parceria com a "}</Typography.Headline6>
          </Grid>
          <Grid item xs={"auto"}>
            <PartnerLogo />
          </Grid>
        </Grid>
        {partnerAdvantages.map(({ icon, title, description }, index) => (
          <Grid
            container
            item
            justifyContent={"center"}
            key={index}
            xs={12}
            sm={6}
            md={4}
          >
            <PartnerAdvantage
              description={description}
              icon={icon}
              title={title}
            />
          </Grid>
        ))}
      </Grid>
    </PartnerAdvantagesWrapper>
  );
};

export default PartnerAdvantages;
