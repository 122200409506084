import { useEffect, useState } from "react";
import { GetEmployeesOutput } from "../../../useCases/getEmployeesUseCase";
import { dayjs } from "@utils/dayjs";
import { toCurrency } from "@utils/masks/toCurrency";
import { UpsertEmployeeInputDTO } from "@pages/PayrollLoan/useCases/upsertEmployeeUseCase";

const MAX_SALARY_PERCENTAGE_PER_INSTALLMENT = 0.35;

export const useEditEmployeeModalViewModel = (
  employee: GetEmployeesOutput["employees"][0],
  handleUpsertEmployee: (employeeData: UpsertEmployeeInputDTO) => void
) => {
  const [grossSalary, setGrossSalary] = useState<number | undefined>();
  const [netSalary, setNetSalary] = useState<number | undefined>();
  const [limitPerInstallment, setLimitPerInstallment] = useState<
    number | undefined
  >();
  const [hiredAt, setHiredAt] = useState<dayjs.Dayjs | undefined>();
  const [isHiredAtDisabled, setIsHiredAtDisabled] = useState(false);

  useEffect(() => {
    setGrossSalary(employee.grossSalary);
    setNetSalary(employee.netSalary);
    setLimitPerInstallment(employee.limitPerInstallment);
    setHiredAt(employee.hiredAt ? dayjs(employee.hiredAt) : undefined);
    setIsHiredAtDisabled(!!employee.hiredAt);
  }, [employee]);

  const handleChangeGrossSalary = (data: string) =>
    setGrossSalary(Number(data.replace(/\D/g, "")));

  const handleChangeNetSalary = (data: string) => {
    const newNetSalary = Number(data.replace(/\D/g, ""));
    setNetSalary(newNetSalary);
    const maxLimitPerInstallment = Math.round(
      newNetSalary * MAX_SALARY_PERCENTAGE_PER_INSTALLMENT
    );
    setLimitPerInstallment(maxLimitPerInstallment);
  };

  const handleChangeLimitPerInstallment = (data: string) =>
    setLimitPerInstallment(Number(data.replace(/\D/g, "")));

  const handleChangeHiredAt = (date: dayjs.Dayjs) => setHiredAt(date);

  const getMaximumLimitPerInstallment = () => {
    return toCurrency(MAX_SALARY_PERCENTAGE_PER_INSTALLMENT * (netSalary ?? 0));
  };

  const handleSubmit = () => {
    const employeeData: UpsertEmployeeInputDTO = {
      employeeId: employee.id,
      companyId: employee.companyId,
      documentNumber: employee.cpf,
      grossSalary: grossSalary as number,
      hiredAt: hiredAt?.toISOString() as string,
      limitPerInstallment: limitPerInstallment as number,
      netSalary: netSalary as number,
    };
    handleUpsertEmployee(employeeData);
  };

  const isNetSalaryValid = (netSalary ?? 0) < (grossSalary ?? 0);
  const isLimitPerInstallmentValid =
    (limitPerInstallment ?? 0) <=
    Math.round(MAX_SALARY_PERCENTAGE_PER_INSTALLMENT * (netSalary ?? 0));

  const displayNetSalaryError = netSalary !== undefined && !isNetSalaryValid;

  const netSalaryHelperText = displayNetSalaryError
    ? 'O valor do "Salário líquido" não pode ser maior que o valor do "Salário bruto".'
    : undefined;

  const limitPerInstallmentHelperText = isLimitPerInstallmentValid
    ? "A Margem consignável não pode ser superior a 35% do salário líquido."
    : `Insira um valor menor. A Margem consignável não pode ser superior a 35% do salário líquido. Nesse caso, o valor máximo é de ${getMaximumLimitPerInstallment()}.`;

  const isSubmitButtonEnabled =
    !!netSalary &&
    !!grossSalary &&
    !!limitPerInstallment &&
    isNetSalaryValid &&
    hiredAt?.isValid() &&
    isLimitPerInstallmentValid;

  return {
    grossSalary,
    onChangeGrossSalary: handleChangeGrossSalary,
    netSalary,
    onChangeNetSalary: handleChangeNetSalary,
    limitPerInstallment,
    onChangeLimitPerInstallment: handleChangeLimitPerInstallment,
    hiredAt,
    onChangeHiredAt: handleChangeHiredAt,
    displayNetSalaryError,
    isLimitPerInstallmentValid,
    netSalaryHelperText,
    limitPerInstallmentHelperText,
    isSubmitButtonEnabled,
    handleSubmit,
    isHiredAtDisabled,
  };
};
