import { ShapeIcon, ShapeIconProps } from "@flash-tecnologia/hros-web-ui-v2";
import { useTheme } from "styled-components";
import Spacer from "../../../../../../components/Spacer";
import Typography from "../../../../../../components/Typography";

export type MessageContentProps = {
  shapeIconProps: ShapeIconProps;
  title: string;
  description: string;
  body: {
    title: string;
    description: string;
  };
};

const MessageContent = (props: MessageContentProps) => {
  const theme = useTheme();
  return (
    <>
      <ShapeIcon
        name={props.shapeIconProps.name}
        size={props.shapeIconProps.size}
        variant={props.shapeIconProps.variant}
        color={props.shapeIconProps.color}
      />
      <Spacer y="xs3" />
      <Typography.Headline8 color={theme.colors.neutral[30]}>
        {props.title}
      </Typography.Headline8>
      <Spacer y="xs5" />
      <Typography.Body4 color={theme.colors.neutral[50]}>
        {props.description}
      </Typography.Body4>
      <Spacer y="xs1" />
      <Typography.Body3 weight={700} color={theme.colors.neutral[20]}>
        {props.body.title}
      </Typography.Body3>
      <Spacer y="xs5" />
      <Typography.Body3 weight={600} color={theme.colors.neutral[50]}>
        {props.body.description}
      </Typography.Body3>
      <Spacer y="xs1" />
    </>
  );
};

export default MessageContent;
