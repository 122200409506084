export type HandleUploadImageType = {
  data: ArrayBuffer;
  contentType: string;
  url: string;
};

export const handleUploadImage = async ({
  data,
  contentType,
  url,
}: HandleUploadImageType) => {
  const imageBody = new Blob([data]);
  return fetch(url, {
    method: "PUT",
    body: imageBody,
    headers: {
      "Content-Type": contentType,
    },
  });
};
