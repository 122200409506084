import styled from "styled-components";

export interface FlexBoxItemProps {
  alignSelf?:
    | "auto"
    | "flex-start"
    | "flex-end"
    | "center"
    | "baseline"
    | "stretch";
  flexBasis?: string;
  flexGrow?: number;
  flexShrink?: number;
  order?: number;
}

const FlexBoxItem = styled.div<FlexBoxItemProps>`
  align-self: ${({ alignSelf }) => alignSelf || "auto"};
  flex: ${({ flexGrow }) => flexGrow || "0"}
    ${({ flexShrink }) => flexShrink || "1"}
    ${({ flexBasis }) => flexBasis || "auto"};
  order: ${({ order }) => order || "0"};
`;

export default FlexBoxItem;
