import {
  Button,
  Icons,
  Spinner,
  Table,
} from "@flash-tecnologia/hros-web-ui-v2";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useTheme } from "styled-components";
import Spacer from "../../../../components/Spacer";
import { LoaderContainer } from "../../../../components/atoms/Loader";
import { useTracking } from "../../../../utils/useTracking";
import { useDeactivateEmployeesViewModel } from "../../viewModel/useDeactivateEmployeesViewModel";
import { useNotificationBannersViewModel } from "../../viewModel/useNotificationBannersViewModel";
import ConfirmDeactivationModal from "../ConfirmDeactivationModal";
import NotificationBanners from "../NotificationBanners";
import TableMassActions from "./components/TableMassActions";
import { useEmployeesTableViewModel } from "./viewModel/useEmployeesTableViewModel";
import { BigNumbers } from "../BigNumbers";
import { EmployeeStatus } from "../../../../types/employees";
import FlexBoxItem from "@atoms/FlexBox/FlexBoxItem";
import FlexBox from "@atoms/FlexBox";
import { EditEmployeeModal } from "../EditEmployeeModal";
import { useEditEmployeeViewModel } from "../../viewModel/useUpsertEmployeeViewModel";

const EmployeesTab = () => {
  const tracking = useTracking();
  const navigate = useNavigate();
  const theme = useTheme();

  useEffect(() => {
    tracking.trackPage({
      name: "payroll_loan_employees",
    });
  }, []);

  const {
    confirmDeactivationModalTitle,
    confirmDeactivationModalVisibility,
    handleDeactivateCancel,
    handleConfirmDeactivation,
    onDeactivateClick,
    isDeactivationLoading,
  } = useDeactivateEmployeesViewModel();

  const {
    editEmployeeModalVisibility,
    handleUpsertCancel,
    handleUpsertEmployee,
    onEditEmployeeClick,
    isUpsertEmployeeLoading,
    employeeData,
  } = useEditEmployeeViewModel();

  const {
    isLoading: isNotificationBannersLoading,
    fileProcessingNotificationCard,
    outdatedDatabaseNotificationCard,
  } = useNotificationBannersViewModel();

  const {
    isLoading: isGetEmployeesLoading,
    forceTableFilterRenderKey,
    forceTableSearchRenderKey,
    onFilter,
    onMassDeactivationClick,
    onSearchInputChange,
    onPaginationChange,
    pageSizeOptions,
    paginationState,
    table,
    totalCount,
    tableFilters,
    statusFilter,
    totalSelected,
    employeesCount,
    isGetEmployessCountLoading,
  } = useEmployeesTableViewModel({
    onDeactivateClick,
    onEditEmployeeClick,
  });

  if (isNotificationBannersLoading || isGetEmployessCountLoading) {
    return (
      <LoaderContainer>
        <Spinner size={64} variant="primary" />
      </LoaderContainer>
    );
  }

  return (
    <>
      <ConfirmDeactivationModal
        loading={isDeactivationLoading}
        onClose={handleDeactivateCancel}
        onSubmit={handleConfirmDeactivation}
        open={confirmDeactivationModalVisibility}
        title={confirmDeactivationModalTitle}
      />
      <EditEmployeeModal
        loading={isUpsertEmployeeLoading}
        onClose={handleUpsertCancel}
        handleUpsertEmployee={handleUpsertEmployee}
        open={editEmployeeModalVisibility}
        employee={employeeData}
      />
      <NotificationBanners
        fileProcessingNotificationCard={fileProcessingNotificationCard}
        outdatedDatabaseNotificationCard={outdatedDatabaseNotificationCard}
      />
      <Spacer y="s" />
      <BigNumbers
        employeesCount={employeesCount}
        onFilter={onFilter}
        currentFilter={statusFilter as EmployeeStatus[]}
      />
      <Spacer y="s" />
      <Table.Root>
        <FlexBox
          flexDirection="row"
          justifyContent="space-between"
          alignItems="center"
          gap="s"
        >
          <FlexBoxItem flexGrow={1}>
            <Table.Search
              key={forceTableSearchRenderKey}
              label="Buscar colaborador por nome ou CPF"
              onChange={onSearchInputChange}
            />
          </FlexBoxItem>
          <Button
            size="large"
            variant="primary"
            variantType="default"
            onClick={() => navigate("/payroll-loan/upload-sheet")}
          >
            Atualizar base
            <Icons
              name="IconTableImport"
              color={theme.colors.neutral[100]}
              fill={"transparent"}
            />
          </Button>
        </FlexBox>

        <Table.Filters
          key={forceTableFilterRenderKey}
          filters={tableFilters}
          label="Filtrar por:"
          onChange={onFilter}
        />
        <Table.Grid.Root loading={isGetEmployeesLoading}>
          {table.selected.allSelected || table.selected.selected.length ? (
            <TableMassActions
              totalCount={totalCount}
              totalSelected={totalSelected}
              onMassDeactivationClick={onMassDeactivationClick}
              onToggleAllSelected={() =>
                table.setAllSelected(!table.selected.allSelected)
              }
            />
          ) : (
            <Table.Grid.Header getHeaderGroups={table.getHeaderGroups} />
          )}
          {table.rows.map((row, index) => (
            <Table.Grid.Row key={index + row.id} row={row} />
          ))}
        </Table.Grid.Root>
        <Table.Pagination
          count={totalCount}
          onPaginationChange={onPaginationChange}
          pagination={paginationState}
          pageSizeOptions={pageSizeOptions}
        />
      </Table.Root>
      <Spacer y="m" />
    </>
  );
};

export default EmployeesTab;
