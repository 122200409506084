import { IconButton } from "@flash-tecnologia/hros-web-ui-v2";
import styled from "styled-components";

export const DrawerWrapper = styled.div`
  width: 670px;
`;

export const DrawerHeaderStyled = styled.div`
  position: relative;
  margin: ${({ theme }) => theme.spacings.m};
`;

export const DrawerContentStyled = styled.div`
  padding: ${({ theme }) => theme.spacings.m};
`;

export const CloseIconButtonStyled = styled(IconButton)`
  position: absolute;
  right: 0;
  transform: translate(50%, -50%);
`;
