import { trpc } from "../../../../../api/client";

interface GetTerminationEmployeeUseCaseInput {
  billetDueDate: string;
  companyId: string;
  employeeId: string;
  enabled: boolean;
  onError: (error) => void;
  onSuccess: (terminationEmployee) => void;
}

export const getTerminationEmployeeUseCase = ({
  billetDueDate,
  companyId,
  employeeId,
  enabled,
  onError,
  onSuccess,
}: GetTerminationEmployeeUseCaseInput) => {
  const { data, isLoading, isFetching, refetch } =
    trpc.termination.getTerminationEmployee.useQuery(
      {
        billetDueDate,
        companyId,
        employeeId,
      },
      {
        enabled,
        onError,
        onSuccess,
        keepPreviousData: false,
        refetchOnWindowFocus: false,
      }
    );

  return {
    data,
    isFetching,
    isLoading,
    refetch,
  };
};
