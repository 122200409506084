import FlexBox from "@atoms/FlexBox";
import {
  Breadcrumbs,
  Button,
  Divider,
  Icons,
  LinkButton,
  PageContainer,
  Stepper,
} from "@flash-tecnologia/hros-web-ui-v2";
import { Grid } from "@mui/material";
import { createContext } from "react";
import { useNavigate } from "react-router-dom";
import { useTheme } from "styled-components";
import CreatedBillModal from "../../components/CreatedBillModal";
import Spacer from "../../components/Spacer";
import Typography from "../../components/Typography";
import { UploadedFile } from "../../components/organisms/FileUploader/useFileUploader.hook";
import { PayrollLoanPageTabs } from "../../types/payrollLoanPageTabs";
import {
  CreateBillPageWrapperStyled,
  FooterWrapperStyled,
  PageHeaderStyled,
} from "./styled";
import {
  Bill,
  useCreateBillPageViewModel,
} from "./viewModel/useCreateBillPageViewModel";

type CreateBillPageContextType = {
  adminEmail?: string;
  bill?: Bill;
  billFileTemplatePath?: string;
  disableContinueButton?: () => void;
  enableContinueButton?: () => void;
  onBillChange?: (bill: Bill) => void;
  onBillFileTemplatePathChange?: (billFileTemplatePath: string) => void;
  onBillFilePathChange?: (billFilePath: string) => void;
  onFileUpload?: (uploadedFile: UploadedFile) => void;
  onReuploadBillFileButtonClick?: () => void;
  onUploadedFileClear?: () => void;
  onValidateBillFileError?: () => void;
  uploadedFile?: UploadedFile;
};

export const CreateBillPageContext = createContext<CreateBillPageContextType>(
  {}
);

const CreateBillPage = () => {
  const theme = useTheme();
  const navigate = useNavigate();

  const {
    adminEmail,
    bill,
    billFileTemplatePath,
    currentStep,
    disableContinueButton,
    enableContinueButton,
    isCreatedBillModalOpen,
    isContinueButtonDisabled,
    isLoading,
    onBillChange,
    onBillFileTemplatePathChange,
    onBillFilePathChange,
    onContinueButtonClick,
    onCreatedBillModalClose,
    onFileUpload,
    onReuploadBillFileButtonClick,
    onUploadedFileClear,
    onValidateBillFileError,
    stepperProps,
    uploadedFile,
  } = useCreateBillPageViewModel();
  return (
    <CreateBillPageContext.Provider
      value={{
        adminEmail,
        bill,
        billFileTemplatePath,
        disableContinueButton,
        enableContinueButton,
        onBillChange,
        onBillFileTemplatePathChange,
        onBillFilePathChange,
        onFileUpload,
        onReuploadBillFileButtonClick,
        onUploadedFileClear,
        onValidateBillFileError,
        uploadedFile,
      }}
    >
      <CreatedBillModal
        adminEmail={adminEmail}
        onClose={onCreatedBillModalClose}
        onSubmit={onCreatedBillModalClose}
        open={isCreatedBillModalOpen}
      />
      <CreateBillPageWrapperStyled flexDirection={"column"}>
        <FlexBox.Item>
          <PageHeaderStyled>
            <Breadcrumbs
              breadcrumbs={[
                <LinkButton
                  onClick={() => {
                    navigate("/payroll-loan", {
                      replace: true,
                      state: {
                        initialTab: PayrollLoanPageTabs.Installments,
                      },
                    });
                  }}
                  variant="neutral"
                  size="small"
                >
                  Parcelas
                </LinkButton>,
                <LinkButton variant="neutral" size="small" disabled>
                  Criar fatura
                </LinkButton>,
              ]}
              separator={
                <Icons name="IconChevronRight" fill={theme.colors.neutral100} />
              }
            />
            <Stepper
              activeStep={currentStep.index}
              steps={stepperProps.steps}
              disableClick={stepperProps.disableClick}
              setActiveStep={onContinueButtonClick}
            />
          </PageHeaderStyled>
        </FlexBox.Item>
        <FlexBox.Item flexGrow={1}>
          <PageContainer>
            <Typography.Headline6>Criar fatura</Typography.Headline6>
            <Spacer y="xs" />
            <Grid container spacing={theme.spacings.xs}>
              <Grid item xs={12} md={3}>
                <Typography.Headline7 color={theme.colors.secondary[50]}>
                  {currentStep.title}
                </Typography.Headline7>
                <Spacer y="xs3" />
                <Typography.Body3 color={theme.colors.neutral[50]}>
                  {currentStep.description}
                </Typography.Body3>
              </Grid>
              <Grid item xs={12} md>
                {currentStep.content}
              </Grid>
            </Grid>
            <Spacer y="xs" />
          </PageContainer>
        </FlexBox.Item>
        <FlexBox.Item>
          <Divider orientation={"horizontal"} />
          <FooterWrapperStyled>
            <FlexBox justifyContent={"space-between"} alignItems={"center"}>
              <FlexBox.Item>
                <LinkButton
                  variant="neutral"
                  size="small"
                  onClick={() => {
                    navigate("/payroll-loan", { replace: true });
                  }}
                >
                  Cancelar
                </LinkButton>
              </FlexBox.Item>
              <FlexBox.Item>
                <Button
                  disabled={isContinueButtonDisabled}
                  size="small"
                  variant="primary"
                  loading={isLoading}
                  variantType="default"
                  onClick={onContinueButtonClick}
                >
                  Continuar
                  <Icons
                    name="IconArrowNarrowRight"
                    color={theme.colors.neutral[100]}
                    fill="transparent"
                  />
                </Button>
              </FlexBox.Item>
            </FlexBox>
          </FooterWrapperStyled>
        </FlexBox.Item>
      </CreateBillPageWrapperStyled>
    </CreateBillPageContext.Provider>
  );
};

export default CreateBillPage;
