import {
  Button,
  Icons,
  SearchField,
  Table,
  Tooltip,
} from "@flash-tecnologia/hros-web-ui-v2";
import { useTheme } from "styled-components";
import Spacer from "../../../../components/Spacer";
import Typography from "../../../../components/Typography";
import FlexBox from "../../../../components/atoms/FlexBox";
import { useInstallmentsTabViewModel } from "../../viewModel/useInstallmentsTabViewModel";

const InstallmentsTab = () => {
  const theme = useTheme();
  const {
    isCreateBillButtonDisabled,
    loading,
    onCreateBillClick,
    onFilter,
    onPaginationChange,
    pageSizeOptions,
    paginationState,
    table,
    tableFilters,
    totalCount,
  } = useInstallmentsTabViewModel();
  return (
    <>
      <Typography.Headline8 color={theme.colors.neutral[30]}>
        Parcelas para desconto em folha
      </Typography.Headline8>
      <Spacer y={"s"} />
      <FlexBox gap={"s"} alignItems="center">
        <FlexBox.Item flexGrow={1}>
          <SearchField disabled={true} label={"Buscar por parcelas"} />
        </FlexBox.Item>
        <FlexBox.Item>
          <Tooltip
            arrow
            title={
              "Você só pode criar a fatura referente ao mês vigente. Selecione o mês no filtro abaixo para continuar."
            }
          >
            <div>
              <Button
                disabled={isCreateBillButtonDisabled}
                size={"large"}
                variant="primary"
                onClick={onCreateBillClick}
              >
                Pagar parcelas
                <Icons name="IconReceipt2" fill={"transparent"} size={24} />
              </Button>
            </div>
          </Tooltip>
        </FlexBox.Item>
      </FlexBox>
      <Spacer y={"s"} />
      <Table.Root>
        <Table.Filters
          filters={tableFilters}
          label="Filtrar por:"
          onChange={onFilter}
        />
        <Table.Grid.Root loading={loading}>
          <Table.Grid.Header getHeaderGroups={table.getHeaderGroups} />
          {table.rows.map((row, index) => (
            <Table.Grid.Row key={index + row.id} row={row} />
          ))}
        </Table.Grid.Root>
        <Table.Pagination
          count={totalCount}
          onPaginationChange={onPaginationChange}
          pagination={paginationState}
          pageSizeOptions={pageSizeOptions}
        />
      </Table.Root>
    </>
  );
};

export default InstallmentsTab;
