import { useTheme } from "styled-components";
import Typography from "../../../../components/Typography";
import Spacer from "../../../../components/Spacer";
import { Grid, LinearProgress } from "@mui/material";
import FlexBox from "../../../../components/atoms/FlexBox";
import { isNil } from "lodash";

export interface StatusDetailsProps {
  description: string;
  title: string;
  progress?: number;
}

const StatusDetails = ({
  description,
  progress,
  title,
}: StatusDetailsProps) => {
  const theme = useTheme();
  return (
    <Grid container>
      <Grid item xs={12}>
        <Typography.Body3 color={theme.colors.neutral[20]} weight={700}>
          {title}
        </Typography.Body3>
        <Spacer y="xs5" />
        <Typography.Body3 color={theme.colors.neutral[50]} weight={600}>
          {description}
        </Typography.Body3>
        <Spacer y="xs1" />
      </Grid>
      {!isNil(progress) && (
        <Grid item xs={12} md={6}>
          <FlexBox gap={"xs3"} alignItems={"center"}>
            <FlexBox.Item flexGrow={1}>
              <LinearProgress variant="determinate" value={progress} />
            </FlexBox.Item>
            <FlexBox.Item>
              <Typography.Body4 color={theme.colors.secondary[50]} weight={600}>
                {`${progress}%`}
              </Typography.Body4>
            </FlexBox.Item>
          </FlexBox>
          <Spacer y="l" />
        </Grid>
      )}
    </Grid>
  );
};

export default StatusDetails;
