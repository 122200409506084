import { trpc } from "../../../api/client";

type RejectUseCaseInputParams = {
  companyId: string;
  loanIds: string[];
  onSuccess: () => void;
  onError: (error) => void;
};

export const rejectLoanRequestsUseCase = ({
  companyId,
  loanIds,
  onSuccess,
  onError,
}: RejectUseCaseInputParams) => {
  const rejectLoanRequestMutation = trpc.loans.rejectLoanRequests.useMutation({
    onSuccess,
    onError,
  });

  return {
    rejectLoanRequestsMutation: () =>
      rejectLoanRequestMutation.mutate({
        companyId,
        loanIds,
      }),
    isLoading: rejectLoanRequestMutation.isLoading,
  };
};
