import { trpc } from "../../../api/client";

export const getExpirationSummaryUseCase = (companyId: string) => {
  const { data, isLoading } = trpc.employees.getExpirationSummary.useQuery(
    { companyId },
    { cacheTime: 0, keepPreviousData: false, refetchOnWindowFocus: false }
  );

  return {
    data,
    isLoading,
  };
};
