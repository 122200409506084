import { DropzoneFile } from "@flash-tecnologia/hros-web-ui-v2/dist/components/Dropzone/types";

export type UploadedFile = {
  data: ArrayBuffer;
  contentType?: string;
};

export interface FileUploaderHookParams {
  onError?: () => void;
  onFileChange?: (uploadedFile: UploadedFile) => void;
  onUploadedFileClear?: () => void;
}

export function useFileUploader({
  onError,
  onFileChange,
  onUploadedFileClear,
}: FileUploaderHookParams) {
  const handleDropzoneChange = (dropzoneFiles: DropzoneFile[]) => {
    if (!dropzoneFiles.length) {
      onUploadedFileClear?.();
      return;
    }
    if (dropzoneFiles[0].error) {
      onError?.();
      return;
    }

    if (dropzoneFiles[0].data && dropzoneFiles[0].data instanceof ArrayBuffer) {
      const uploadedFile: UploadedFile = {
        contentType: dropzoneFiles[0].mime,
        data: dropzoneFiles[0].data,
      };
      onFileChange?.(uploadedFile);
    }
    return;
  };

  return {
    onDropzoneChange: handleDropzoneChange,
  };
}
