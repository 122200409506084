import { Table } from "@flash-tecnologia/hros-web-ui-v2";
import { useLoanRequestsTabViewModel } from "../../viewModel/useLoanRequestsTabViewModel";
import LoanRequestActionModal from "./LoanRequestActionModal";
import LoanRequestDetailsDrawer from "./LoanRequestDetailsDrawer";

const LoanRequestsTab = () => {
  const {
    emptyState,
    loading,
    loanRequestActionModalProps,
    loanRequestDetailsDrawerProps,
    onFilter,
    onPaginationChange,
    pageSizeOptions,
    paginationState,
    table,
    tableFilters,
    totalCount,
  } = useLoanRequestsTabViewModel();

  return (
    <>
      {loanRequestActionModalProps && (
        <LoanRequestActionModal
          loading={loanRequestActionModalProps.loading}
          onSubmit={loanRequestActionModalProps.onSubmit}
          onClose={loanRequestActionModalProps.onClose}
          submitButtonText={loanRequestActionModalProps.submitButtonText}
          title={loanRequestActionModalProps.title}
          visible={loanRequestActionModalProps.visible}
        />
      )}
      <LoanRequestDetailsDrawer
        detailsSections={loanRequestDetailsDrawerProps.detailsSections}
        employeeName={loanRequestDetailsDrawerProps.employeeName}
        isLoading={loanRequestDetailsDrawerProps.isLoading}
        loanRequestValue={loanRequestDetailsDrawerProps.loanRequestValue}
        onClose={loanRequestDetailsDrawerProps.onClose}
        open={loanRequestDetailsDrawerProps.open}
        statusTagProps={loanRequestDetailsDrawerProps.statusTagProps}
      />
      <Table.Root>
        <Table.Header title={"Solicitações de empréstimo"} />
        <Table.Filters
          filters={tableFilters}
          label="Filtrar por:"
          onChange={onFilter}
        />
        <Table.Grid.Root empty={emptyState} loading={loading}>
          <Table.Grid.Header getHeaderGroups={table.getHeaderGroups} />
          {table.rows.map((row, index) => (
            <Table.Grid.Row key={index + row.id} row={row} />
          ))}
        </Table.Grid.Root>
        <Table.Pagination
          count={totalCount}
          onPaginationChange={onPaginationChange}
          pagination={paginationState}
          pageSizeOptions={pageSizeOptions}
        />
      </Table.Root>
    </>
  );
};

export default LoanRequestsTab;
