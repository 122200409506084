import FlexBox from "@atoms/FlexBox";
import {
  Button,
  Icons,
  Modal,
  ShapeIcon,
} from "@flash-tecnologia/hros-web-ui-v2";
import { useTheme } from "styled-components";
import Typography from "../../../../components/Typography";
import { ChooseContactModalFooterWrapperStyled } from "./styled";

interface ChooseContactModalProps {
  onChatButtonClick?: () => void;
  onClose: () => void;
  onScheduleMeetingButtonClick: () => void;
  open: boolean;
}

const ChooseContactModal = ({
  onChatButtonClick,
  onClose,
  onScheduleMeetingButtonClick,
  open,
}: ChooseContactModalProps) => {
  const theme = useTheme();
  return (
    <Modal.Root open={open} onClose={onClose} size={"sm"} showClose={false}>
      <>
        <Modal.Content>
          <FlexBox alignItems={"center"} flexDirection={"column"} gap={"xs2"}>
            <FlexBox.Item>
              <ShapeIcon
                name={"IconCheck"}
                size={64}
                color={theme.colors.secondary50}
                variant={"default"}
              />
            </FlexBox.Item>
            <FlexBox.Item>
              <Typography.Headline6 center color={theme.colors.neutral20}>
                Agradecemos o seu interesse!
              </Typography.Headline6>
            </FlexBox.Item>
            <FlexBox.Item>
              <Typography.Headline6 center color={theme.colors.neutral20}>
                Vamos entrar em contato em breve!
              </Typography.Headline6>
            </FlexBox.Item>
            <FlexBox.Item>
              <Typography.Body3 center color={theme.colors.neutral50}>
                Se preferir, você já pode falar com nossos especialistas pelo
                Whatsapp ou agendando um papo:
              </Typography.Body3>
            </FlexBox.Item>
          </FlexBox>
        </Modal.Content>
        <Modal.Footer>
          <ChooseContactModalFooterWrapperStyled>
            <FlexBox alignItems="center" gap={"xs"} justifyContent="center">
              <FlexBox.Item>
                <Button
                  variant={"secondary"}
                  onClick={onChatButtonClick}
                  size={"large"}
                >
                  Falar pelo Whatsapp
                  <Icons
                    name="IconBrandWhatsapp"
                    color={theme.colors.secondary50}
                    fill="transparent"
                  />
                </Button>
              </FlexBox.Item>
              <FlexBox.Item>
                <Button
                  onClick={onScheduleMeetingButtonClick}
                  size="large"
                  variant="primary"
                >
                  Agendar papo
                  <Icons
                    name="IconCalendarEvent"
                    color={theme.colors.neutral100}
                    fill="transparent"
                  />
                </Button>
              </FlexBox.Item>
            </FlexBox>
          </ChooseContactModalFooterWrapperStyled>
        </Modal.Footer>
      </>
    </Modal.Root>
  );
};

export default ChooseContactModal;
