import {
  Button,
  DatePicker,
  Icons,
  LinkButton,
  Modal,
  TextField,
} from "@flash-tecnologia/hros-web-ui-v2";
import { useTheme } from "styled-components";
import Typography from "../../../../components/Typography";
import FlexBox from "../../../../components/atoms/FlexBox";
import { ContentWrapperStyled } from "./styled";
import Spacer from "../../../../components/Spacer";
import { cpfMask } from "@utils/masks/formatCPF";
import { GetEmployeesOutput } from "../../useCases/getEmployeesUseCase";
import { useEditEmployeeModalViewModel } from "./viewModel/useEditEmployeeModalViewModel";
import { toCurrency } from "@utils/masks/toCurrency";
import { UpsertEmployeeInputDTO } from "../../useCases/upsertEmployeeUseCase";

type EditEmployeeModalProps = {
  loading: boolean;
  onClose: () => void;
  handleUpsertEmployee: (employeeData: UpsertEmployeeInputDTO) => void;
  open: boolean;
  employee: GetEmployeesOutput["employees"][0];
};
export const EditEmployeeModal = ({
  loading,
  onClose,
  handleUpsertEmployee,
  open,
  employee,
}: EditEmployeeModalProps) => {
  const theme = useTheme();
  const {
    grossSalary,
    onChangeGrossSalary,
    netSalary,
    onChangeNetSalary,
    limitPerInstallment,
    onChangeLimitPerInstallment,
    hiredAt,
    onChangeHiredAt,
    displayNetSalaryError,
    isLimitPerInstallmentValid,
    netSalaryHelperText,
    limitPerInstallmentHelperText,
    isSubmitButtonEnabled,
    handleSubmit,
    isHiredAtDisabled,
  } = useEditEmployeeModalViewModel(employee, handleUpsertEmployee);

  return (
    <Modal.Root open={open} onClose={onClose} size="xs" showClose>
      <>
        <Modal.Header
          title="Editar dados da pessoa"
          description="Os dados editados a seguir afetam diretamente as condições em que a pessoa poderá solicitar um empréstimo."
        />
        <Modal.Content>
          <Typography.Headline9 color={theme.colors.neutral30}>
            {`${employee.name ?? ""}  •  ${cpfMask(employee.cpf ?? "")}`}
          </Typography.Headline9>
          <Spacer y="xs1" />
          <DatePicker
            onDateChange={onChangeHiredAt}
            label="Data de admissão"
            value={hiredAt}
            disabled={isHiredAtDisabled}
          />
          <Spacer y="xs1" />
          <TextField
            fullWidth
            onChange={(event) => onChangeGrossSalary(event.target.value)}
            label="Salário bruto"
            value={grossSalary !== undefined ? toCurrency(grossSalary) : ""}
          />
          <Spacer y="xs1" />
          <TextField
            fullWidth
            onChange={(event) => onChangeNetSalary(event.target.value)}
            label="Salário líquido"
            value={netSalary !== undefined ? toCurrency(netSalary) : ""}
            error={displayNetSalaryError}
            helperText={netSalaryHelperText}
          />
          <Spacer y="xs1" />
          <TextField
            fullWidth
            onChange={(event) =>
              onChangeLimitPerInstallment(event.target.value)
            }
            label="Margem consignável"
            helperText={limitPerInstallmentHelperText}
            value={
              limitPerInstallment !== undefined
                ? toCurrency(limitPerInstallment)
                : ""
            }
            error={!isLimitPerInstallmentValid}
          />
        </Modal.Content>
        <Modal.Footer>
          <ContentWrapperStyled
            alignItems="center"
            justifyContent="space-between"
          >
            <FlexBox.Item>
              <LinkButton
                disabled={loading}
                onClick={onClose}
                variant="neutral"
                size="small"
              >
                <Typography.Body4 color={theme.colors.neutral40} weight={700}>
                  Cancelar
                </Typography.Body4>
              </LinkButton>
            </FlexBox.Item>
            <FlexBox.Item>
              <Button
                variant="primary"
                loading={loading}
                onClick={handleSubmit}
                size="large"
                variantType="default"
                disabled={!isSubmitButtonEnabled}
              >
                Editar pessoa
                <Icons
                  name="IconCheck"
                  color={theme.colors.neutral[100]}
                  fill={"transparent"}
                />
              </Button>
            </FlexBox.Item>
          </ContentWrapperStyled>
        </Modal.Footer>
      </>
    </Modal.Root>
  );
};
