import { dispatchToast } from "../../../utils/dispatchEvents";
import { trpc } from "../../../api/client";
import { RouterInput } from "server/src/routers/trpc";

interface DeactivateEmployeesUseCaseParams {
  companyId: string;
  onSuccess?: () => void;
}

type DeactivateEmployeesUseCaseInput =
  RouterInput["employees"]["deactivateEmployees"]["employees"];

export const deactivateEmployeesUseCase = ({
  companyId,
  onSuccess,
}: DeactivateEmployeesUseCaseParams) => {
  const deactivateEmployeesMutation =
    trpc.employees.deactivateEmployees.useMutation({
      onSuccess: () => {
        const toastContent = `Pessoa(s) desabilitada(s) com sucesso!`;

        dispatchToast({
          type: "success",
          content: toastContent,
        });
        onSuccess?.();
      },
      onError: () => {
        dispatchToast({
          type: "error",
          content: "Não foi possível desabilitar essa(s) pessoa(s).",
          description:
            "Tente novamente mais tarde. Se o problema persistir, entre em contato com o suporte.",
        });
      },
    });
  return {
    deactivateEmployeesMutation: (employees: DeactivateEmployeesUseCaseInput) =>
      deactivateEmployeesMutation.mutate({ companyId, employees }),
    isLoading: deactivateEmployeesMutation.isLoading,
  };
};
