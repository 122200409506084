import { Card } from "@atoms/Card";
import {
  DownloadButton,
  LinkButton,
  NotificationCard,
} from "@flash-tecnologia/hros-web-ui-v2";
import { useSelectedCompany } from "@flash-tecnologia/hros-web-utility";
import { LinearProgress } from "@mui/material";
import { CreateBillPageContext } from "@pages/CreateBillPage";
import {
  GetBillFileTemplateResponse,
  getBillFileTemplateUseCase,
} from "@pages/CreateBillPage/useCases/getBillFileTemplateUseCase";
import { dayjs } from "@utils/dayjs";
import { dispatchToast } from "@utils/dispatchEvents";
import { useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { PAYROLL_LOAN_GENERIC_ERROR_MESSAGE } from "server/src/utils/errorHandlers/payrollLoan.errors";
import Spacer from "../../../../components/Spacer";
import Typography from "../../../../components/Typography";
import { BillFileFilterState } from "../types";

import { NotificationCardProps as DSNotificationCardProps } from "@flash-tecnologia/hros-web-ui-v2/dist/components/NotificationCard/Root";
import BillFileTemplateDiscountColumnTutorial from "../../../../assets/bill_file_template_discount_column_tutorial.png";

interface NotificationCardProps {
  iconName: "IconCheck" | "IconAlertCircle";
  type: DSNotificationCardProps["type"];
  title: string;
  subtitle: string;
}

const DownloadBillFileTemplate = () => {
  const { onBillFileTemplatePathChange, enableContinueButton } = useContext(
    CreateBillPageContext
  );

  const { selectedCompany } = useSelectedCompany();
  const startDate = dayjs()
    .add(1, "month")
    .startOf("month")
    .format("YYYY-MM-DD");
  const endDate = dayjs(startDate).endOf("month").format("YYYY-MM-DD");
  const [notificationCardProps, setNotificationCardProps] =
    useState<NotificationCardProps>();

  const [
    isGetBillFileTemplateUseCaseEnabled,
    setIsGetBillFileTemplateUseCaseEnabled,
  ] = useState<boolean>(false);

  const [filter, setFilter] = useState<BillFileFilterState>();

  const location = useLocation();
  const state = location.state as BillFileFilterState | null;

  useEffect(() => {
    if (state) {
      setFilter(state as BillFileFilterState);
    }
  }, [state]);

  const handleGetBillFileTemplateError = (error) => {
    dispatchToast({
      type: "error",
      content: error.message ?? PAYROLL_LOAN_GENERIC_ERROR_MESSAGE,
    });
    setNotificationCardProps(undefined);
    setIsGetBillFileTemplateUseCaseEnabled(false);
  };

  const handleGetBillFileTemplateSuccess = (
    data: GetBillFileTemplateResponse
  ) => {
    const newNotificationCardProps: NotificationCardProps = {
      iconName: "IconCheck",
      type: "success",
      title: "Seu arquivo foi gerado",
      subtitle: "Baixe por aqui",
    };
    onBillFileTemplatePathChange?.(data.billFileTemplatePath);
    setNotificationCardProps(newNotificationCardProps);
    setIsGetBillFileTemplateUseCaseEnabled(false);
  };

  const { data, isLoading } = getBillFileTemplateUseCase({
    companyId: selectedCompany.id,
    startDate: filter?.startDate ?? startDate,
    endDate: filter?.endDate ?? endDate,
    enabled: isGetBillFileTemplateUseCaseEnabled,
    onError: handleGetBillFileTemplateError,
    onSuccess: handleGetBillFileTemplateSuccess,
  });

  const loadingNotificationCardProps: NotificationCardProps = {
    iconName: "IconAlertCircle",
    type: "info",
    title: "Estamos gerando seu arquivo",
    subtitle: "Ao final do processo, você poderá baixá-lo por aqui",
  };

  const handleDownloadButtonClick = () => {
    setNotificationCardProps(loadingNotificationCardProps);
    setIsGetBillFileTemplateUseCaseEnabled(true);
  };

  return (
    <>
      <Card>
        <Typography.Headline8>Gerar modelo de arquivo</Typography.Headline8>
        <Spacer y="xs5" />
        <Typography.Body3>
          Clique no botão abaixo para gerar o arquivo de parcelas do mês
          vigente.
        </Typography.Body3>
        <Spacer y="m" />
        {notificationCardProps ? (
          <>
            <NotificationCard.Root
              type={notificationCardProps.type}
              variant={"outlined"}
              autoClose
              showCloseButton
            >
              <NotificationCard.Icon
                iconName={notificationCardProps.iconName}
              />
              <NotificationCard.WrapperTexts>
                <NotificationCard.Title>
                  {notificationCardProps.title}
                </NotificationCard.Title>
                <NotificationCard.Subtitle>
                  {notificationCardProps.subtitle}
                </NotificationCard.Subtitle>
                {isLoading && <LinearProgress variant="indeterminate" />}
                {data && !isLoading && (
                  <LinkButton
                    onClick={() => {
                      window.open(data.billFileTemplateURL, "_blank");
                      enableContinueButton?.();
                    }}
                    variant="neutral"
                    size="small"
                  >
                    {data.fileName ?? "Parcelas.xlsx"}
                  </LinkButton>
                )}
              </NotificationCard.WrapperTexts>
            </NotificationCard.Root>
          </>
        ) : (
          <DownloadButton
            title={"Gerar modelo XLSX"}
            fileName={"Parcelas.xls"}
            icon={"IconDownload"}
            docIconSize={32}
            onClick={handleDownloadButtonClick}
          />
        )}
      </Card>
      <Spacer y={"m"} />
      <Card>
        <Typography.Headline8>Preencher dados de desconto</Typography.Headline8>
        <Spacer y="xs5" />
        <Typography.Body3>
          Preencha a coluna "VALOR DESCONTADO" com base na coluna "VALOR DA
          PARCELA". Caso o valor descontado seja menor, preencha a coluna
          "MOTIVO DE DESCONTO DIFERENTE". Confira os motivos permitidos na aba
          "Instruções".
        </Typography.Body3>
        <Spacer y="m" />
        <img
          alt={`Exemplo de preenchimento das colunas "VALOR DESCONTADO" e "MOTIVE DE DESCONTO DIFERENTE"`}
          width={700}
          src={BillFileTemplateDiscountColumnTutorial}
        />
      </Card>
    </>
  );
};

export default DownloadBillFileTemplate;
