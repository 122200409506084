import * as React from "react";

const UserCustomIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={72}
    height={65}
    fill="none"
    {...props}
  >
    <mask
      id="a"
      width={70}
      height={65}
      x={1}
      y={0}
      maskUnits="userSpaceOnUse"
      style={{
        maskType: "luminance",
      }}
    >
      <path fill="#fff" d="M71 .304H1v64h70v-64Z" />
    </mask>
    <g mask="url(#a)">
      <mask
        id="b"
        width={70}
        height={65}
        x={1}
        y={0}
        maskUnits="userSpaceOnUse"
        style={{
          maskType: "luminance",
        }}
      >
        <path fill="#fff" d="M71 .304H1v64h70v-64Z" />
      </mask>
      <g fill="#FE2B8F" mask="url(#b)">
        <path d="M44.359 63.918c-6.492 0-12.99-.033-19.481.009-5.22.032-10.44.184-15.656.22-1.469 0-2.938-.11-4.388-.33-3.13-.452-4.374-2.312-3.33-5.314A28.99 28.99 0 0 1 4.28 52.76c4.8-7.73 11.781-12.754 20.15-15.99a40.413 40.413 0 0 1 14.251-2.814c7.297-.064 14.014 1.796 19.751 6.63a36.871 36.871 0 0 1 9.397 11.847c.636 1.276 1.35 2.519 1.863 3.845.49 1.22.828 2.5 1.015 3.803.252 1.952-.832 3.246-2.777 3.495a34.665 34.665 0 0 1-4.086.308c-6.492.033-12.988.014-19.48.014v.019h-.005Zm21.334-4.766a2.387 2.387 0 0 0-.005-.626c-2.068-5.392-4.927-10.268-9.342-14.085-3.404-2.946-7.229-5.064-11.768-5.64-5.229-.663-10.413-.525-15.523.92-9.672 2.736-17.386 8.123-22.67 16.802-.6.985-1.08 2.049-1.633 3.113.169.092.343.17.526.234 1.807.401 3.614.166 5.44.06 3.793-.212 7.594-.304 11.392-.327 12.426-.064 24.857-.06 37.283-.12 2.068-.009 4.136-.216 6.3-.34v.01ZM37.272.512c5-.125 9.182 2.274 12.357 6.63.75 1.027 1.652 1.98 2.196 3.108 1.222 2.518 2.233 5.106 1.583 8.043-.608 2.758-1.404 5.42-3.175 7.68-3.147 4.02-7.16 6.502-12.289 7.123-4.616.557-9.008-.129-13.107-2.311-4.223-2.247-6.625-5.88-7.495-10.553-1.313-7.031 2.352-13.334 8.13-16.686C28.968 1.52 32.748.498 37.273.512Zm10.962 14.282c.229-1.773-.476-3.186-1.574-4.475-.425-.502-.814-1.045-1.258-1.529-1.61-1.763-3.445-3.154-5.884-3.522a17.497 17.497 0 0 0-10.097 1.33c-3.948 1.824-6.9 4.605-7.577 9.227-.791 5.392 1.981 10.222 6.913 11.815a21.286 21.286 0 0 0 9.146.797c4.196-.484 9.137-4.812 9.805-9.26.22-1.454.357-2.919.53-4.378l-.004-.005Z" />
      </g>
    </g>
  </svg>
);
export default UserCustomIcon;
