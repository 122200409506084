export const PayrollLoanFaq = [
  {
    question: "O que é o empréstimo Consignado?",
    answer: `O Empréstimo Consignado Privado é uma modalidade de crédito regulamentada pela Lei Federal 10.820/2003, em que as parcelas do empréstimo não ultrapassam 35% do salário líquido e são descontadas no holerite do colaborador.

		O Consignado é uma modalidade de empréstimo com taxas que podem ser até 30% menores do que outras opções como o cheque especial, cartão de crédito e empréstimo pessoal.
		
		Esse tipo de empréstimo não pode consumir mais de 35% do salário líquido do colaborador e as parcelas são descontadas direto no salário.`,
  },
  {
    question: "A Flash é responsável pelo empréstimo?",
    answer:
      "O Consignado será operado em parceria com a Paketá, que oferece uma plataforma completa de empréstimo consignado privado. A Flash vai atuar como um canal de vendas para a Paketá, fazendo a indicação de clientes interessados. Já a Paketá é responsável pela operação financeira, ou seja, análise de crédito, liberação do crédito e cobrança.",
  },
  {
    question:
      "Existe algum critério para que a minha empresa possa contratar o benefício?",
    answer:
      "A Paketá faz uma análise da empresa para definir se ela é elegível ou não. Essa análise leva em conta faturamento, ramo de atuação, tempo de fundação da empresa e número de colaboradores. O resultado da análise leva cerca de 3 dias úteis.",
  },
  {
    question: "Vou ter algum custo com a contratação do benefício?",
    answer:
      "A contratação e ativação dos benefícios não tem custo para os nossos clientes.",
  },
  {
    question:
      "Meus colaboradores terão algum limite de solicitação de empréstimos?",
    answer:
      "Não existe um limite, mas a soma de todos os empréstimos consignados do colaborador (incluindo em outras empresas de crédito) não deve ultrapassar 35% de seu salário líquido.",
  },
  {
    question: "O que é margem consignável?",
    answer:
      "A margem consignável corresponde ao limite, em % do salário líquido, que pode ser debitado da folha do colaborador. Este valor deve ser atualizado mensalmente pela própria empresa e é limitado a 35%.",
  },
  {
    question: "As taxas variam de acordo com empresa e colaborador?",
    answer:
      "As condições de empréstimo são adaptadas de acordo com a situação individual de cada colaborador e empresa.",
  },
];
