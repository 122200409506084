import { trpc } from "../../../api/client";

type ApproveUseCaseInputParams = {
  companyId: string;
  loanId: string;
  onSuccess: () => void;
  onError: () => void;
};

export const approveLoanRequestUseCase = ({
  companyId,
  loanId,
  onSuccess,
  onError,
}: ApproveUseCaseInputParams) => {
  const approveLoanRequestMutation = trpc.loans.approveLoanRequest.useMutation({
    onSuccess,
    onError,
  });

  return {
    approveLoanRequestMutation: () =>
      approveLoanRequestMutation.mutate({
        companyId,
        loanId,
      }),
    isLoading: approveLoanRequestMutation.isLoading,
  };
};
