import { Tag } from "@flash-tecnologia/hros-web-ui-v2";
import styled from "styled-components";

export const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

export const BoxContainer = styled.div`
  border: 1px solid #ebe6e9;
  border-radius: ${({ theme }) => theme.spacings.m};
  padding: ${({ theme }) => theme.spacings.m};
`;

export const FooterContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-top: 1px solid #ebe6e9;
  padding: ${({ theme }) => theme.spacings.xs};
`;

export const RadioContainer = styled.div<{
  isSelected: boolean;
  disabled?: boolean;
}>`
  border: 1px solid
    ${({ theme, isSelected }) =>
      isSelected ? theme.colors.primary : theme.colors.neutral[90]};
  padding: ${({ theme }) => theme.spacings.xs};
  border-radius: ${({ theme }) => theme.spacings.s};
  opacity: ${({ disabled }) => (disabled ? 0.7 : 1)};
`;
export const RadioTitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 2px;
`;

export const AccordionItemContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${({ theme }) => theme.spacings.xs2};
  gap: ${({ theme }) => theme.spacings.xs4};
`;

export const AccordionItemHeader = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: center;
  gap: ${({ theme }) => theme.spacings.xs4};
`;

export const AccordionItemList = styled.ul`
  padding-left: ${({ theme }) => theme.spacings.xs1};
`;

export const AccordionItemTag = styled(Tag)`
  padding: ${({ theme }) => `${theme.spacings.xs5} ${theme.spacings.xs4}`};
  gap: ${({ theme }) => theme.spacings.xs5};
  font-size: 10px;
  line-height: ${({ theme }) => theme.spacings.xs3};
  border-radius: ${({ theme }) => theme.borders.radius.xl};
  height: ${({ theme }) => theme.spacings.xs1};
`;

export const LoadingContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: ${({ theme }) => theme.spacings.xs2};
`;
