import { Grid } from "@mui/material";
import { useTheme } from "styled-components";
import Spacer from "../../../../../components/Spacer";
import Typography from "../../../../../components/Typography";
import BagCustomIcon from "../assets/BagCustomIcon";
import InvestCustomIcon from "../assets/InvestCustomIcon";
import UserCustomIcon from "../assets/UserCustomIcon";

export enum PartnerCustomIcons {
  BAG,
  INVEST,
  USER,
}

export interface PartnerAdvantageProps {
  icon: PartnerCustomIcons;
  title: string;
  description: string;
}

const PartnerAdvantage = ({
  description,
  icon,
  title,
}: PartnerAdvantageProps) => {
  const IconComponent = {
    [PartnerCustomIcons.BAG]: BagCustomIcon,
    [PartnerCustomIcons.INVEST]: InvestCustomIcon,
    [PartnerCustomIcons.USER]: UserCustomIcon,
  }[icon];
  const theme = useTheme();

  return (
    <>
      {!!IconComponent && (
        <Grid item xs="auto">
          <IconComponent />
          <Spacer y={"xs3"} />
        </Grid>
      )}
      <Grid item xs={12}>
        <Typography.Body1 center color={theme.colors.primary} weight={700}>
          {title}
        </Typography.Body1>
      </Grid>
      <Grid item xs={12}>
        <Typography.Body2 center color={theme.colors.neutral[30]}>
          {description}
        </Typography.Body2>
      </Grid>
    </>
  );
};

export default PartnerAdvantage;
