import { useEffect, useState } from "react";

export function useDebouncedValue<T>(input: {
  inputValue: T;
  delayInMillis: number;
}) {
  const { delayInMillis, inputValue } = input;
  const [debouncedValue, setDebouncedValue] = useState(inputValue);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(inputValue);
    }, delayInMillis);

    return () => {
      clearTimeout(handler);
    };
  }, [inputValue, delayInMillis]);

  return debouncedValue;
}
