import { Loader } from "@flash-tecnologia/hros-web-ui-v2";
import { LoaderContainer } from "./styled";

export const PermissionsLoader = () => {
  return (
    <LoaderContainer>
      <Loader size="large" variant="primary" />
    </LoaderContainer>
  );
};
