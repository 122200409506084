import { useState } from "react";
import {
  TerminationEmployee,
  useTerminationEmployeesListViewModel,
} from "./useTerminationEmployeesListViewModel";

export function useTerminationTabViewModel() {
  const [adminEmail, setAdminEmail] = useState<string>();

  const [isCreatedBillModalOpen, setIsCreatedBillModalOpen] =
    useState<boolean>(false);

  const [isTerminateEmployeeModalOpen, setIsTerminateEmployeeModalOpen] =
    useState<boolean>(false);

  const [selectedTerminationEmployee, setSelectedTerminationEmployee] =
    useState<TerminationEmployee>();

  const handleTerminateEmployeeButtonClick = (
    terminationEmployee: TerminationEmployee
  ) => {
    setSelectedTerminationEmployee(terminationEmployee);
    setIsTerminateEmployeeModalOpen(true);
  };

  const handleTerminateEmployeeModalClose = () => {
    setSelectedTerminationEmployee(undefined);
    setIsTerminateEmployeeModalOpen(false);
  };

  const {
    isLoading: isTerminationEmployeeListLoading,
    onPaginationChange: onTablePaginationChange,
    pageSizeOptions,
    paginationState,
    table,
    onSearchInputChange,
    refetchTerminationEmployees,
    totalCount: terminationEmployeesTotalCount,
  } = useTerminationEmployeesListViewModel({
    onTerminateEmployeeButtonClick: handleTerminateEmployeeButtonClick,
  });

  const handleCreatedBillModalClose = () => {
    setIsCreatedBillModalOpen(false);
    refetchTerminationEmployees();
  };

  const handleTerminateEmployeeSuccess = (adminEmail: string) => {
    setAdminEmail(adminEmail);
    setIsCreatedBillModalOpen(true);
    handleTerminateEmployeeModalClose();
  };

  return {
    adminEmail,
    isCreatedBillModalOpen,
    isTerminateEmployeeModalOpen,
    isTerminationEmployeeListLoading,
    onCreatedBillModalClose: handleCreatedBillModalClose,
    onCreatedBillModalSubmit: handleCreatedBillModalClose,
    onTablePaginationChange,
    onSearchInputChange,
    onTerminateEmployeeModalClose: handleTerminateEmployeeModalClose,
    onTerminateEmployeeSuccess: handleTerminateEmployeeSuccess,
    pageSizeOptions,
    selectedTerminationEmployee,
    table,
    tablePaginationState: paginationState,
    terminationEmployeesTotalCount,
  };
}
