import {
  Button,
  Icons,
  PageContainer,
  PageHeader,
  Tab,
} from "@flash-tecnologia/hros-web-ui-v2";

import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import Spacer from "../../components/Spacer";
import Typography from "../../components/Typography";
import { useTracking } from "../../utils/useTracking";
import { HeaderStyled } from "./styled";
import { usePayrollLoanPageTabsViewModel } from "./viewModel/usePayrollLoanPageTabsViewModel";
import { services } from "../../api/services";
import EnvService from "../../api/EnvService";
import { useTheme } from "styled-components";
import FlexBoxItem from "@atoms/FlexBox/FlexBoxItem";
import FlexBox from "@atoms/FlexBox";

export default function PayrollLoanPage() {
  const tracking = useTracking();
  const [searchParams] = useSearchParams();

  const { selectedTab, tabItens } = usePayrollLoanPageTabsViewModel();
  const payrollLoanPartnerUrl =
    services.payrollLoanPartner[EnvService.BUILD_ENV];
  const theme = useTheme();

  useEffect(() => {
    const sourceQueryParam = searchParams.get("source");
    tracking.trackPage({
      name: "payroll_loan_page",
      ...(sourceQueryParam && {
        params: {
          source: sourceQueryParam,
        },
      }),
    });
  }, []);

  return (
    <>
      <PageHeader>
        <HeaderStyled>
          <FlexBox flexDirection="column">
            <Typography.Headline6>Consignado</Typography.Headline6>
            <Spacer y="xs5" />
            <Typography.Body3>
              Selecione os colaboradores que terão acesso ao empréstimo
              consignado e acompanhe os empréstimos em andamento.
            </Typography.Body3>
          </FlexBox>
          <FlexBoxItem alignSelf="center">
            <Button
              size="large"
              variant="primary"
              variantType="default"
              onClick={() => window.open(payrollLoanPartnerUrl, "_blank")}
            >
              Acompanhar empréstimos
              <Icons
                name="IconExternalLink"
                color={theme.colors.neutral[100]}
                fill={"transparent"}
              />
            </Button>
          </FlexBoxItem>
        </HeaderStyled>
      </PageHeader>
      <PageContainer>
        <Tab selected={selectedTab} tabItens={tabItens} />
      </PageContainer>
    </>
  );
}
