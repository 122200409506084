import FlexBox from "@atoms/FlexBox";
import { Button, Modal, ShapeIcon } from "@flash-tecnologia/hros-web-ui-v2";
import { useTheme } from "styled-components";
import Typography from "../Typography";
import { FooterButtonWrapperStyled } from "./styled";

interface CreatedBillModalProps {
  adminEmail?: string;
  onClose: () => void;
  open: boolean;
  onSubmit: () => void;
}

const CreatedBillModal = ({
  adminEmail,
  onClose,
  open,
  onSubmit,
}: CreatedBillModalProps) => {
  const theme = useTheme();

  return (
    <Modal.Root open={open} onClose={onClose} showClose={false}>
      <>
        <Modal.Content>
          <FlexBox alignItems={"center"} flexDirection={"column"} gap={"xs2"}>
            <FlexBox.Item>
              <ShapeIcon
                name={"IconCheck"}
                size={64}
                color={theme.colors.secondary50}
                variant={"default"}
              />
            </FlexBox.Item>
            <FlexBox.Item>
              <Typography.Body3
                weight={700}
                center
                color={theme.colors.secondary50}
              >
                Pronto!
              </Typography.Body3>
            </FlexBox.Item>
            <FlexBox.Item>
              <Typography.Headline6 center>
                Sua fatura foi criada!
              </Typography.Headline6>
            </FlexBox.Item>
            <FlexBox.Item>
              {!!adminEmail && (
                <Typography.Body3 center color={theme.colors.neutral50}>
                  Assim que ficar pronto, o boleto será enviado para o endereço:{" "}
                  <b>{adminEmail}</b>
                </Typography.Body3>
              )}
              {!adminEmail && (
                <Typography.Body3 center color={theme.colors.neutral50}>
                  Assim que ficar pronto, o boleto será enviado por e-mail
                </Typography.Body3>
              )}
            </FlexBox.Item>
            <FlexBox.Item>
              <Typography.Body3 center color={theme.colors.neutral50}>
                Se precisar de 2ª via do boleto, entre em contato com a{" "}
                <b>Paketá</b> pelo WhatsApp <b>(11) 3381-0459</b>
              </Typography.Body3>
            </FlexBox.Item>
          </FlexBox>
        </Modal.Content>
        <Modal.Footer>
          <FooterButtonWrapperStyled>
            <FlexBox justifyContent="center">
              <FlexBox.Item>
                <Button variant="primary" onClick={onSubmit} size="small">
                  Entendi
                </Button>
              </FlexBox.Item>
            </FlexBox>
          </FooterButtonWrapperStyled>
        </Modal.Footer>
      </>
    </Modal.Root>
  );
};

export default CreatedBillModal;
