import {
  Breadcrumbs,
  Icons,
  LinkButton,
  PageContainer,
  PageHeader,
} from "@flash-tecnologia/hros-web-ui-v2";
import { useSelectedCompany } from "@flash-tecnologia/hros-web-utility";
import { requestContactUseCase } from "@pages/LandingPage/useCases/requestContactUseCase";
import { useTracking } from "@utils/useTracking";
import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useTheme } from "styled-components";
import Spacer from "../../components/Spacer";
import Typography from "../../components/Typography";
import EnvService from "./../../api/EnvService";
import ChooseContactModal from "./components/ChooseContactModal";

const schedulerURL = EnvService.LEADS_MEETINGS_SCHEDULER_URL;
const chatURL = EnvService.LEADS_CHAT_URL;

const LandingPageScheduler = () => {
  const [isChooseContactModalOpen, setIsChooseContactModalOpen] =
    useState(false);

  const theme = useTheme();
  const navigate = useNavigate();
  const { selectedCompany } = useSelectedCompany();
  const [searchParams] = useSearchParams();
  const tracking = useTracking();

  const { requestContactMutation } = requestContactUseCase({});

  const handleChooseContactModalClose = () => {
    setIsChooseContactModalOpen(false);
  };

  const handleChatButtonClick = () => {
    const sourceQueryParam = searchParams.get("source");
    tracking.trackEvent({
      name: "payroll_loan_landing_page_chat_clicked",
      ...(sourceQueryParam && {
        params: {
          source: sourceQueryParam,
        },
      }),
    });
    window.open(chatURL, "_blank");
  };

  useEffect(() => {
    const sourceQueryParam = searchParams.get("source");
    tracking.trackPage({
      name: "payroll_loan_landing_page_scheduler_page",
      ...(sourceQueryParam && {
        params: {
          source: sourceQueryParam,
        },
      }),
    });

    if (chatURL) {
      setIsChooseContactModalOpen(true);
    }

    requestContactMutation({
      companyId: selectedCompany.id,
    });
  }, []);

  return (
    <>
      <ChooseContactModal
        onClose={handleChooseContactModalClose}
        onScheduleMeetingButtonClick={handleChooseContactModalClose}
        open={isChooseContactModalOpen}
        onChatButtonClick={handleChatButtonClick}
      />
      <PageHeader>
        <Breadcrumbs
          breadcrumbs={[
            <LinkButton
              onClick={() => {
                const queryString = searchParams.toString();
                navigate(
                  `/payroll-loan/landing-page${
                    queryString ? `?${queryString}` : ""
                  }`,
                  { replace: true }
                );
              }}
              variant="neutral"
              size="small"
            >
              Consignado
            </LinkButton>,
            <LinkButton variant="neutral" size="small" disabled>
              Falar com especialista
            </LinkButton>,
          ]}
          separator={
            <Icons name="IconChevronRight" fill={theme.colors.neutral100} />
          }
        />
      </PageHeader>
      <PageContainer>
        <Typography.Headline6>Falar com especialista</Typography.Headline6>
        <Spacer y="xs" />
        <iframe
          title={"Agendamento de reunião com especialista"}
          src={schedulerURL}
          height={"850"}
        ></iframe>
      </PageContainer>
    </>
  );
};

export default LandingPageScheduler;
