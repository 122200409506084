import {
  Divider,
  IconTypes,
  ShapeIcon,
  Tag,
  TagProps,
} from "@flash-tecnologia/hros-web-ui-v2";
import { Drawer, Grid } from "@mui/material";
import { useTheme } from "styled-components";
import Spacer from "../../../../../components/Spacer";
import Typography from "../../../../../components/Typography";
import FlexBox from "../../../../../components/atoms/FlexBox";
import LoadingState from "./LoadingState";
import {
  CloseIconButtonStyled,
  DrawerContentStyled,
  DrawerHeaderStyled,
  DrawerWrapper,
} from "./styled";

export enum LoanRequestFields {
  INTEREST_RATE = "INTEREST_RATE",
  INSTALLMENTS_QUANTITY = "INSTALLMENTS_QUANTITY",
  INSTALLMENT_AMOUNT = "INSTALLMENT_AMOUNT",
  CREATED_AT = "CREATED_AT",
}

export enum EmployeeFields {
  CPF = "CPF",
  NAME = "NAME",
  HIRED_AT = "HIRED_AT",
  GROSS_SALARY = "GROSS_SALARY",
  NET_SALARY = "NET_SALARY",
}

export interface LoanRequestStatusTagProps {
  variant: TagProps["variant"];
  description: string;
}

export interface LoanRequestDetailsSection {
  iconType: IconTypes;
  title: string;
  fields:
    | {
        [key in LoanRequestFields]: {
          title: string;
          description: string;
        };
      }
    | {
        [key in EmployeeFields]: {
          title: string;
          description: string;
        };
      };
}

export interface LoanRequestDetailsDrawerProps {
  detailsSections: LoanRequestDetailsSection[];
  employeeName?: string;
  isLoading: boolean;
  loanRequestValue?: string;
  onClose: () => void;
  open: boolean;
  statusTagProps?: LoanRequestStatusTagProps;
}

const LoanRequestDetailsDrawer = ({
  detailsSections,
  employeeName,
  isLoading,
  loanRequestValue,
  onClose,
  open,
  statusTagProps,
}: LoanRequestDetailsDrawerProps) => {
  const theme = useTheme();
  return (
    <Drawer anchor={"right"} open={open} onClose={onClose}>
      <DrawerWrapper>
        <DrawerHeaderStyled>
          <CloseIconButtonStyled
            icon={"IconX"}
            variant={"line"}
            onClick={onClose}
            size={"medium"}
          />
          <Typography.Headline7>
            {"Detalhes da solicitação"}
          </Typography.Headline7>
          <Spacer y={"xs"} />
          {isLoading ? (
            <LoadingState />
          ) : (
            <FlexBox gap={"xs3"} alignItems={"center"}>
              <FlexBox.Item flexGrow={1}>
                <Typography.Headline8 color={theme.colors.neutral[30]}>
                  {employeeName}
                </Typography.Headline8>
              </FlexBox.Item>
              <Typography.Headline8 color={theme.colors.secondary[50]}>
                {loanRequestValue}
              </Typography.Headline8>
              {statusTagProps && (
                <Tag disabled variant={statusTagProps.variant} hasLeftDotIcon>
                  {statusTagProps.description}
                </Tag>
              )}
            </FlexBox>
          )}
        </DrawerHeaderStyled>
        {!!detailsSections?.length && (
          <>
            <Divider orientation={"horizontal"} />
            <DrawerContentStyled>
              {detailsSections.map(({ title, fields, iconType }) => {
                return (
                  <>
                    <Grid
                      container
                      rowSpacing={theme.spacings.xs2}
                      alignItems={"center"}
                    >
                      <Grid
                        item
                        container
                        xs={12}
                        columnSpacing={theme.spacings.xs4}
                      >
                        <Grid item>
                          <ShapeIcon
                            name={iconType}
                            variant={"default"}
                            color={theme.colors.secondary[50]}
                          />
                        </Grid>
                        <Grid item xs>
                          <Typography.Headline8
                            color={theme.colors.neutral[30]}
                          >
                            {title}
                          </Typography.Headline8>
                        </Grid>
                      </Grid>
                      {Object.entries(fields).map(
                        ([key, { title, description }]) => {
                          return (
                            <Grid key={key} item xs={6}>
                              <Typography.Body3 weight={700}>
                                {title}
                              </Typography.Body3>
                              <Typography.Body4>{description}</Typography.Body4>
                            </Grid>
                          );
                        }
                      )}
                    </Grid>
                    <Spacer y={"m"} />
                  </>
                );
              })}
            </DrawerContentStyled>
          </>
        )}
      </DrawerWrapper>
    </Drawer>
  );
};

export default LoanRequestDetailsDrawer;
