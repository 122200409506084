import FlexBox from "@atoms/FlexBox";
import { PageHeader } from "@flash-tecnologia/hros-web-ui-v2";
import styled from "styled-components";

export const PageHeaderStyled = ({ children }) => {
  return <PageHeader style={localStyles.pageHeader}>{children}</PageHeader>;
};

export const FooterWrapperStyled = styled.div`
  padding: ${({ theme }) => `${theme.spacings.xs2} ${theme.spacings.xs}`};
`;

export const CreateBillPageWrapperStyled = styled(FlexBox)`
  height: 100%;
`;

const localStyles = {
  pageHeader: {
    justifyContent: "space-between",
  },
};
