import { trpc } from "../../../api/client";

const getIsCompanyActive = (companyId: string) => {
  const { data, isLoading, refetch } =
    trpc.companies.getIsCompanyActive.useQuery(
      { companyId },
      { refetchOnWindowFocus: false }
    );

  return {
    data,
    isLoading,
    refetch,
  };
};

export const getIsCompanyActiveUseCase = (companyId: string) => {
  const handlegetCompanyIsActive = getIsCompanyActive(companyId);
  return {
    data: handlegetCompanyIsActive.data,
    isLoading: handlegetCompanyIsActive.isLoading,
  };
};
