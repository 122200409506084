import { trpc } from "../../../../../api/client";

type TerminateEmployeeUseCaseInputParams = {
  onSuccess: (result) => void;
  onError: (error) => void;
};

export const terminateEmployeeUseCase = ({
  onSuccess,
  onError,
}: TerminateEmployeeUseCaseInputParams) => {
  const terminateEmployeeMutation =
    trpc.termination.terminateEmployee.useMutation({
      onSuccess,
      onError,
    });

  return {
    terminateEmployeeMutation: terminateEmployeeMutation.mutate,
    isLoading: terminateEmployeeMutation.isLoading,
  };
};
