import { RouterOutput } from "server/src/routers/trpc";
import { trpc } from "../../../../../api/client";

export type FindTerminationEmployeesUseCaseOutput =
  RouterOutput["termination"]["findTerminationEmployees"];

interface FindTerminationEmployeesUseCaseInput {
  companyId: string;
  currentPage: number;
  billetDueDate: string;
  onError: (error) => void;
  pageSize: number;
  searchInput: string;
}

export const findTerminationEmployeesUseCase = ({
  companyId,
  currentPage,
  billetDueDate,
  onError,
  pageSize,
  searchInput,
}: FindTerminationEmployeesUseCaseInput) => {
  const { data, isLoading, isFetching, refetch } =
    trpc.termination.findTerminationEmployees.useQuery(
      {
        companyId,
        currentPage,
        billetDueDate,
        pageSize,
        searchInput,
      },
      { onError, refetchOnWindowFocus: false }
    );

  return {
    data,
    isFetching,
    isLoading,
    refetch,
  };
};
