import { IconTypes } from "@flash-tecnologia/hros-web-ui-v2";
import { NotificationCardProps } from "@flash-tecnologia/hros-web-ui-v2/dist/components/NotificationCard/Root";
import { useTheme } from "styled-components";
import * as SC from "./styles";
import { EmployeeStatus } from "../../../../types/employees";
import { isEqual } from "lodash-es";
import FlexBox from "@atoms/FlexBox";

export interface NotificationBannerProps {
  redirectLink?: {
    onClick: () => void;
    text: string;
  };
  iconName: IconTypes;
  subtitle: string;
  title: string;
  type: NotificationCardProps["type"];
}

interface BigNumbersProps {
  employeesCount?: { total: number; active: number; expired: number };
  onFilter: (data: { name: "status"; values: EmployeeStatus[] }[]) => void;
  currentFilter?: EmployeeStatus[];
}

export const BigNumbers = ({
  employeesCount,
  onFilter,
  currentFilter,
}: BigNumbersProps) => {
  const theme = useTheme();

  const { total = 0, active = 0, expired = 0 } = employeesCount ?? {};

  const handleClick = (filter: EmployeeStatus[]) =>
    onFilter([
      {
        name: "status",
        values: filter,
        ...(filter.length && { initialSelectedOption: filter[0] }),
      },
    ]);

  const isFilteredByActive = isEqual(currentFilter, [EmployeeStatus.Active]);
  const isFilteredByExpired = isEqual(currentFilter, [EmployeeStatus.Expired]);

  return (
    <FlexBox justifyContent="space-between" flexDirection="row">
      <SC.BigNumber
        orientation="horizontal"
        icon="IconUserDollar"
        title="Pessoas ativas na Base"
        iconColors={{
          primary: theme.colors.secondary[50],
          secondary: theme.colors.secondary[95],
        }}
        value={active}
        button={
          <SC.LinkButtonContainer>
            <SC.LinkButtonStyled
              variant="default"
              size="small"
              onClick={() =>
                handleClick(isFilteredByActive ? [] : [EmployeeStatus.Active])
              }
              color={theme.colors.primary}
            >
              {isFilteredByActive ? "Remover filtro" : "Filtrar"}
            </SC.LinkButtonStyled>
          </SC.LinkButtonContainer>
        }
        blur={isFilteredByExpired}
      />
      <SC.BigNumber
        orientation="horizontal"
        icon="IconAlertTriangle"
        title="Pessoas expiradas na Base"
        iconColors={{
          primary: theme.colors.feedback.error[40],
          secondary: theme.colors.feedback.error[90],
        }}
        value={expired}
        button={
          <SC.LinkButtonContainer>
            <SC.LinkButtonStyled
              variant="default"
              size="small"
              onClick={() =>
                handleClick(isFilteredByExpired ? [] : [EmployeeStatus.Expired])
              }
              color={theme.colors.primary}
            >
              {isFilteredByExpired ? "Remover filtro" : "Filtrar"}
            </SC.LinkButtonStyled>
          </SC.LinkButtonContainer>
        }
        blur={isFilteredByActive}
      />
      <SC.BigNumber
        orientation="horizontal"
        icon="IconUsers"
        title="Total de pessoas na Equipe"
        iconColors={{
          primary: theme.colors.secondary[50],
          secondary: theme.colors.secondary[95],
        }}
        value={total}
        button={
          <SC.LinkButtonContainer>
            <SC.LinkButtonStyled
              variant="default"
              size="small"
              onClick={() => handleClick([])}
              color={theme.colors.primary}
              disabled={!isFilteredByActive && !isFilteredByExpired}
            >
              Remover filtros
            </SC.LinkButtonStyled>
          </SC.LinkButtonContainer>
        }
        blur={isFilteredByActive || isFilteredByExpired}
      />
    </FlexBox>
  );
};
