import { dispatchToast } from "@utils/dispatchEvents";
import { useContext, useEffect } from "react";
import { CreateBillPageContext } from "..";
import { UploadedFile } from "../../../components/organisms/FileUploader/useFileUploader.hook";

export function useUploadBillFileViewModel() {
  const {
    enableContinueButton,
    disableContinueButton,
    onFileUpload,
    onUploadedFileClear,
  } = useContext(CreateBillPageContext);

  const handleUploadedFileClear = () => {
    onUploadedFileClear?.();
    disableContinueButton?.();
  };

  const handleFileUpload = (uploadedFile: UploadedFile) => {
    onFileUpload?.(uploadedFile);
    enableContinueButton?.();
  };

  const handleUploadError = () => {
    dispatchToast({
      type: "error",
      content:
        "Não foi possível enviar seu arquivo. Tente novamente mais tarde.",
    });
    disableContinueButton?.();
  };

  useEffect(() => {
    disableContinueButton?.();
  }, []);

  return {
    onFileChange: handleFileUpload,
    onUploadError: handleUploadError,
    onUploadedFileClear: handleUploadedFileClear,
  };
}
