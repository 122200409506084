import { dispatchToast } from "../../../utils/dispatchEvents";
import { trpc } from "../../../api/client";

export const handleSendFinishedFileProcessing = (
  companyId: string,
  bucketPath: string,
  onSuccess?: () => void
) => {
  const sendFinishedFileProcessingMutation =
    trpc.fileProcessing.sendFinishedFileProcessing.useMutation({
      onSuccess: () => {
        dispatchToast({
          type: "warning",
          content: "Recebemos a planilha e estamos processando",
          description: "Isso pode demorar alguns minutos.",
        });
        onSuccess?.();
      },
      onError: () => {
        dispatchToast({
          type: "error",
          content: "Algo deu errado",
          description: "Verifique os erros encontrados",
        });
      },
    });
  return {
    sendFinishedFileProcessingMutation: function () {
      sendFinishedFileProcessingMutation.mutate({ companyId, bucketPath });
    },
    isLoading: sendFinishedFileProcessingMutation.isLoading,
  };
};
