import { StepperProps } from "@flash-tecnologia/hros-web-ui-v2";
import { useSelectedCompany } from "@flash-tecnologia/hros-web-utility";
import { dispatchToast } from "@utils/dispatchEvents";
import { getAdminUseCase } from "@utils/useCases/getAdminUseCase";
import { ReactNode, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { PAYROLL_LOAN_GENERIC_ERROR_MESSAGE } from "server/src/utils/errorHandlers/payrollLoan.errors";
import { UploadedFile } from "../../../components/organisms/FileUploader/useFileUploader.hook";
import BillSummary from "../components/BillSummary";
import DownloadBillFileTemplate from "../components/DownloadBillFileTemplate";
import UploadBillFile from "../components/UploadBillFile";
import { createBillUseCase } from "../useCases/createBillUseCase";
import ValidateBillFile from "../components/ValidateBillFile";
import { BillFileFilterState } from "../components/types";
import { dayjs } from "@utils/dayjs";
import {
  BillsErrorCodes,
  getBillFileUserFriendlyErrorMessage,
} from "server/src/utils/errorHandlers/bills.errors";
import { PayrollLoanPageTabs } from "../../../types/payrollLoanPageTabs";

enum CreateBillPageSteps {
  DOWNLOAD_BILL_FILE_TEMPLATE = "DOWNLOAD_BILL_FILE_TEMPLATE",
  UPLOAD_BILL_FILE = "UPLOAD_BILL_FILE",
  VALIDATING_BILL_FILE = "VALIDATING_BILL_FILE",
  BILL_SUMMARY = "BILL_SUMMARY",
}

interface CreateBillPageStep {
  id: CreateBillPageSteps;
  index: number;
  name: string;
  title: string;
  description: string;
  content?: ReactNode;
}

export interface Bill {
  employees: { name: string; documentNumber: string }[];
  value: number;
}

export function useCreateBillPageViewModel() {
  const location = useLocation();
  const state = location.state as BillFileFilterState | null;
  const startDate = dayjs()
    .add(1, "month")
    .startOf("month")
    .format("YYYY-MM-DD");
  const endDate = dayjs(startDate).endOf("month").format("YYYY-MM-DD");

  const [filter, setFilter] = useState<BillFileFilterState>();

  useEffect(() => {
    if (state) {
      setFilter(state as BillFileFilterState);
    }
  }, [state]);

  const CREATE_BILL_PAGE_STEPS = {
    [CreateBillPageSteps.DOWNLOAD_BILL_FILE_TEMPLATE]: {
      id: CreateBillPageSteps.DOWNLOAD_BILL_FILE_TEMPLATE,
      index: 0,
      name: "Prepare o arquivo",
      title: "Prepare o arquivo",
      description:
        "Faça o download do arquivo de parcelas e preencha os descontos realizados. Nos próximos passos você vai poder conferir tudo e corrigir possíveis erros.",
      content: <DownloadBillFileTemplate />,
    },
    [CreateBillPageSteps.UPLOAD_BILL_FILE]: {
      id: CreateBillPageSteps.UPLOAD_BILL_FILE,
      index: 1,
      name: "Envie o arquivo",
      title: "Envie o arquivo",
      description:
        "Envie o arquivo para processamento e acompanhe o andamento. Esse processo pode demorar alguns minutos.",
      content: <UploadBillFile />,
    },
    [CreateBillPageSteps.VALIDATING_BILL_FILE]: {
      id: CreateBillPageSteps.VALIDATING_BILL_FILE,
      index: 2,
      name: "Importando informações",
      title: "Importando informações",
      description:
        "Envie o arquivo para processamento e acompanhe o andamento. Esse processo pode demorar alguns minutos.",
      content: <ValidateBillFile />,
    },
    [CreateBillPageSteps.BILL_SUMMARY]: {
      id: CreateBillPageSteps.BILL_SUMMARY,
      index: 3,
      name: "Resumo da fatura",
      title: "Revise os detalhes da fatura",
      description:
        "Aproveite pra conferir os valores e pessoas que fazem parte da fatura.",
      content: <BillSummary />,
    },
  };

  const stepperProps: StepperProps = {
    steps: Object.values(CREATE_BILL_PAGE_STEPS).map((step) => step.name),
    disableClick: true,
  };

  const initialUploadedFile: UploadedFile = {
    data: new ArrayBuffer(0),
  };
  const [uploadedFile, setUploadedFile] =
    useState<UploadedFile>(initialUploadedFile);

  const initialBill: Bill = {
    value: 0,
    employees: [],
  };
  const [bill, setBill] = useState<Bill>(initialBill);
  const [billFileTemplatePath, setBillFileTemplatePath] = useState<string>();
  const [billFilePath, setBillFilePath] = useState<string>("");
  const handleBillChange = (bill: Bill) => {
    setBill(bill);
  };

  const [currentStep, setCurrentStep] = useState<CreateBillPageStep>(
    Object.values(CREATE_BILL_PAGE_STEPS)[0]
  );

  const [isContinueButtonDisabled, setIsContinueButtonDisabled] =
    useState<boolean>(true);

  const [createdBillModalOpen, setCreatedBillModalOpen] =
    useState<boolean>(false);

  const navigate = useNavigate();
  const { selectedCompany } = useSelectedCompany();

  const { data, handleGetAdmin } = getAdminUseCase({
    companyId: selectedCompany.id,
    onError: (error) => {
      dispatchToast({
        type: "error",
        content:
          error.userFriendlyMessage ?? PAYROLL_LOAN_GENERIC_ERROR_MESSAGE,
      });
    },
  });

  const adminEmail = data?.email;

  const handleBillCreateError = (error) => {
    dispatchToast({
      type: "error",
      content: error.userFriendlyMessage ?? PAYROLL_LOAN_GENERIC_ERROR_MESSAGE,
    });
  };

  const handleCreatedBillModalClose = () => {
    setCreatedBillModalOpen(false);
    navigate("/payroll-loan", {
      replace: true,
      state: {
        initialTab: PayrollLoanPageTabs.Installments,
      },
    });
  };

  const handleUploadedFileClear = () => {
    setUploadedFile(initialUploadedFile);
  };

  const { handleBillCreate, isLoading } = createBillUseCase({
    onError: handleBillCreateError,
    onSuccess: () => setCreatedBillModalOpen(true),
  });

  const currentStepId = currentStep.id;
  const stepsCount = Object.keys(CREATE_BILL_PAGE_STEPS).length;
  const isCurrentStepLastStep =
    currentStepId === Object.keys(CREATE_BILL_PAGE_STEPS)[stepsCount - 1];

  const handleContinueButtonClick = () => {
    if (isCurrentStepLastStep) {
      if (!adminEmail) {
        dispatchToast({
          type: "error",
          content: getBillFileUserFriendlyErrorMessage(
            BillsErrorCodes.MissingAdminEmail
          ),
        });
        return;
      }
      handleBillCreate?.({
        adminEmail,
        companyId: selectedCompany?.id,
        startDate: filter?.startDate ?? startDate,
        endDate: filter?.endDate ?? endDate,
        billFilePath,
      });
      return;
    }

    const nextStep =
      CREATE_BILL_PAGE_STEPS[
        Object.keys(CREATE_BILL_PAGE_STEPS)[currentStep.index + 1]
      ];

    setCurrentStep(nextStep);
  };

  const handleReuploadBillFileButtonClick = () => {
    setCurrentStep(
      CREATE_BILL_PAGE_STEPS[CreateBillPageSteps.UPLOAD_BILL_FILE]
    );
  };

  const handleValidateBillFileError = () => {
    const uploadBillFileStep =
      CREATE_BILL_PAGE_STEPS[CreateBillPageSteps.UPLOAD_BILL_FILE];

    setCurrentStep(uploadBillFileStep);
  };

  useEffect(() => {
    handleGetAdmin();
  }, []);

  return {
    adminEmail: data?.email,
    bill,
    billFileTemplatePath,
    currentStep,
    disableContinueButton: () => setIsContinueButtonDisabled(true),
    enableContinueButton: () => setIsContinueButtonDisabled(false),
    isContinueButtonDisabled,
    isCreatedBillModalOpen: createdBillModalOpen,
    isLoading,
    onBillChange: handleBillChange,
    onBillFileTemplatePathChange: setBillFileTemplatePath,
    onBillFilePathChange: setBillFilePath,
    onContinueButtonClick: handleContinueButtonClick,
    onCreatedBillModalClose: handleCreatedBillModalClose,
    onFileUpload: setUploadedFile,
    onReuploadBillFileButtonClick: handleReuploadBillFileButtonClick,
    onUploadedFileClear: handleUploadedFileClear,
    onValidateBillFileError: handleValidateBillFileError,
    stepperProps,
    uploadedFile,
  };
}
