import { Icons, tableControllers } from "@flash-tecnologia/hros-web-ui-v2";
import { PaginationState } from "@flash-tecnologia/hros-web-ui-v2/dist/components/Table/components/Pagination";
import { useSelectedCompany } from "@flash-tecnologia/hros-web-utility";
import { dayjs } from "@utils/dayjs";
import { cpfMask } from "@utils/masks/formatCPF";
import { useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTheme } from "styled-components";
import Typography from "../../../components/Typography";
import FlexBox from "../../../components/atoms/FlexBox";
import UserTableCell from "../../../components/molecules/UserTableCell";
import { Installment } from "../../../types/installments";
import { toCurrency } from "../../../utils/masks/toCurrency";
import { CurrencyCellStyled } from "../components/LoanRequestsTab/styled";
import {
  findInstallmentsUseCase,
  FindTerminationEmployeesUseCaseOutput,
} from "../useCases/findInstallmentsUseCase";

interface PaginationQueryMap {
  [page: number]: {
    startingAfterInstallmentId?: string;
    installmentsCount?: number;
    hasMore?: boolean;
  };
}

const INITIAL_PAGINATION_STATE = {
  pageNumber: 1,
  pageSize: 10,
};

export function useInstallmentsTabViewModel() {
  const [paginationState, setPaginationState] = useState<PaginationState>(
    INITIAL_PAGINATION_STATE
  );

  const [monthFilter, setMonthFilter] = useState<string>(
    dayjs().add(1, "month").format("MM")
  );
  const [yearFilter, setYearFilter] = useState<string>(dayjs().format("YYYY"));
  const [paginationQueryMap, setPaginationQueryMap] =
    useState<PaginationQueryMap>({
      1: {},
    });

  const theme = useTheme();

  const handleFindInstallmentsSuccess = (response) =>
    setPaginationQueryMap((prevState) => {
      return {
        ...prevState,
        [paginationState.pageNumber]: {
          startingAfterInstallmentId:
            prevState[paginationState.pageNumber].startingAfterInstallmentId,
          installmentsCount: response.data.length,
          hasMore: response.hasMore,
        },
      };
    });

  const { selectedCompany } = useSelectedCompany();
  const navigate = useNavigate();

  const startDate = `${yearFilter}-${monthFilter}-01`;
  const endDate = dayjs(startDate).endOf("month").format("YYYY-MM-DD");

  const { data: findInstallmentsResponse, isLoading } = findInstallmentsUseCase(
    {
      companyId: selectedCompany?.id,
      onSuccess: handleFindInstallmentsSuccess,
      pagination: paginationState,
      startingAfterInstallmentId:
        paginationQueryMap[paginationState.pageNumber]
          ?.startingAfterInstallmentId,
      startDate,
      endDate,
    }
  );

  const handlePaginationChange = ({ pageSize, pageNumber }) => {
    const currentPaginationQueryMap = paginationQueryMap[pageNumber];

    const pageSizeChanged = pageSize !== paginationState.pageSize;

    if (pageSizeChanged) {
      setPaginationQueryMap({
        1: {},
      });
    } else if (!currentPaginationQueryMap) {
      setPaginationQueryMap((prevState) => {
        return {
          ...prevState,
          [pageNumber]: {
            startingAfterInstallmentId:
              installments[installments.length - 1].id,
          },
        };
      });
    }

    setPaginationState({ ...paginationState, pageSize, pageNumber });
  };

  const table = tableControllers.useTableColumns<
    FindTerminationEmployeesUseCaseOutput["data"][number]
  >({
    data: findInstallmentsResponse?.data ?? [],
    total: findInstallmentsResponse?.total ?? 0,
    columns: [
      {
        header: "Nome",
        accessorKey: "name",
        cell: ({ cell }) => {
          const { name, documentNumber } = cell.row.original;
          const documentNumberFormatted = cpfMask(documentNumber);
          return (
            <UserTableCell name={name} document={documentNumberFormatted} />
          );
        },
      },
      {
        header: "Valor da parcela (R$)",
        accessorKey: "value",
        cell: ({ cell }) => {
          const installmentAmount = cell?.row?.original?.value;
          const formattedAmount = toCurrency(installmentAmount, true);

          return (
            <CurrencyCellStyled color={theme.colors.neutral[40]} weight={600}>
              {formattedAmount}
            </CurrencyCellStyled>
          );
        },
      },
      {
        header: "Valor pago (R$)",
        accessorKey: "amountPaid",
        cell: ({ cell }) => {
          const amountPaid = cell?.row?.original?.amountPaid;
          const formattedAmount = toCurrency(amountPaid, true);

          return (
            <CurrencyCellStyled color={theme.colors.neutral[40]} weight={600}>
              {formattedAmount}
            </CurrencyCellStyled>
          );
        },
      },
      {
        header: "Número da parcela",
        accessorKey: "currentInstallmentIndex",
        cell: ({ cell }) => {
          const currentInstallmentIndex =
            cell?.row?.original?.currentInstallmentIndex;
          const installmentsQuantity =
            cell?.row?.original?.installmentsQuantity;
          const columnText = `${currentInstallmentIndex} de ${installmentsQuantity}`;

          return (
            <CurrencyCellStyled color={theme.colors.neutral[40]} weight={600}>
              {columnText}
            </CurrencyCellStyled>
          );
        },
      },
      {
        header: "Data de vencimento",
        accessorKey: "dueDate",
        cell: ({ cell }) => {
          const dueDate = cell?.row?.original?.dueDate;
          const formattedDate = dueDate && dayjs(dueDate).format("DD/MM/YYYY");

          return (
            <FlexBox alignItems={"center"} gap={"xs2"}>
              <Icons
                name="IconCalendar"
                fill={"transparent"}
                size={24}
                color={theme.colors.neutral[30]}
              />
              <Typography.Body3 color={theme.colors.neutral[40]} weight={600}>
                {formattedDate}
              </Typography.Body3>
            </FlexBox>
          );
        },
      },
    ],
    pagination: paginationState,
    onPaginationChange: handlePaginationChange,
  });

  const handleCreateBillClick = useCallback(() => {
    navigate("/payroll-loan/create-bill", {
      replace: true,
      state: {
        startDate,
        endDate,
      },
    });
  }, [startDate, endDate]);

  const handleFilter = (filters) => {
    if (!filters.length) {
      setMonthFilter("");
      setYearFilter("");
    } else {
      const newMonthFilter = filters.find(
        ({ name }) => name === monthFilterConfig.name
      );
      if (newMonthFilter) {
        const newMonthFilterValue = newMonthFilter?.values?.[0] || "";
        if (newMonthFilterValue !== monthFilter) {
          setMonthFilter(newMonthFilterValue);
        }
      }

      const newYearFilter = filters.find(
        ({ name }) => name === yearFilterConfig.name
      );

      if (newYearFilter) {
        const newYearFilterValue = newYearFilter?.values?.[0] || "";
        if (newYearFilterValue !== yearFilter) {
          setYearFilter(newYearFilterValue);
        }
      }
    }
    setPaginationState(INITIAL_PAGINATION_STATE);
    setPaginationQueryMap({
      1: {},
    });
  };

  const monthFilterConfig = {
    label: "mês",
    name: "month",
    options: dayjs.months().map((month, index) => {
      const value = dayjs().month(index).format("MM");
      return {
        isActive: value === "01",
        label: month,
        value,
      };
    }),
    initialSelectedOption: dayjs().add(1, "month").format("MM"),
  };

  const yearFilterConfig = {
    label: "ano",
    name: "year",
    options: [
      {
        label: dayjs().format("YYYY"),
        value: dayjs().format("YYYY"),
      },
    ],
    initialSelectedOption: dayjs().format("YYYY"),
  };

  const getTableFilters = () => {
    return [monthFilterConfig, yearFilterConfig];
  };

  const installments: Installment[] = findInstallmentsResponse?.data ?? [];

  const tableFilters = getTableFilters();

  const totalCount = Object.values(paginationQueryMap).reduce(
    (acc, currentPage, index) => {
      acc += currentPage.installmentsCount ?? 0;
      const isLastPage = index === Object.values(paginationQueryMap).length - 1;
      const shouldDisplayNextPage = isLastPage && currentPage.hasMore;
      if (shouldDisplayNextPage) {
        acc++;
      }
      return acc;
    },
    0
  );

  const isCreateBillButtonDisabled =
    !installments.length ||
    monthFilter !== dayjs().add(1, "month").format("MM");

  return {
    isCreateBillButtonDisabled,
    installments,
    loading: isLoading,
    onCreateBillClick: handleCreateBillClick,
    onFilter: handleFilter,
    onPaginationChange: handlePaginationChange,
    paginationState,
    pageSizeOptions: [
      {
        label: "2 itens",
        value: 2,
      },
      {
        label: "5 itens",
        value: 5,
      },
      {
        label: "10 itens",
        value: 10,
      },
    ],
    table,
    tableFilters,
    totalCount,
  };
}
