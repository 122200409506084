import {
  Button,
  IconTypes,
  Icons,
  ShapeIconOptions,
} from "@flash-tecnologia/hros-web-ui-v2";
import { useSelectedCompany } from "@flash-tecnologia/hros-web-utility";
import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useTheme } from "styled-components";
import { getLastFileProcessingUseCase } from "../../PayrollLoan/useCases/getLastFileProcessingUseCase";
import { StatusDetailsProps } from "../components/StatusDetails";
import { StatusHeaderProps } from "../components/StatusHeader";

enum UploadSheetInfoPageTypes {
  SheetProcessingError = "SHEET_PROCESSING_ERROR",
  UpsertEmployeeError = "UPSERT_EMPLOYEE_ERROR",
  Processing = "PROCESSING",
  Success = "SUCCESS",
}

type ErrorType = {
  message: string;
  line: number;
};

type LocationState = {
  errors: ErrorType[];
  successLines: number;
  errorLines: number;
};

export function useUploadSheetInfoViewModel() {
  const { selectedCompany } = useSelectedCompany();
  const navigate = useNavigate();
  const theme = useTheme();
  const location = useLocation();

  const getStatusHeaderProps = (
    uploadSheetInfoPageType: UploadSheetInfoPageTypes,
    successfullImports: number,
    failureImports: number,
    totalImports: number
  ): StatusHeaderProps | undefined => {
    const completedImports = failureImports + successfullImports;
    return {
      [UploadSheetInfoPageTypes.Success]: {
        description: `${completedImports} de ${totalImports} pessoa(s) importada(s)`,
        shapeIconProps: {
          name: "IconCheck" as IconTypes,
          size: 48,
          variant: "success" as ShapeIconOptions,
          color: theme.colors.feedback.success[40],
        },
        title: "Sucesso na importação!",
      },
      [UploadSheetInfoPageTypes.UpsertEmployeeError]: {
        description: `${successfullImports} pessoas foram importadas com sucesso. ${failureImports} pessoas não puderam ser importadas.`,
        shapeIconProps: {
          name: "IconAlertTriangle" as IconTypes,
          size: 48,
          variant: "error" as ShapeIconOptions,
          color: theme.colors.feedback.error[50],
        },
        title: "Processamento finalizado",
      },
      [UploadSheetInfoPageTypes.SheetProcessingError]: {
        description: `Houve uma falha no preenchimento da planilha impedindo que ela seja analisada. Será necessária uma nova importação do arquivo corrigido.`,
        shapeIconProps: {
          name: "IconAlertTriangle" as IconTypes,
          size: 48,
          variant: "error" as ShapeIconOptions,
          color: theme.colors.feedback.error[50],
        },
        title: "Não foi possível concluir o seu processamento",
      },
      [UploadSheetInfoPageTypes.Processing]: {
        description:
          "Assim que o processamento do seu arquivo for finalizado, notificaremos aqui na plataforma o resultado da importação :)",
        shapeIconProps: {
          name: "IconRefresh" as IconTypes,
          size: 48,
          variant: "default" as ShapeIconOptions,
          color: theme.colors.primary,
        },
        title: "Em processamento",
      },
    }[uploadSheetInfoPageType];
  };

  const getStatusDetailsProps = (
    uploadSheetInfoPageType: UploadSheetInfoPageTypes,
    successfullImports: number,
    failureImports: number,
    totalImports: number
  ): StatusDetailsProps | undefined => {
    const completedImports = successfullImports + failureImports;
    const progress = totalImports
      ? Math.floor((completedImports * 100) / totalImports)
      : 0;
    return {
      [UploadSheetInfoPageTypes.Success]: {
        description: `${successfullImports} pessoa(s) cadastrada(s) com sucesso. Confira na lista de pessoas os cadastros importados.`,
        progress: 100,
        title: "Sucesso na importação!",
      },
      [UploadSheetInfoPageTypes.UpsertEmployeeError]: {
        description:
          "Confira abaixo os erros. Você deverá refazer a importação dessas pessoas.",
        title: "Alguns cadastros não puderam ser importados.",
      },
      [UploadSheetInfoPageTypes.Processing]: {
        description: `${completedImports} de ${totalImports} pessoas importadas`,
        progress,
        title: "Progresso",
      },
    }[uploadSheetInfoPageType];
  };

  const RedirectPayrollLoanPageButton = ({
    buttonType,
  }: {
    buttonType: "primary" | "secondary";
  }) => {
    const iconColor = {
      primary: theme.colors.neutral[100],
      secondary: theme.colors.secondary[40],
    }[buttonType];

    return (
      <Button
        size="large"
        variant={buttonType}
        variantType="default"
        onClick={() => navigate("/payroll-loan", { replace: true })}
      >
        <Icons
          name="IconArrowNarrowLeft"
          color={iconColor}
          fill="transparent"
        />
        Voltar para a lista de pessoas
      </Button>
    );
  };

  const RedirectUploadSheetPageButton = () => {
    return (
      <Button
        size="large"
        variant="primary"
        variantType="default"
        onClick={() =>
          navigate("/payroll-loan/upload-sheet", { replace: true })
        }
      >
        Nova importação
        <Icons name="Add" color={theme.colors.neutral[100]} />
      </Button>
    );
  };

  const getSecondaryButton = (
    uploadSheetInfoPageType: UploadSheetInfoPageTypes
  ): React.FC | undefined => {
    return {
      [UploadSheetInfoPageTypes.SheetProcessingError]: () => (
        <RedirectPayrollLoanPageButton buttonType={"secondary"} />
      ),
      [UploadSheetInfoPageTypes.UpsertEmployeeError]: () => (
        <RedirectPayrollLoanPageButton buttonType={"secondary"} />
      ),
    }[uploadSheetInfoPageType];
  };

  const getPrimaryButton = (
    uploadSheetInfoPageType: UploadSheetInfoPageTypes
  ): React.FC | undefined => {
    return {
      [UploadSheetInfoPageTypes.SheetProcessingError]: () => (
        <RedirectUploadSheetPageButton />
      ),
      [UploadSheetInfoPageTypes.UpsertEmployeeError]: () => (
        <RedirectUploadSheetPageButton />
      ),
      [UploadSheetInfoPageTypes.Processing]: () => (
        <RedirectPayrollLoanPageButton buttonType={"primary"} />
      ),
      [UploadSheetInfoPageTypes.Success]: () => (
        <RedirectPayrollLoanPageButton buttonType={"primary"} />
      ),
    }[uploadSheetInfoPageType];
  };

  const handleGetLastFileProcessing = getLastFileProcessingUseCase(
    selectedCompany.id
  );

  const lastFileProcessing = handleGetLastFileProcessing.data
    ?.lastFileProcessing ?? {
    errors: [],
    lines: {
      success: 0,
      failed: 0,
      total: 0,
    },
    status: "",
    updatedAt: undefined,
  };

  const { lines } = lastFileProcessing;

  const progress = lines.total
    ? ((lines.success + lines.failed) * 100) / lines.total
    : 0;

  const getUploadSheetInfoPageType = (
    hasErrors: boolean,
    status: string
  ): UploadSheetInfoPageTypes | undefined => {
    if (status === "FINISHED" && !hasErrors) {
      return UploadSheetInfoPageTypes.Success;
    }
    if (status === "FAILED") {
      return UploadSheetInfoPageTypes.SheetProcessingError;
    }
    if (status === "FINISHED" && hasErrors) {
      return UploadSheetInfoPageTypes.UpsertEmployeeError;
    }
    if (status === "PROCESSING") {
      return UploadSheetInfoPageTypes.Processing;
    }
  };

  const uploadSheetInfoPageType = getUploadSheetInfoPageType(
    !!lastFileProcessing.errors?.length,
    lastFileProcessing.status
  );

  useEffect(() => {
    const intervalId = setInterval(handleGetLastFileProcessing.refetch, 5000);

    return () => {
      clearInterval(intervalId);
    };
  }, [handleGetLastFileProcessing]);

  if (location.state) {
    const { errors, successLines, errorLines } =
      location.state as LocationState;
    const shouldRenderErrorPageType = !!errors;
    if (shouldRenderErrorPageType) {
      const totalImports = successLines + errorLines;
      const uploadSheetInfoPageType =
        UploadSheetInfoPageTypes.UpsertEmployeeError;
      return {
        status: lastFileProcessing.status,
        errors,
        isLoading: false,
        PrimaryButton: getPrimaryButton(uploadSheetInfoPageType),
        SecondaryButton: getSecondaryButton(uploadSheetInfoPageType),
        statusHeaderProps: getStatusHeaderProps(
          uploadSheetInfoPageType,
          successLines,
          errorLines,
          totalImports
        ),
        statusDetailsProps:
          uploadSheetInfoPageType &&
          getStatusDetailsProps(
            uploadSheetInfoPageType,
            successLines,
            errorLines,
            totalImports
          ),
      };
    }
  }

  return {
    isLoading: handleGetLastFileProcessing.isLoading,
    ...(uploadSheetInfoPageType
      ? {
          status: lastFileProcessing.status,
          errors: lastFileProcessing.errors,
          PrimaryButton: getPrimaryButton(uploadSheetInfoPageType),
          progress,
          SecondaryButton: getSecondaryButton(uploadSheetInfoPageType),
          statusHeaderProps: getStatusHeaderProps(
            uploadSheetInfoPageType,
            lines.success,
            lines.failed,
            lines.total
          ),
          statusDetailsProps: getStatusDetailsProps(
            uploadSheetInfoPageType,
            lines.success,
            lines.failed,
            lines.total
          ),
        }
      : {}),
  };
}
