import { useRef, useState } from "react";
import {
  UpsertEmployeeInputDTO,
  upsertEmployeeUseCase,
} from "../useCases/upsertEmployeeUseCase";

export function useEditEmployeeViewModel() {
  const [employeeData, setEmployeeData] = useState<any>({});
  const onUpsertEmployeeSuccess = useRef<() => void>();

  const [editEmployeeModalVisibility, setEditEmployeeModalVisibility] =
    useState(false);

  const hideEditEmployeeModal = () => {
    setEmployeeData({});
    setEditEmployeeModalVisibility(false);
  };

  const handleUpsertEmployeeSucess = () => {
    hideEditEmployeeModal();
    onUpsertEmployeeSuccess.current?.();
  };

  const {
    isLoading: isUpsertEmployeeLoading,
    upsertEmployeeMutation: handleUpsertEmployee,
  } = upsertEmployeeUseCase({
    onSuccess: handleUpsertEmployeeSucess,
  });

  const handleEditEmployeeClick = (
    employee: any,
    handleEditEmployeeSuccess?: () => void
  ) => {
    setEmployeeData(employee);
    setEditEmployeeModalVisibility(true);
    onUpsertEmployeeSuccess.current = handleEditEmployeeSuccess;
  };

  const handleConfirmUpsert = (employeeData: UpsertEmployeeInputDTO) => {
    handleUpsertEmployee(employeeData);
  };

  return {
    editEmployeeModalVisibility,
    handleUpsertCancel: hideEditEmployeeModal,
    handleUpsertEmployee: handleConfirmUpsert,
    onEditEmployeeClick: handleEditEmployeeClick,
    isUpsertEmployeeLoading: isUpsertEmployeeLoading,
    employeeData,
  };
}
