import { MonitoringErrorBoundary } from "@flash-tecnologia/hros-web-utility";

export class ErrorBoundary extends MonitoringErrorBoundary {
  project =
    "https://cc5bd3d2095e410ca41cb80c1468ed20@o266934.ingest.sentry.io/4505563411382272";

  constructor(props) {
    super(props);
  }
}
