import {
  useSelectedCompany,
  usePermissions,
} from "@flash-tecnologia/hros-web-utility";
import { Navigate, Outlet, useSearchParams } from "react-router-dom";
import { getIsCompanyActiveUseCase } from "../useCases/getIsCompanyActiveUseCase";
import { PermissionsLoader } from "../components/Loader";

enum PayrollLoanPermissions {
  VIEWER = "payroll_loan_viewer",
  MANAGER = "payroll_loan_manager",
}

export default function VerifyPermissionsProductPage() {
  const permissions = usePermissions();
  const { selectedCompany } = useSelectedCompany();
  const [searchParams] = useSearchParams();
  const selectedCompanyId = selectedCompany?.id;
  const userCompanies = permissions?.companies ?? [];
  const { data, isLoading } = getIsCompanyActiveUseCase(selectedCompanyId);

  if (isLoading) {
    return <PermissionsLoader />;
  }

  const isProductActive = data?.isActive;
  const isAdmin = permissions?.isAdmin;

  const companyPermissions =
    userCompanies.find((c) => c._id === selectedCompanyId)?.permissions ?? [];
  const canView = companyPermissions.includes(PayrollLoanPermissions.VIEWER);

  if ((isAdmin || canView) && isProductActive) {
    return <Outlet />;
  } else if ((isAdmin || canView) && !isProductActive) {
    const queryString = searchParams.toString();
    return (
      <Navigate
        to={`/payroll-loan/landing-page${queryString ? `?${queryString}` : ""}`}
      />
    );
  }

  return <Navigate to={"/home"} />;
}
