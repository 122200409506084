import FlexBox from "@atoms/FlexBox";
import FlexBoxItem from "@atoms/FlexBox/FlexBoxItem";
import { NotificationCard, Table, Tag } from "@flash-tecnologia/hros-web-ui-v2";
import { useTheme } from "styled-components";
import CreatedBillModal from "../../../../components/CreatedBillModal";
import Spacer from "../../../../components/Spacer";
import Typography from "../../../../components/Typography";
import TerminateEmployeeModal from "./components/TerminateEmployeeModal";
import { useTerminationTabViewModel } from "./viewModel/useTerminationTabViewModel";

const TerminationsTab = () => {
  const theme = useTheme();
  const {
    adminEmail,
    isCreatedBillModalOpen,
    isTerminationEmployeeListLoading,
    isTerminateEmployeeModalOpen,
    onCreatedBillModalClose,
    onCreatedBillModalSubmit,
    onSearchInputChange,
    onTablePaginationChange,
    onTerminateEmployeeModalClose,
    onTerminateEmployeeSuccess,
    pageSizeOptions,
    selectedTerminationEmployee,
    table,
    tablePaginationState,
    terminationEmployeesTotalCount,
  } = useTerminationTabViewModel();

  return (
    <>
      <CreatedBillModal
        adminEmail={adminEmail}
        onClose={onCreatedBillModalClose}
        onSubmit={onCreatedBillModalSubmit}
        open={isCreatedBillModalOpen}
      />
      {selectedTerminationEmployee && (
        <TerminateEmployeeModal
          employeeId={selectedTerminationEmployee.id}
          employeeName={selectedTerminationEmployee.name}
          onClose={onTerminateEmployeeModalClose}
          onTerminateEmployeeSuccess={onTerminateEmployeeSuccess}
          open={isTerminateEmployeeModalOpen}
        />
      )}
      <FlexBox alignItems={"center"} gap="xs4">
        <FlexBoxItem>
          <Typography.Headline8 color={theme.colors.neutral[30]}>
            Pessoas cadastradas na empresa
          </Typography.Headline8>
        </FlexBoxItem>
        {!isTerminationEmployeeListLoading && (
          <FlexBoxItem>
            <Tag disabled variant={"gray"}>
              {`${terminationEmployeesTotalCount} itens`}
            </Tag>
          </FlexBoxItem>
        )}
      </FlexBox>
      <Spacer y={"xs4"} />
      <NotificationCard.Root
        type={"info"}
        variant={"outlined"}
        autoClose
        showCloseButton
      >
        <NotificationCard.Icon iconName={"IconAlertCircle"} />
        <NotificationCard.WrapperTexts>
          <NotificationCard.Title>{"Atenção"}</NotificationCard.Title>
          <NotificationCard.Subtitle>
            {
              "A lista mostra todos os colabadores da sua empresa. Não é possível nem necessário gerar fatura para pessoas que não tenham saldo para quitação. Use a ferramenta de busca para encontrar pessoas com saldo a quitar."
            }
          </NotificationCard.Subtitle>
        </NotificationCard.WrapperTexts>
      </NotificationCard.Root>
      <Spacer y={"s"} />
      <Table.Root>
        <Table.Search
          label="Pesquisar por CPF ou nome..."
          onChange={onSearchInputChange}
        />
        <Table.Grid.Root loading={isTerminationEmployeeListLoading}>
          <Table.Grid.Header getHeaderGroups={table.getHeaderGroups} />
          {table.rows.map((row, index) => (
            <Table.Grid.Row key={index + row.id} row={row} />
          ))}
        </Table.Grid.Root>
        <Table.Pagination
          count={terminationEmployeesTotalCount}
          onPaginationChange={onTablePaginationChange}
          pagination={tablePaginationState}
          pageSizeOptions={pageSizeOptions}
        />
      </Table.Root>
    </>
  );
};

export default TerminationsTab;
