import Typography from "../../../../components/Typography";
import styled from "styled-components";

export const CurrencyCellStyled = styled(Typography.Body3)`
  white-space: nowrap;
`;

export const TerminateButtonWrapperStyled = styled.div`
  display: inline-block;
`;
