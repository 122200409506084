import { TagProps } from "@flash-tecnologia/hros-web-ui-v2";
import { LoanRequestStatus } from "../../types/loanRequests";
import { LoanRequestStatusTagProps } from "src/pages/PayrollLoan/components/LoanRequestsTab/LoanRequestDetailsDrawer";

export function getLoanRequestStatusDescription(
  loanRequestStatus: LoanRequestStatus
) {
  return {
    [LoanRequestStatus.ANALYSING]: "Em análise",
    [LoanRequestStatus.APPROVED]: "Crédito liberado",
    [LoanRequestStatus.REJECTED]: "Reprovado",
  }[loanRequestStatus];
}

export function getLoanRequestStatusTagProps(
  loanRequestStatus: LoanRequestStatus
) {
  const loanRequestStatusDescription =
    getLoanRequestStatusDescription(loanRequestStatus);

  const loanRequestStatusTagVariant = {
    [LoanRequestStatus.ANALYSING]: "error",
    [LoanRequestStatus.APPROVED]: "success",
    [LoanRequestStatus.REJECTED]: "gray",
  }[loanRequestStatus] as TagProps["variant"];

  return {
    description: loanRequestStatusDescription,
    variant: loanRequestStatusTagVariant,
  } as LoanRequestStatusTagProps;
}
