import { IconTypes } from "@flash-tecnologia/hros-web-ui-v2";

interface Field {
  icon: IconTypes;
  title: string;
  tag: string;
  bullets: string[];
}

export interface FieldInstructions {
  header: {
    title: string;
    description: string;
  };
  fields: Field[];
}

export const fieldInstructions: FieldInstructions = {
  header: {
    title: "Como preencher o arquivo para envio?",
    description:
      "Confira quais são os dados pedidos no modelo de planilha e como preenchê-los.",
  },
  fields: [
    {
      icon: "IconUser",
      title: "Nome completo",
      tag: "Opcional",
      bullets: [
        "Dado para consulta na tabela, não substitui o nome cadastrado em Equipe.",
      ],
    },
    {
      icon: "IconId",
      title: "CPF",
      tag: "Obrigatório",
      bullets: ["O uso de pontos e hífen é opcional."],
    },
    {
      icon: "IconCalendarEvent",
      title: "Data de admissão",
      tag: "Obrigatório",
      bullets: [
        "O formato deve ser dd/mm/aaaa.",
        "A data só precisa ser informada no primeiro cadastro na plataforma e será pré-preenchida quando já conhecermos o valor.",
        "Não é possível alterar uma data já cadastrada através do módulo de Consignado.",
      ],
    },
    {
      icon: "IconCashBanknote",
      title: "Salário bruto",
      tag: "Obrigatório",
      bullets: [
        "Valor acordado em contrato ou registrado em carteira, antes de qualquer desconto ou acréscimo.",
        "Comissões, premiação e horas extras, por exemplo, não devem ser consideradas.",
      ],
    },
    {
      icon: "IconWallet",
      title: "Salário líquido",
      tag: "Obrigatório",
      bullets: [
        "Valor de salário efetivamente recebido pelo funcionário, descontados todos os encargos, coparticipação de benefícios, parcelas de outros empréstimos ou benefícios pagos em folha, por exemplo.",
      ],
    },
    {
      icon: "IconBuildingSkyscraper",
      title: "Margem consignável",
      tag: "Obrigatório",
      bullets: [
        "Deve ser, no máximo, 35% do salário líquido. Não sendo aceita a inserção de valores maiores.",
        "Representa a soma máxima das parcelas mensais de todos os empréstimos.",
      ],
    },
  ],
};
