import { useSelectedCompany } from "@flash-tecnologia/hros-web-utility";
import { approveLoanRequestUseCase } from "../useCases/approveLoanRequestUseCase";
import { dispatchToast } from "../../../utils/dispatchEvents";
import { LoanRequest } from "../../../types/loanRequests";
import { rejectLoanRequestsUseCase } from "../useCases/rejectLoanRequestsUseCase";
import { LoanRequestActionModalProps } from "../components/LoanRequestsTab/LoanRequestActionModal";
import { useState } from "react";
import { PAYROLL_LOAN_GENERIC_ERROR_MESSAGE } from "server/src/utils/errorHandlers/payrollLoan.errors";

interface ActionsViewModelInput {
  onActionsModalClose: () => void;
  onApproveSucess: () => void;
  onRejectSucess: () => void;
  selectedLoanRequest?: LoanRequest;
}

export function useLoanRequestActionsViewModel({
  onActionsModalClose,
  onApproveSucess,
  onRejectSucess,
  selectedLoanRequest,
}: ActionsViewModelInput) {
  const { selectedCompany } = useSelectedCompany();

  const [
    showLoanRequestApproveConfirmation,
    setShowLoanRequestApproveConfirmation,
  ] = useState<boolean>(false);

  const [
    showLoanRequestRejectConfirmation,
    setShowLoanRequestRejectConfirmation,
  ] = useState<boolean>(false);

  const onApproveClick = () => {
    setShowLoanRequestApproveConfirmation(true);
  };

  const onApproveCancel = () => {
    onActionsModalClose();
    setShowLoanRequestApproveConfirmation(false);
  };

  const onRejectClick = () => {
    setShowLoanRequestRejectConfirmation(true);
  };

  const onRejectCancel = () => {
    onActionsModalClose();
    setShowLoanRequestRejectConfirmation(false);
  };

  const { isLoading: isApproveLoanRequestLoading, approveLoanRequestMutation } =
    approveLoanRequestUseCase({
      companyId: selectedCompany?.id,
      loanId: selectedLoanRequest?.id ?? "",
      onError: () => {
        dispatchToast({
          type: "error",
          content:
            "Tente novamente mais tarde. Se o problema persistir, entre em contato com o suporte.",
        });
      },
      onSuccess: () => {
        dispatchToast({
          type: "success",
          content: "Solicitação de empréstimo aprovada com sucesso",
        });
        setShowLoanRequestApproveConfirmation(false);
        onApproveSucess();
      },
    });

  const { isLoading: isRejectLoanRequestLoading, rejectLoanRequestsMutation } =
    rejectLoanRequestsUseCase({
      companyId: selectedCompany?.id,
      loanIds: selectedLoanRequest?.id ? [selectedLoanRequest.id] : [],
      onError: (error) => {
        dispatchToast({
          type: "error",
          content: error.message ?? PAYROLL_LOAN_GENERIC_ERROR_MESSAGE,
        });
      },
      onSuccess: () => {
        dispatchToast({
          type: "success",
          content: "Solicitação de empréstimo reprovada com sucesso",
        });
        setShowLoanRequestRejectConfirmation(false);
        onRejectSucess();
      },
    });

  const approveLoanRequestModalProps: LoanRequestActionModalProps = {
    loading: isApproveLoanRequestLoading,
    onClose: onApproveCancel,
    onSubmit: approveLoanRequestMutation,
    submitButtonText: "Aprovar",
    title: `Tem certeza que deseja aprovar a solicitação de ${selectedLoanRequest?.name}?`,
    visible: showLoanRequestApproveConfirmation,
  };

  const rejectLoanRequestModalProps: LoanRequestActionModalProps = {
    loading: isRejectLoanRequestLoading,
    onClose: onRejectCancel,
    onSubmit: rejectLoanRequestsMutation,
    submitButtonText: "Reprovar",
    title: `Tem certeza que deseja reprovar a solicitação de ${selectedLoanRequest?.name}?`,
    visible: showLoanRequestRejectConfirmation,
  };

  const getLoanRequestActionModalProps = () => {
    if (showLoanRequestApproveConfirmation) {
      return approveLoanRequestModalProps;
    }

    if (showLoanRequestRejectConfirmation) {
      return rejectLoanRequestModalProps;
    }
  };

  const loanRequestActionModalProps = getLoanRequestActionModalProps();

  return {
    loanRequestActionModalProps,
    onApproveClick,
    onRejectClick,
  };
}
