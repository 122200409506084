import { RouterInput } from "server/src/routers/trpc";
import { trpc } from "../../../api/client";

type UpsertEmployeeInputParams = {
  onSuccess?: () => void;
  onError?: (error: unknown) => void;
};

export type UpsertEmployeeInputDTO = RouterInput["employees"]["upsertEmployee"];

export const upsertEmployeeUseCase = ({
  onSuccess,
  onError,
}: UpsertEmployeeInputParams) => {
  const upsertEmployeeMutation = trpc.employees.upsertEmployee.useMutation({
    onSuccess,
    onError,
  });

  return {
    upsertEmployeeMutation: upsertEmployeeMutation.mutate,
    isLoading: upsertEmployeeMutation.isLoading,
  };
};
