import Typography from "../../../../../../components/Typography";
import styled from "styled-components";

export const TerminateEmployeeModalContentTitleStyled = styled(
  Typography.Headline8
)`
  font-weight: 700;
`;

export const TerminateEmployeeModalFooterWrapperStyled = styled.div`
  width: 100%;
`;
