import styled from "styled-components";

export const BoxContainer = styled.div`
  border: 1px solid #ebe6e9;
  border-radius: 12px;
  padding: 40px;
`;

export const ErrorBoxContainer = styled.div`
  width: 580px;
  border: 1px solid #ebe6e9;
  border-radius: 12px;
  padding: 20px;
  margin-top: 25px;
  margin-bottom: 25px;
`;
