import { Icons, Modal } from "@flash-tecnologia/hros-web-ui-v2";
import FlexBox from "../../../../../components/atoms/FlexBox";
import { LoanRequestActionModalIconWrapperStyled } from "./styled";
import { useTheme } from "styled-components";
import Typography from "../../../../../components/Typography";
import LoanRequestActionModalFooterContent from "./LoanRequestActionModalFooter";
import { useTracking } from "../../../../../utils/useTracking";
import { useEffect } from "react";

export interface LoanRequestActionModalProps {
  loading: boolean;
  onClose: () => void;
  onSubmit: () => void;
  submitButtonText: string;
  title: string;
  visible: boolean;
}

const LoanRequestActionModal = ({
  loading,
  onClose,
  onSubmit,
  submitButtonText,
  title,
  visible,
}: LoanRequestActionModalProps) => {
  const theme = useTheme();
  const tracking = useTracking();

  useEffect(() => {
    tracking.trackPage({
      name: "payroll_loan_requests",
    });
  }, []);

  return (
    <Modal.Root open={visible} onClose={onClose}>
      <>
        <Modal.Content>
          <FlexBox alignItems={"center"} flexDirection={"column"} gap={"xs2"}>
            <FlexBox.Item>
              <LoanRequestActionModalIconWrapperStyled>
                <Icons
                  name={"IconAlertCircle"}
                  size={64}
                  stroke={"1"}
                  fill={"transparent"}
                  color={theme.colors.feedback.error[40]}
                />
              </LoanRequestActionModalIconWrapperStyled>
            </FlexBox.Item>
            <FlexBox.Item>
              <Typography.Body3 center color={theme.colors.feedback.error[40]}>
                Atenção!
              </Typography.Body3>
            </FlexBox.Item>
            <FlexBox.Item>
              <Typography.Headline6 center>{title}</Typography.Headline6>
            </FlexBox.Item>
            <FlexBox.Item>
              <Typography.Body3 center>
                Esta ação <b>não</b> poderá ser <b>desfeita</b>. Deseja
                continuar?
              </Typography.Body3>
            </FlexBox.Item>
          </FlexBox>
        </Modal.Content>
        <Modal.Footer>
          <LoanRequestActionModalFooterContent
            loading={loading}
            onCancel={onClose}
            onSubmit={onSubmit}
            submitButtonText={submitButtonText}
          />
        </Modal.Footer>
      </>
    </Modal.Root>
  );
};

export default LoanRequestActionModal;
