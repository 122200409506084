import { useSelectedCompany } from "@flash-tecnologia/hros-web-utility";
import { dispatchToast } from "../../../utils/dispatchEvents";
import { requestContactUseCase } from "../useCases/requestContactUseCase";
import { useTracking } from "../../../utils/useTracking";
import { useNavigate, useSearchParams } from "react-router-dom";
import EnvService from "../../../api/EnvService";

const schedulerURL = EnvService.LEADS_MEETINGS_SCHEDULER_URL;

export function useRequestContactViewModel() {
  const { selectedCompany } = useSelectedCompany();
  const tracking = useTracking();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const handleRequestContactSuccess = () => {
    dispatchToast({
      type: "success",
      content: "Em breve, entraremos em contato",
    });
  };

  const handleRequestContactError = () => {
    dispatchToast({
      type: "error",
      content:
        "Tente novamente mais tarde. Se o problema persistir, entre em contato com o suporte.",
    });
  };

  const { isLoading, requestContactMutation } = requestContactUseCase({
    onError: handleRequestContactError,
    onSuccess: handleRequestContactSuccess,
  });

  const handleRequestContact = () => {
    const sourceQueryParam = searchParams.get("source");
    tracking.trackEvent({
      name: "payroll_loan_landing_page_request_contact_clicked",
      ...(sourceQueryParam && {
        params: {
          source: sourceQueryParam,
        },
      }),
    });
    if (schedulerURL) {
      const queryString = searchParams.toString();
      navigate(
        `/payroll-loan/landing-page/scheduler${
          queryString ? `?${queryString}` : ""
        }`,
        { replace: true }
      );
    } else {
      requestContactMutation({
        companyId: selectedCompany.id,
      });
    }
  };

  return {
    handleRequestContact,
    isLoading,
  };
}
