import Typography from "../../../../components/Typography";
import { useTheme } from "styled-components";
import FlexBox from "../../../../components/atoms/FlexBox";
import { ContainerWrapper, AccordionItem, AccordionContainer } from "./styled";
import Spacer from "../../../../components/Spacer";
import { PayrollLoanFaq } from "./constants";

const FAQ = () => {
  const theme = useTheme();
  return (
    <ContainerWrapper>
      <FlexBox flexDirection="column" alignItems="center">
        <Typography.Headline5 color={theme.colors.neutral[20]}>
          Quer saber mais sobre o Empréstimo Consignado?
        </Typography.Headline5>
        <Spacer y="xs2" />
        <Typography.Body3 color={theme.colors.neutral[20]}>
          Confira as perguntas mais frequentes
        </Typography.Body3>
        <Spacer y="s" />
        <AccordionContainer>
          {PayrollLoanFaq.map((item, index) => (
            <div key={index}>
              <AccordionItem
                children={item.answer}
                title={item.question}
                variant="default"
              />
              <Spacer y="xs2" />
            </div>
          ))}
        </AccordionContainer>
      </FlexBox>
    </ContainerWrapper>
  );
};

export default FAQ;
