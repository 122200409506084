import styled from "styled-components";
import Typography from "../../../../components/Typography";

export const ContainerWrapper = styled.div`
  padding: 0 ${({ theme }) => theme.spacings.xl};
`;

export const DiscoverOurSolutionDescriptionWrapperStyled = styled.div`
  text-align: center;
`;

export const DiscoverOurSolutionDescriptionStyled = styled(Typography.Body3)`
  display: inline;

  & + & {
    margin-left: ${({ theme }) => theme.spacings.xs4};
  }
`;
