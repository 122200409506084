import FlexBox from "@atoms/FlexBox";
import {
  Checkbox,
  Icons,
  LinkButton,
  Tag,
} from "@flash-tecnologia/hros-web-ui-v2";
import * as SC from "./styled";

interface TableMassActionsProps {
  totalSelected: number;
  totalCount: number;
  onMassDeactivationClick: () => void;
  onToggleAllSelected: () => void;
}

const TableMassActions = ({
  totalSelected,
  totalCount,
  onMassDeactivationClick,
  onToggleAllSelected,
}: TableMassActionsProps) => {
  return (
    <SC.TableMassActionsWrapper>
      <SC.TableMassActionsItem>
        <Checkbox
          onChange={onToggleAllSelected}
          indeterminate={!!totalSelected}
          checked={totalSelected === totalCount}
        />
      </SC.TableMassActionsItem>
      <SC.TableMassActionsItem>
        <FlexBox alignItems="center" gap="xs">
          <Tag as="div" size="medium" variant="primary">
            {`${totalSelected} de ${totalCount} selecionados`}
          </Tag>
          <LinkButton variant="neutral" onClick={onMassDeactivationClick}>
            <Icons size={15} name="IconTrash" fill="transparent" />
            Desabilitar consignado
          </LinkButton>
        </FlexBox>
      </SC.TableMassActionsItem>
    </SC.TableMassActionsWrapper>
  );
};

export default TableMassActions;
