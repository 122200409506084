import FlexBox from "@atoms/FlexBox";
import {
  Button,
  DatePicker,
  LinkButton,
  Modal,
  SelectField,
  Skeleton,
  TextField,
} from "@flash-tecnologia/hros-web-ui-v2";
import { Grid } from "@mui/material";
import { dayjs } from "@utils/dayjs";
import { toCurrency } from "@utils/masks/toCurrency";
import { useTheme } from "styled-components";
import Spacer from "../../../../../../components/Spacer";
import Typography from "../../../../../../components/Typography";
import { useTerminateEmployeeViewModel } from "../../viewModel/useTerminateEmployeeViewModel";
import {
  TerminateEmployeeModalContentTitleStyled,
  TerminateEmployeeModalFooterWrapperStyled,
} from "./styled";

export interface TerminationDiscountAlert {
  message: string;
  isError: boolean;
}

interface TerminateEmployeeModalProps {
  employeeId: string;
  employeeName: string;
  onClose: () => void;
  open: boolean;
  onTerminateEmployeeSuccess?: (adminEmail: string) => void;
}

const TerminateEmployeeModal = ({
  onClose,
  open,
  employeeId,
  employeeName,
  onTerminateEmployeeSuccess,
}: TerminateEmployeeModalProps) => {
  const theme = useTheme();

  const {
    billetDueDate,
    billetDueDateErrorMessage,
    isGetTerminationEmployeeFetching,
    isTerminateEmployeeButtonDisabled,
    isTerminateEmployeeLoading,
    isTerminationDiscountAlertVisible,
    isTerminationReasonCodeErrorVisible,
    onBilletDueDateBlur,
    onBilletDueDateChange,
    onSeverancePayChange,
    onTerminateEmployeeSubmit,
    onTerminationDiscountBlur,
    onTerminationDiscountChange,
    onTerminationReasonCodeBlur,
    onTerminationReasonCodeSelectChange,
    resetTerminateEmployeeForm,
    severancePay,
    terminationDiscount,
    terminationDiscountAlert,
    terminationEmployee,
    terminationReasonCode,
    terminationReasonsOptions,
  } = useTerminateEmployeeViewModel({
    onTerminateEmployeeSuccess,
    employeeId,
  });

  const handleModalClose = () => {
    resetTerminateEmployeeForm();
    onClose();
  };

  return (
    <Modal.Root open={open} onClose={handleModalClose} size={"md"}>
      <>
        <Modal.Header title="Rescindir" description={employeeName} />
        <Modal.Content>
          <TerminateEmployeeModalContentTitleStyled
            color={theme.colors.neutral30}
          >
            Preencha o valor da rescisão, data e motivo da demissão:
          </TerminateEmployeeModalContentTitleStyled>
          <Spacer y={"xs2"} />
          <Grid container columnSpacing={theme.spacings.xs2}>
            <Grid item xs={6}>
              <DatePicker
                error={!!billetDueDateErrorMessage}
                fromDate={dayjs()}
                helperText={billetDueDateErrorMessage}
                label={"Data de vencimento do boleto"}
                onBlur={onBilletDueDateBlur}
                onDateChange={onBilletDueDateChange}
                placeholder={"Exemplo: 01/01/2024"}
                value={billetDueDate}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                label={"Verbas rescisórias"}
                onChange={(e) => onSeverancePayChange(e.target.value)}
                value={severancePay}
              />
            </Grid>
          </Grid>
          <Spacer y={"xs2"} />
          <SelectField
            error={
              isTerminationReasonCodeErrorVisible && !terminationReasonCode
            }
            helperText={
              isTerminationReasonCodeErrorVisible && !terminationReasonCode
                ? "Campo obrigatório"
                : ""
            }
            label={"Motivo da demissão"}
            options={terminationReasonsOptions}
            onBlur={(e) => onTerminationReasonCodeBlur(e.target.value)}
            onSelectChange={(_, { value }) => {
              onTerminationReasonCodeSelectChange(value);
            }}
            value={terminationReasonCode}
          />
          <Spacer y={"s"} />
          <TerminateEmployeeModalContentTitleStyled
            color={theme.colors.neutral30}
          >
            Preencha o valor descontado:
          </TerminateEmployeeModalContentTitleStyled>
          <Spacer y={"xs2"} />
          <FlexBox gap="xs2">
            <Typography.Body3 color={theme.colors.neutral50}>
              &bull;
            </Typography.Body3>
            <Typography.Body3 color={theme.colors.neutral50}>
              Se o valor total de quitação for menor ou igual a 35% do valor
              líquido da rescisão, preencha com o valor total para quitação;
            </Typography.Body3>
          </FlexBox>
          <Spacer y={"xs2"} />
          <FlexBox gap="xs2">
            <Typography.Body3 color={theme.colors.neutral50}>
              &bull;
            </Typography.Body3>
            <Typography.Body3 color={theme.colors.neutral50}>
              Se o valor total de quitação for maior que 35% do valor líquido da
              rescisão, preencha com o valor dos 35% do líquido da rescisão.
            </Typography.Body3>
          </FlexBox>
          <Spacer y={"xs2"} />
          <Grid container columnSpacing={theme.spacings.xs2}>
            <Grid item xs={6}>
              {isGetTerminationEmployeeFetching ? (
                <Skeleton animation={"wave"} width={"100%"} height={"57px"} />
              ) : (
                <TextField
                  fullWidth
                  label={"Saldo restante para quitação"}
                  disabled
                  value={toCurrency(terminationEmployee?.remainingBalance ?? 0)}
                />
              )}
            </Grid>
            <Grid item xs={6}>
              {isGetTerminationEmployeeFetching ? (
                <Skeleton animation={"wave"} width={"100%"} height={"57px"} />
              ) : (
                <TextField
                  error={
                    isTerminationDiscountAlertVisible &&
                    !terminationDiscountAlert?.isError
                  }
                  fullWidth
                  helperText={terminationDiscountAlert?.message}
                  label={"Valor descontado"}
                  onBlur={(e) => onTerminationDiscountBlur(e.target.value)}
                  onChange={(e) => {
                    onTerminationDiscountChange(e.target.value);
                  }}
                  value={terminationDiscount}
                />
              )}
            </Grid>
          </Grid>
        </Modal.Content>
        <Modal.Footer>
          <TerminateEmployeeModalFooterWrapperStyled>
            <FlexBox alignItems="center" justifyContent="space-between">
              <FlexBox.Item>
                <LinkButton variant="neutral" size="small" onClick={onClose}>
                  Cancelar
                </LinkButton>
              </FlexBox.Item>
              <FlexBox.Item>
                <Button
                  disabled={isTerminateEmployeeButtonDisabled}
                  loading={isTerminateEmployeeLoading}
                  onClick={() => onTerminateEmployeeSubmit(employeeId)}
                  size="large"
                  variant="primary"
                >
                  Gerar fatura
                </Button>
              </FlexBox.Item>
            </FlexBox>
          </TerminateEmployeeModalFooterWrapperStyled>
        </Modal.Footer>
      </>
    </Modal.Root>
  );
};

export default TerminateEmployeeModal;
