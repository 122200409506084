import { Dropzone } from "@flash-tecnologia/hros-web-ui-v2";
import {
  FileUploaderHookParams,
  useFileUploader,
} from "./useFileUploader.hook";

interface FileUploaderProps extends FileUploaderHookParams {
  acceptedFileTypes?: string[];
  title?: string;
}

const FileUploader = ({
  acceptedFileTypes,
  onError,
  onFileChange,
  onUploadedFileClear,
  title,
}: FileUploaderProps) => {
  const { onDropzoneChange } = useFileUploader({
    onError,
    onFileChange,
    onUploadedFileClear,
  });
  return (
    <Dropzone
      accept={acceptedFileTypes}
      onChange={onDropzoneChange}
      onRemove={onUploadedFileClear}
      title={title}
    />
  );
};

export default FileUploader;
