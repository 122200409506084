import { Card } from "@atoms/Card";
import Typography from "../../../../../../components/Typography";
import MessageContent, { MessageContentProps } from "../MessageContent";
import { ErrorBoxContainer } from "./styled";
import { ValidateBillFileUseCaseResponse } from "@pages/CreateBillPage/useCases/validateBillFileUseCase";
import Spacer from "../../../../../../components/Spacer";
import { Button, Icons } from "@flash-tecnologia/hros-web-ui-v2";
import { useTheme } from "styled-components";
import React from "react";

type ValidateBillFileErrorStateProps = {
  messageContentProps: MessageContentProps;
  onReuploadBillFileButtonClick?: () => void;
  validateBillFileData: ValidateBillFileUseCaseResponse;
};

const ValidateBillFileErrorContent = (
  props: ValidateBillFileErrorStateProps
) => {
  const {
    messageContentProps,
    onReuploadBillFileButtonClick,
    validateBillFileData,
  } = props;
  const theme = useTheme();
  return (
    <>
      <Card>
        <MessageContent
          body={messageContentProps.body}
          shapeIconProps={messageContentProps.shapeIconProps}
          description={messageContentProps.description}
          title={messageContentProps.title}
        />
        {!!validateBillFileData.errors?.length && (
          <ErrorBoxContainer>
            <Typography.Body3 weight={700}>
              Finalizado com erros
            </Typography.Body3>
            {validateBillFileData.errors.map((error, i) => (
              <React.Fragment key={i}>
                <Spacer y="xs3" />
                <Typography.Body3>
                  Linha {error.line} - {error.message}
                </Typography.Body3>
              </React.Fragment>
            ))}
          </ErrorBoxContainer>
        )}
        <Spacer y="l" />
        <Button
          size={"large"}
          variant={"secondary"}
          onClick={onReuploadBillFileButtonClick}
        >
          Reenviar planilha
          <Icons
            name="IconRefresh"
            color={theme.colors.secondary50}
            fill="transparent"
          />
        </Button>
      </Card>
    </>
  );
};

export default ValidateBillFileErrorContent;
