import { ShapeIcon, ShapeIconProps } from "@flash-tecnologia/hros-web-ui-v2";
import { useTheme } from "styled-components";
import Spacer from "../../../../components/Spacer";
import Typography from "../../../../components/Typography";

export interface StatusHeaderProps {
  description: string;
  shapeIconProps: ShapeIconProps;
  title: string;
}

const StatusHeader = ({
  description,
  shapeIconProps,
  title,
}: StatusHeaderProps) => {
  const theme = useTheme();
  return (
    <>
      <ShapeIcon
        name={shapeIconProps.name}
        size={shapeIconProps.size}
        variant={shapeIconProps.variant}
        color={shapeIconProps.color}
      />
      <Spacer y="xs4" />
      <Typography.Headline8>{title}</Typography.Headline8>
      <Spacer y="xs5" />
      <Typography.Body4 color={theme.colors.neutral[50]}>
        {description}
      </Typography.Body4>
      <Spacer y="s" />
    </>
  );
};

export default StatusHeader;
