import { IconTypes, ShapeIcon } from "@flash-tecnologia/hros-web-ui-v2";
import Typography from "../../../../../components/Typography";
import { useTheme } from "styled-components";
import Spacer from "../../../../../components/Spacer";
import { Grid } from "@mui/material";

export interface AdvantageProps {
  description: string;
  iconName: IconTypes;
}

const Advantage = ({ description, iconName }: AdvantageProps) => {
  const theme = useTheme();
  return (
    <>
      <Grid item xs="auto">
        <ShapeIcon
          name={iconName}
          size={64}
          variant={"default"}
          color={theme.colors.primary}
        />
        <Spacer y={"xs3"} />
      </Grid>
      <Grid item xs={12}>
        <Typography.Body2 color={theme.colors.neutral[30]} center>
          {description}
        </Typography.Body2>
      </Grid>
    </>
  );
};

export default Advantage;
