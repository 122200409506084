import {
  getFromLS,
  segmentEventTracking,
  segmentPageTracking,
  useSelectedCompany,
} from "@flash-tecnologia/hros-web-utility";
import { useCallback } from "react";

type EventProps = {
  name: string;
  params?: Record<string, string>;
};

const MODULE_NAME = "payroll_loan";
const BUSINESS_UNIT = "benefits";

export function useTracking() {
  const accessToken = getFromLS("hrosAccessToken");
  const { selectedCompany } = useSelectedCompany();

  const buildTrackProps = useCallback(
    (event: EventProps) => {
      return {
        businessUnit: BUSINESS_UNIT,
        module: MODULE_NAME,
        name: event.name,
        params: {
          employee_id: accessToken?.employeeId || "",
          company_id: selectedCompany?.id || "",
          ...event?.params,
        },
      };
    },
    [accessToken?.employeeId, selectedCompany?.id]
  );

  const trackEvent = useCallback(
    (event: EventProps) => {
      const trackEventProps = buildTrackProps(event);

      return segmentEventTracking(trackEventProps);
    },
    [buildTrackProps]
  );

  const trackPage = useCallback(
    (event: EventProps) => {
      const trackPageProps = buildTrackProps(event);

      return segmentPageTracking(trackPageProps);
    },
    [buildTrackProps]
  );

  return {
    trackEvent,
    trackPage,
  };
}
