import { Card } from "@atoms/Card";
import { Grid, LinearProgress } from "@mui/material";
import Spacer from "../../../../../../components/Spacer";
import MessageContent, { MessageContentProps } from "../MessageContent";

type ValidateBillFileLoadingContentProps = {
  messageContentProps: MessageContentProps;
};

const ValidateBillFileLoadingContent = (
  props: ValidateBillFileLoadingContentProps
) => {
  const { messageContentProps } = props;
  return (
    <>
      <Card>
        <MessageContent
          body={messageContentProps.body}
          shapeIconProps={messageContentProps.shapeIconProps}
          description={messageContentProps.description}
          title={messageContentProps.title}
        />
        <Grid item xs={12} md={6}>
          <LinearProgress variant="indeterminate" />
          <Spacer y="l" />
        </Grid>
      </Card>
    </>
  );
};

export default ValidateBillFileLoadingContent;
