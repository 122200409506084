import styled from "styled-components";

export const TableMassActionsWrapper = styled.tr`
  display: table-caption;
  background-color: ${({ theme }) => theme.colors.neutral[95]};
`;

export const TableMassActionsItem = styled.th`
  padding: 24px;
`;
