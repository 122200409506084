import { RouterOutput } from "server/src/routers/trpc";
import { trpc } from "../../../api/client";

export type FindLoanRequestsOutput = RouterOutput["loans"]["findLoanRequests"];

export const findLoanRequestsUseCase = (input: {
  companyId: string;
  onSuccess?: (response) => void;
  pageSize: number;
  startingAfterLoanId?: string;
  status?: string;
}) => {
  const { companyId, onSuccess, pageSize, startingAfterLoanId, status } = input;
  const { data, isFetching, isLoading, refetch } =
    trpc.loans.findLoanRequests.useQuery(
      {
        companyId,
        pageSize,
        startingAfterLoanId,
        status,
      },
      { onSuccess, keepPreviousData: false, refetchOnWindowFocus: false }
    );

  return {
    data,
    isFetching,
    isLoading,
    refetch,
  };
};
