import { PaginationState } from "@flash-tecnologia/hros-web-ui-v2/dist/components/Table/components/Pagination";
import { trpc } from "../../../api/client";
import { RouterOutput } from "server/src/routers/trpc";

export type FindTerminationEmployeesUseCaseOutput =
  RouterOutput["installments"]["findInstallments"];

interface FindIntallmentsUseCaseInput {
  companyId: string;
  onSuccess?: (response) => void;
  pagination: PaginationState;
  startingAfterInstallmentId?: string;
  startDate: string;
  endDate: string;
}

export const findInstallmentsUseCase = ({
  companyId,
  onSuccess,
  pagination,
  startingAfterInstallmentId,
  startDate,
  endDate,
}: FindIntallmentsUseCaseInput) => {
  const { data, isLoading } = trpc.installments.findInstallments.useQuery(
    {
      companyId,
      pageSize: pagination.pageSize,
      startingAfterInstallmentId,
      startDate,
      endDate,
    },
    {
      cacheTime: 0,
      keepPreviousData: false,
      onSuccess,
      refetchOnWindowFocus: false,
    }
  );

  return {
    data,
    isLoading,
  };
};
