import {
  Button,
  IconButton,
  Icons,
  Tag,
  Tooltip,
  dayjs,
  tableControllers,
} from "@flash-tecnologia/hros-web-ui-v2";
import { PaginationState } from "@flash-tecnologia/hros-web-ui-v2/dist/components/Table/components/Pagination";
import { useSelectedCompany } from "@flash-tecnologia/hros-web-utility";
import { cpfMask } from "@utils/masks/formatCPF";
import { useState } from "react";
import { useTheme } from "styled-components";
import Typography from "../../../components/Typography";
import FlexBox from "../../../components/atoms/FlexBox";
import UserTableCell from "../../../components/molecules/UserTableCell";
import { LoanRequest, LoanRequestStatus } from "../../../types/loanRequests";
import {
  getLoanRequestStatusDescription,
  getLoanRequestStatusTagProps,
} from "../../../utils/loanRequests/getLoanStatusTagProps";
import { toCurrency } from "../../../utils/masks/toCurrency";
import { LoanRequestDetailsDrawerProps } from "../components/LoanRequestsTab/LoanRequestDetailsDrawer";
import { CurrencyCellStyled } from "../components/LoanRequestsTab/styled";
import {
  FindLoanRequestsOutput,
  findLoanRequestsUseCase,
} from "../useCases/findLoanRequestsUseCase";
import { useLoanRequestActionsViewModel } from "./useLoanRequestActionsViewModel";
import { useLoanRequestDetailsViewModel } from "./useLoanRequestDetailsViewModel";

interface PaginationQueryMap {
  [page: number]: {
    startingAfterLoanId?: string;
    loanRequestsCount?: number;
    hasMore?: boolean;
  };
}

const PAGE_SIZE_OPTIONS = [
  {
    label: "2 itens",
    value: 2,
  },
  {
    label: "5 itens",
    value: 5,
  },
  {
    label: "10 itens",
    value: 10,
  },
  {
    label: "25 itens",
    value: 25,
  },
];

const STATUS_COLUMN_ACCESSOR_KEY = "status";
const STATUS_COLUMN_LABEL = "Status";

const INITIAL_PAGINATION_STATE = {
  pageNumber: 1,
  pageSize: 10,
};

export function useLoanRequestsTabViewModel() {
  const [paginationState, setPaginationState] = useState<PaginationState>(
    INITIAL_PAGINATION_STATE
  );
  const [statusFilter, setStatusFilter] = useState<string>(
    LoanRequestStatus.ANALYSING
  );
  const [selectedLoanRequest, setSelectedLoanRequest] = useState<LoanRequest>();
  const [showLoanRequestDetails, setShowLoanRequestDetails] =
    useState<boolean>(false);
  const [paginationQueryMap, setPaginationQueryMap] =
    useState<PaginationQueryMap>({
      1: {},
    });
  const { selectedCompany } = useSelectedCompany();

  const theme = useTheme();

  const handleFindLoanRequestsSuccess = (response) =>
    setPaginationQueryMap((prevState) => {
      return {
        ...prevState,
        [paginationState.pageNumber]: {
          startingAfterLoanId:
            prevState[paginationState.pageNumber].startingAfterLoanId,
          loanRequestsCount: response.data.length,
          hasMore: response.hasMore,
        },
      };
    });

  const {
    data: findLoanRequestsOutput,
    isLoading,
    isFetching,
    refetch: refetchLoanRequests,
  } = findLoanRequestsUseCase({
    companyId: selectedCompany?.id,
    onSuccess: handleFindLoanRequestsSuccess,
    pageSize: paginationState.pageSize,
    startingAfterLoanId:
      paginationQueryMap[paginationState.pageNumber]?.startingAfterLoanId,
    status: statusFilter,
  });

  const loanRequests: LoanRequest[] = findLoanRequestsOutput?.data ?? [];

  const onActionsModalClose = () => {
    setSelectedLoanRequest(undefined);
  };

  const { loanRequestActionModalProps, onApproveClick, onRejectClick } =
    useLoanRequestActionsViewModel({
      onActionsModalClose,
      onApproveSucess: refetchLoanRequests,
      onRejectSucess: refetchLoanRequests,
      selectedLoanRequest,
    });

  const {
    employeeName,
    isLoading: isLoanRequestDetailsLoading,
    loanRequestValue,
    statusTagProps,
    detailsSections,
  } = useLoanRequestDetailsViewModel(selectedLoanRequest);

  const handleApproveClick = (loanRequest?: LoanRequest) => {
    if (loanRequest) {
      setSelectedLoanRequest(loanRequest);
      onApproveClick();
    }
  };

  const handleRejectClick = (loanRequest?: LoanRequest) => {
    if (loanRequest) {
      setSelectedLoanRequest(loanRequest);
      onRejectClick();
    }
  };

  const handleDetailsClick = (loanRequest?: LoanRequest) => {
    if (loanRequest) {
      setSelectedLoanRequest(loanRequest);
      setShowLoanRequestDetails(true);
    }
  };

  const onDetailsClose = () => {
    setShowLoanRequestDetails(false);
    setSelectedLoanRequest(undefined);
  };

  const handleFilter = (filters) => {
    if (!filters.length) {
      setStatusFilter(LoanRequestStatus.ANALYSING);
    } else {
      const newStatusFilter = filters.find(
        ({ name }) => name === STATUS_COLUMN_ACCESSOR_KEY
      );
      setStatusFilter(
        newStatusFilter?.values?.[0] || LoanRequestStatus.ANALYSING
      );
    }
    setPaginationState(INITIAL_PAGINATION_STATE);
    setPaginationQueryMap({
      1: {},
    });
  };

  const getTableFilters = () => {
    const label = STATUS_COLUMN_LABEL;
    const name = STATUS_COLUMN_ACCESSOR_KEY;
    const options = Object.keys(LoanRequestStatus).map((key: string) => ({
      label: getLoanRequestStatusDescription(LoanRequestStatus[key]),
      value: LoanRequestStatus[key],
    }));

    return [
      {
        label,
        name,
        options,
        initialSelectedOption: LoanRequestStatus.ANALYSING,
      },
    ];
  };

  const handlePaginationChange = ({ pageSize, pageNumber }) => {
    const currentPaginationQueryMap = paginationQueryMap[pageNumber];

    const pageSizeChanged = pageSize !== paginationState.pageSize;

    if (pageSizeChanged) {
      setPaginationQueryMap({
        1: {},
      });
    } else if (!currentPaginationQueryMap) {
      setPaginationQueryMap((prevState) => {
        return {
          ...prevState,
          [pageNumber]: {
            startingAfterLoanId: loanRequests[loanRequests.length - 1].id,
          },
        };
      });
    }

    setPaginationState({ ...paginationState, pageSize, pageNumber });
  };

  const tableFilters = getTableFilters();

  const table = tableControllers.useTableColumns<
    FindLoanRequestsOutput["data"][number]
  >({
    data: findLoanRequestsOutput?.data ?? [],
    total: findLoanRequestsOutput?.total ?? 0,
    columns: [
      {
        header: "Nome",
        accessorKey: "name",
        cell: ({ cell }) => {
          const { name, documentNumber } = cell.row.original;
          const documentNumberFormatted = cpfMask(documentNumber);
          return (
            <UserTableCell name={name} document={documentNumberFormatted} />
          );
        },
      },
      {
        header: "Status",
        accessorKey: "status",
        cell: ({ cell }) => {
          const status = cell?.row?.original?.status;
          const tagProps = getLoanRequestStatusTagProps(
            LoanRequestStatus[status as keyof typeof LoanRequestStatus]
          );
          return (
            <Tag disabled variant={tagProps.variant} hasLeftDotIcon>
              {tagProps.description}
            </Tag>
          );
        },
      },
      {
        header: "Data",
        accessorKey: "createdAt",
        cell: ({ cell }) => {
          const date = cell?.row?.original?.createdAt;
          const formattedDate = date && dayjs(date).format("DD/MM/YYYY");

          return (
            <FlexBox alignItems={"center"} gap={"xs2"}>
              <Icons
                name="IconCalendar"
                fill={"transparent"}
                size={24}
                color={theme.colors.neutral[30]}
              />
              <Typography.Body3 color={theme.colors.neutral[40]} weight={600}>
                {formattedDate}
              </Typography.Body3>
            </FlexBox>
          );
        },
      },
      {
        header: "Valor",
        accessorKey: "value",
        cell: ({ cell }) => {
          const amount = cell?.row?.original?.value;
          const formattedAmount = toCurrency(amount);

          return (
            <CurrencyCellStyled color={theme.colors.neutral[40]} weight={600}>
              {formattedAmount}
            </CurrencyCellStyled>
          );
        },
      },
      {
        header: "Ações",
        id: "actions",
        sticky: "right",
        cell: ({ cell }) => {
          const status = cell?.row?.original?.status;
          const loanRequestId = cell?.row?.original?.id;
          const correspondentLoanRequest = loanRequests.find(
            ({ id }) => id === loanRequestId
          );
          const disabledActions = status !== LoanRequestStatus.ANALYSING;

          return (
            <FlexBox alignItems={"center"} gap={"xs2"}>
              <FlexBox.Item>
                <Button
                  disabled={disabledActions}
                  onClick={() => handleApproveClick(correspondentLoanRequest)}
                  size={"small"}
                  variant={"secondary"}
                  variantType={"success"}
                >
                  Aprovar
                </Button>
              </FlexBox.Item>
              <FlexBox.Item>
                <Button
                  disabled={disabledActions}
                  onClick={() => handleRejectClick(correspondentLoanRequest)}
                  size={"small"}
                  variant={"secondary"}
                  variantType={"error"}
                >
                  Reprovar
                </Button>
              </FlexBox.Item>
              <Tooltip title={"Detalhes"}>
                <IconButton
                  icon="IconFileDescription"
                  onClick={() => handleDetailsClick(correspondentLoanRequest)}
                  size="large"
                  variant="line"
                />
              </Tooltip>
            </FlexBox>
          );
        },
      },
    ],
    pagination: paginationState,
    onPaginationChange: handlePaginationChange,
  });

  const loanRequestDetailsDrawerProps: LoanRequestDetailsDrawerProps = {
    detailsSections,
    employeeName,
    isLoading: isLoanRequestDetailsLoading,
    loanRequestValue,
    onClose: onDetailsClose,
    open: !!selectedLoanRequest?.id && showLoanRequestDetails,
    statusTagProps,
  };

  const totalCount = Object.values(paginationQueryMap).reduce(
    (acc, currentPage, index) => {
      acc += currentPage.loanRequestsCount ?? 0;
      const isLastPage = index === Object.values(paginationQueryMap).length - 1;
      const shouldDisplayNextPage = isLastPage && currentPage.hasMore;
      if (shouldDisplayNextPage) {
        acc++;
      }
      return acc;
    },
    0
  );

  const emptyState = {
    message:
      {
        [LoanRequestStatus.ANALYSING]: "Nenhuma solicitação em análise",
        [LoanRequestStatus.APPROVED]:
          "Nenhuma solicitação com crédito aprovado",
        [LoanRequestStatus.REJECTED]: "Nenhuma solicitação reprovada",
      }[statusFilter] ?? "Nenhuma solicitação",
  };

  return {
    emptyState,
    loading: isLoading || isFetching,
    loanRequestActionModalProps,
    loanRequestDetailsDrawerProps,
    onFilter: handleFilter,
    onPaginationChange: handlePaginationChange,
    paginationState,
    pageSizeOptions: PAGE_SIZE_OPTIONS,
    table,
    tableFilters,
    totalCount,
  };
}
