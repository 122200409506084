import styled from "styled-components";

interface AdvantagesBackgroundStyledProps {
  color?: string;
}

export const AdvantagesBackgroundStyled = styled.div<AdvantagesBackgroundStyledProps>`
  background-color: ${({ color }) => color ?? "transparent"};
  padding: ${({ theme }) => `${theme.spacings.l} ${theme.spacings.xl}`};
`;
