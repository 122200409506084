import { useSelectedCompany } from "@flash-tecnologia/hros-web-utility";
import { getTerminationEmployeeUseCase } from "@pages/PayrollLoan/components/TerminationTab/useCases/getTerminationEmployeeUseCase";
import { TRPCError } from "@trpc/server";
import { dayjs } from "@utils/dayjs";
import { dispatchToast } from "@utils/dispatchEvents";
import { isNill } from "@utils/isNill";
import { toCurrency } from "@utils/masks/toCurrency";
import { Dayjs } from "dayjs";
import { useCallback, useEffect, useState } from "react";
import { PAYROLL_LOAN_GENERIC_ERROR_MESSAGE } from "server/src/utils/errorHandlers/payrollLoan.errors";
import { TerminationDiscountAlert } from "../components/TerminateEmployeeModal";
import {
  MAX_TERMINATION_DEDUCTION_FACTOR,
  TERMINATION_REASONS,
} from "../components/TerminateEmployeeModal/constants";
import { terminateEmployeeUseCase } from "../useCases/terminateEmployeeUseCase";
import { TerminationEmployee } from "./useTerminationEmployeesListViewModel";
import { TRPCClientError } from "@trpc/client";

export function useTerminateEmployeeViewModel(input: {
  onTerminateEmployeeSuccess?: (adminEmail: string) => void;
  employeeId: string;
}) {
  const { employeeId, onTerminateEmployeeSuccess } = input;

  const [
    isTerminateEmployeeButtonDisabled,
    setIsTerminateEmployeeButtonDisabled,
  ] = useState<boolean>(true);

  const [maxTerminationDiscount, setMaxTerminationDiscount] =
    useState<number>(0);

  const [severancePayCurrencyString, setSeverancePayCurrencyString] =
    useState<string>(toCurrency(0));

  const [billetDueDate, setBilletDueDate] = useState<Dayjs | string>();

  const [isBilletDueDateValid, setIsBilletDueDateValid] =
    useState<boolean>(false);

  const [isBilletDueDateErrorVisible, setIsBilletDueDateErrorVisible] =
    useState<boolean>(false);

  const getBilletDueDateErrorMessage = useCallback(() => {
    if (!isBilletDueDateErrorVisible) {
      return "";
    }
    if (!billetDueDate) {
      return "Campo obrigatório";
    }
    if (!isBilletDueDateValid) {
      return "Campo inválido";
    }
  }, [billetDueDate, isBilletDueDateErrorVisible, isBilletDueDateValid]);

  const [
    terminationDiscountCurrencyString,
    setTerminationDiscountCurrencyString,
  ] = useState<string>(toCurrency(0));

  const [terminationDiscountAlert, setTerminationDiscountAlert] =
    useState<TerminationDiscountAlert>();

  const [terminationReasonCode, setTerminationReasonCode] = useState<string>();

  const [
    isTerminationReasonCodeErrorVisible,
    setIsTerminationReasonCodeErrorVisible,
  ] = useState<boolean>(false);

  const [
    isTerminationDiscountAlertVisible,
    setIsTerminationDiscountAlertVisible,
  ] = useState<boolean>(false);

  const { selectedCompany } = useSelectedCompany();

  const isGetTerminationUseCaseEnabled = !!employeeId && isBilletDueDateValid;

  const {
    data: getTerminationEmployee,
    isFetching: isGetTerminationEmployeeFetching,
  } = getTerminationEmployeeUseCase({
    billetDueDate: isGetTerminationUseCaseEnabled
      ? dayjs(billetDueDate).toString()
      : "",
    companyId: selectedCompany.id,
    employeeId,
    enabled: isGetTerminationUseCaseEnabled,
    onError: (error) => {
      const message =
        error instanceof TRPCError
          ? error.message
          : PAYROLL_LOAN_GENERIC_ERROR_MESSAGE;

      dispatchToast({
        type: "error",
        content: message,
      });
    },
    onSuccess: (result) => {
      handleTerminationDiscountAutomaticUpdate(
        severancePayCurrencyString,
        result.data.remainingBalance
      );
    },
  });

  const terminationEmployee =
    getTerminationEmployee?.data as TerminationEmployee;

  const handleBilletDueDateChange = (value) => {
    setBilletDueDate(value);
  };

  const handleTerminationDiscountAutomaticUpdate = (
    severancePay: string,
    remainingBalance: number
  ) => {
    const newSeverancePay = Number(severancePay.replace(/\D/g, "") || "0");
    const maxSeverancePayDiscount =
      Math.round(newSeverancePay * MAX_TERMINATION_DEDUCTION_FACTOR * 100) /
      100;
    const newTerminationDiscount = Math.min(
      maxSeverancePayDiscount,
      remainingBalance
    );
    setMaxTerminationDiscount(Math.round(newTerminationDiscount));
    setTerminationDiscountCurrencyString(toCurrency(newTerminationDiscount));
  };

  const resetTerminateEmployeeForm = () => {
    setIsBilletDueDateErrorVisible(false);
    setIsTerminateEmployeeButtonDisabled(true);
    setIsTerminationReasonCodeErrorVisible(false);
    setIsTerminationDiscountAlertVisible(false);
    setMaxTerminationDiscount(0);
    setBilletDueDate(undefined);
    setTerminationDiscountAlert(undefined);
    setSeverancePayCurrencyString(toCurrency(0));
    setTerminationReasonCode(undefined);
    setTerminationDiscountCurrencyString(toCurrency(0));
  };

  const { isLoading: isTerminateEmployeeLoading, terminateEmployeeMutation } =
    terminateEmployeeUseCase({
      onError: (error) => {
        const message =
          error instanceof TRPCClientError
            ? error.message
            : PAYROLL_LOAN_GENERIC_ERROR_MESSAGE;

        dispatchToast({
          type: "error",
          content: message,
        });
      },
      onSuccess: (result) => {
        const { adminEmail } = result;
        onTerminateEmployeeSuccess?.(adminEmail);
      },
    });

  const handleSeverancePayChange = (value: string) => {
    const newSeverancePay = Number(value.replace(/\D/g, "") || "0");
    handleTerminationDiscountAutomaticUpdate(
      value,
      terminationEmployee?.remainingBalance ?? 0
    );
    setSeverancePayCurrencyString(toCurrency(newSeverancePay));
  };

  const handleTerminateEmployeeSubmit = (employeeId: string) => {
    if (!terminationReasonCode) {
      return;
    }

    terminateEmployeeMutation({
      billetDueDate: dayjs(billetDueDate).toISOString(),
      employees: [
        {
          employeeId,
          discountValue: Number(
            terminationDiscountCurrencyString.replace(/\D/g, "") || "0"
          ),
          severancePayment: Number(
            severancePayCurrencyString.replace(/\D/g, "") || "0"
          ),
          terminationReason: terminationReasonCode,
        },
      ],
      companyId: selectedCompany.id,
    });
  };

  const handleTerminationDiscountChange = (value: string) => {
    const newTerminationDiscount = Number(value.replace(/\D/g, "") || "0");
    setTerminationDiscountCurrencyString(toCurrency(newTerminationDiscount));
    setIsTerminationDiscountAlertVisible(!value);
  };

  const handleBilletDueDateBlur = () => {
    setIsBilletDueDateErrorVisible(!isBilletDueDateValid);
  };

  const handleTerminationReasonCodeBlur = (value: string) => {
    setIsTerminationReasonCodeErrorVisible(!value);
  };

  const handleTerminationDiscountBlur = (value: string) => {
    setIsTerminationDiscountAlertVisible(!value);
  };

  const handleTerminationReasonCodeSelectChange = (value: string) => {
    setTerminationReasonCode(value);
    setIsTerminationReasonCodeErrorVisible(!value);
  };

  const terminationReasonsOptions = TERMINATION_REASONS.map(
    ({ code, description }) => {
      return {
        label: `${code} - ${description}`,
        value: code,
      };
    }
  );

  useEffect(() => {
    const terminationDiscount = Number(
      terminationDiscountCurrencyString.replace(/\D/g, "") || "0"
    );

    if (
      !terminationDiscount ||
      terminationDiscount === maxTerminationDiscount
    ) {
      setTerminationDiscountAlert(undefined);
      return;
    }

    const isTerminationDiscountValid =
      terminationDiscount < maxTerminationDiscount;

    setTerminationDiscountAlert({
      message: `O valor descontado é ${
        isTerminationDiscountValid ? "menor" : "maior"
      } do que o permitido`,
      isError: !isTerminationDiscountValid,
    });
  }, [maxTerminationDiscount, terminationDiscountCurrencyString]);

  useEffect(() => {
    const isTerminationFormValid =
      isBilletDueDateValid &&
      !isNill(severancePayCurrencyString) &&
      !terminationDiscountAlert?.isError &&
      !isNill(terminationDiscountCurrencyString) &&
      !isNill(terminationReasonCode);
    setIsTerminateEmployeeButtonDisabled(!isTerminationFormValid);
  }, [
    isBilletDueDateValid,
    severancePayCurrencyString,
    terminationDiscountAlert,
    terminationDiscountCurrencyString,
    terminationReasonCode,
  ]);

  useEffect(() => {
    const isNewDateValid =
      !!billetDueDate && dayjs(billetDueDate, "DD/MM/YYYY").isValid();

    if (!isNewDateValid) {
      setTerminationDiscountCurrencyString(toCurrency(0));
    }

    setIsBilletDueDateValid(isNewDateValid);
  }, [billetDueDate]);

  return {
    billetDueDate,
    billetDueDateErrorMessage: getBilletDueDateErrorMessage(),
    isBilletDueDateValid,
    isBilletDueDateErrorVisible,
    isGetTerminationEmployeeFetching,
    isTerminateEmployeeButtonDisabled,
    isTerminateEmployeeLoading,
    isTerminationDiscountAlertVisible,
    isTerminationReasonCodeErrorVisible,
    onBilletDueDateBlur: handleBilletDueDateBlur,
    onBilletDueDateChange: handleBilletDueDateChange,
    onSeverancePayChange: handleSeverancePayChange,
    onTerminateEmployeeSubmit: handleTerminateEmployeeSubmit,
    onTerminationDiscountBlur: handleTerminationDiscountBlur,
    onTerminationDiscountChange: handleTerminationDiscountChange,
    onTerminationReasonCodeBlur: handleTerminationReasonCodeBlur,
    onTerminationReasonCodeSelectChange:
      handleTerminationReasonCodeSelectChange,
    resetTerminateEmployeeForm: resetTerminateEmployeeForm,
    severancePay: severancePayCurrencyString,
    terminationDiscount: terminationDiscountCurrencyString,
    terminationDiscountAlert,
    terminationEmployee,
    terminationReasonCode,
    terminationReasonsOptions,
  };
}
