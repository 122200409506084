import AppRouter from "./routes";
import { ThemeProvider } from "@flash-tecnologia/hros-web-ui-v2";
import { TrpcProvider } from "./api/client";

import "../src/i18n";

export default function Root() {
  return (
    <TrpcProvider>
      <ThemeProvider>
        <AppRouter />
      </ThemeProvider>
    </TrpcProvider>
  );
}
