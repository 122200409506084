import {
  Breadcrumbs,
  Icons,
  LinkButton,
  Loader,
  PageContainer,
  PageHeader,
} from "@flash-tecnologia/hros-web-ui-v2";
import { Grid } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useTheme } from "styled-components";
import Spacer from "../../components/Spacer";
import Typography from "../../components/Typography";
import FlexBox from "../../components/atoms/FlexBox";
import StatusDetails from "./components/StatusDetails";
import StatusHeader from "./components/StatusHeader";
import { BoxContainer, ErrorBoxContainer } from "./styled";
import { useUploadSheetInfoViewModel } from "./viewModel/useUploadSheetInfoViewModel";
import { LoaderContainer } from "../../components/atoms/Loader";

export default function UploadSheetInfoPage() {
  const theme = useTheme();
  const navigate = useNavigate();
  const {
    status,
    errors,
    isLoading,
    PrimaryButton,
    SecondaryButton,
    statusHeaderProps,
    statusDetailsProps,
  } = useUploadSheetInfoViewModel();

  if (isLoading) {
    return (
      <LoaderContainer>
        <Loader size="large" variant="primary" />
      </LoaderContainer>
    );
  }

  return (
    <>
      <PageHeader style={{ flexDirection: "column" }}>
        <Breadcrumbs
          breadcrumbs={[
            <LinkButton
              onClick={() => {
                navigate("/payroll-loan", { replace: true });
              }}
              variant="neutral"
              size="small"
            >
              Consignado
            </LinkButton>,
            <LinkButton variant="neutral" size="small" disabled>
              Atualizar base
            </LinkButton>,
          ]}
          separator={<Icons name="IconChevronRight" fill="#ffffff" />}
        />
        <Spacer y="xs" />
      </PageHeader>
      <PageContainer>
        <Typography.Headline6>Atualizar base</Typography.Headline6>
        <Spacer y="xs" />
        <Grid container spacing={theme.spacings.xs}>
          <Grid item xs={12} md={3}>
            <Typography.Headline7 color={theme.colors.secondary[50]}>
              Status da importação
            </Typography.Headline7>
            <Spacer y="xs3" />
            <Typography.Body3 color={theme.colors.neutral[50]}>
              Acompanhe aqui o status da sua importação. Esse processo pode
              demorar alguns minutos, mas você pode fechar essa página e
              continuar usando a plataforma normalmente!
            </Typography.Body3>
          </Grid>
          <Grid item xs={12} md>
            <BoxContainer>
              {statusHeaderProps && (
                <StatusHeader
                  description={statusHeaderProps.description}
                  shapeIconProps={statusHeaderProps.shapeIconProps}
                  title={statusHeaderProps.title}
                />
              )}
              {statusDetailsProps && (
                <StatusDetails
                  description={statusDetailsProps.description}
                  progress={statusDetailsProps.progress}
                  title={statusDetailsProps.title}
                />
              )}
              {!!errors?.length && (
                <ErrorBoxContainer>
                  <Typography.Body3 style={{ fontWeight: 700 }}>
                    {status === "FAILED"
                      ? "Erro no processamento"
                      : "Finalizado com erros"}
                  </Typography.Body3>
                  {errors.map((error, i) => (
                    <Typography.Body3 key={i}>
                      Linha {error.line} - {error.message}
                    </Typography.Body3>
                  ))}
                </ErrorBoxContainer>
              )}
              <FlexBox alignItems={"center"} gap={"xs"}>
                {SecondaryButton && (
                  <FlexBox.Item>
                    <SecondaryButton />
                  </FlexBox.Item>
                )}
                {PrimaryButton && (
                  <FlexBox.Item>
                    <PrimaryButton />
                  </FlexBox.Item>
                )}
              </FlexBox>
            </BoxContainer>
          </Grid>
        </Grid>
        <Spacer y="xs" />
      </PageContainer>
    </>
  );
}
