import styled from "styled-components";
import { Accordion } from "@flash-tecnologia/hros-web-ui-v2";

export const ContainerWrapper = styled.div`
  padding: ${(p) => p.theme.spacings.l};
  padding-bottom: 0;
`;

export const AccordionContainer = styled.div`
  padding: 0 ${(p) => p.theme.spacings.xl};
`;

export const AccordionItem = styled(Accordion)`
  border-radius: ${(p) => p.theme.borders.radius.s} !important;
  border-width: ${(p) => p.theme.borders.width.xs2};
  padding: ${(p) => p.theme.spacings.xs};
  width: 100%;
  background-color: ${(p) =>
    p.expanded ? p.theme.colors.neutral[90] : p.theme.colors.secondary[99]};
  & .MuiButtonBase-root {
    padding: 0;
  }
`;
