import { RouterOutput } from "server/src/routers/trpc";
import { trpc } from "../../../api/client";

export type GetEmployeesOutput = RouterOutput["employees"]["getEmployees"];

export const getEmployeesUseCase = (
  companyId: string,
  searchInput: string,
  pagination?: { currentPage: number; pageSize: number },
  statuses?: string[]
) => {
  const { data, isFetching, isLoading, refetch } =
    trpc.employees.getEmployees.useQuery(
      { companyId, pagination, searchInput, statuses },
      { refetchOnWindowFocus: false }
    );

  return {
    data,
    isFetching,
    isLoading,
    refetch,
  };
};
