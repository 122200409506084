import { Icons, Modal } from "@flash-tecnologia/hros-web-ui-v2";
import { useTheme } from "styled-components";
import Typography from "../../../../components/Typography";
import FlexBox from "../../../../components/atoms/FlexBox";
import ConfirmDeactivationModalFooterContent from "./ConfirmDeactivationModalFooter";
import { ConfirmDeactivationModalIconWrapperStyled } from "./styled";

const ConfirmDeactivationModal = ({
  loading,
  onClose,
  onSubmit,
  open,
  title,
}) => {
  const theme = useTheme();
  return (
    <Modal.Root open={open} onClose={onClose}>
      <>
        <Modal.Content>
          <FlexBox alignItems={"center"} flexDirection={"column"} gap={"xs2"}>
            <FlexBox.Item>
              <ConfirmDeactivationModalIconWrapperStyled>
                <Icons
                  name={"IconAlertCircle"}
                  size={64}
                  stroke={"1"}
                  fill={"transparent"}
                  color={theme.colors.feedback.error[40]}
                />
              </ConfirmDeactivationModalIconWrapperStyled>
            </FlexBox.Item>
            <FlexBox.Item>
              <Typography.Body3 center color={theme.colors.feedback.error[40]}>
                Atenção!
              </Typography.Body3>
            </FlexBox.Item>
            <FlexBox.Item>
              <Typography.Headline6 center>{title}</Typography.Headline6>
            </FlexBox.Item>
            <FlexBox.Item>
              <Typography.Body3 center>
                Uma vez desabilitada, a pessoa não poderá mais solicitar
                empréstimos consignados.
              </Typography.Body3>
            </FlexBox.Item>
            <FlexBox.Item>
              <Typography.Body3 center>
                Se estiver ativa na empresa, ela ainda terá acesso ao menu de
                Consignado no aplicativo, porém sem poder solicitar novos
                empréstimos.
              </Typography.Body3>
            </FlexBox.Item>
          </FlexBox>
        </Modal.Content>
        <Modal.Footer>
          <ConfirmDeactivationModalFooterContent
            loading={loading}
            onCancel={onClose}
            onSubmit={onSubmit}
          />
        </Modal.Footer>
      </>
    </Modal.Root>
  );
};

export default ConfirmDeactivationModal;
