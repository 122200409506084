import { Button, LinkButton } from "@flash-tecnologia/hros-web-ui-v2";
import FlexBox from "../../../../../components/atoms/FlexBox";
import { ContentWrapperStyled } from "./styled";

const ConfirmDeactivationModalFooterContent = ({
  loading,
  onCancel,
  onSubmit,
}) => {
  return (
    <ContentWrapperStyled alignItems="center" justifyContent="space-between">
      <FlexBox.Item>
        <LinkButton
          disabled={loading}
          onClick={onCancel}
          variant="neutral"
          size="small"
        >
          Cancelar
        </LinkButton>
      </FlexBox.Item>
      <FlexBox.Item>
        <Button
          variant="primary"
          loading={loading}
          onClick={onSubmit}
          size="small"
          variantType="error"
        >
          Desabilitar
        </Button>
      </FlexBox.Item>
    </ContentWrapperStyled>
  );
};

export default ConfirmDeactivationModalFooterContent;
