import { useTheme } from "styled-components";
import Advantages from "./components/Advantages";
import {
  PAYROLL_EMPLOYEE_ADVANTAGES,
  PAYROLL_HR_ADVANTAGES,
} from "./components/Advantages/consts";
import Definition from "./components/Definition";
import DiscoverOurSolution from "./components/DiscoverOurSolution";
import FAQ from "./components/FAQ";
import PartnerAdvantages from "./components/PartnerAdvantages";
import { PARTNER_ADVANTAGES } from "./components/PartnerAdvantages/consts";
import { ContainerWrapper } from "./styled";
import { useRequestContactViewModel } from "./viewModel/useRequestContactViewModel";
import { useEffect } from "react";
import { useTracking } from "../../utils/useTracking";
import { useSearchParams } from "react-router-dom";

export default function LandingPageView() {
  const theme = useTheme();
  const tracking = useTracking();
  const [searchParams] = useSearchParams();

  useEffect(() => {
    const sourceQueryParam = searchParams.get("source");
    tracking.trackPage({
      name: "payroll_loan_landing_page",
      ...(sourceQueryParam && {
        params: {
          source: sourceQueryParam,
        },
      }),
    });
  }, []);

  const { isLoading: isRequestContactLoading, handleRequestContact } =
    useRequestContactViewModel();

  return (
    <ContainerWrapper>
      <DiscoverOurSolution
        loading={isRequestContactLoading}
        onClick={handleRequestContact}
      />
      <Advantages
        advantages={PAYROLL_HR_ADVANTAGES}
        title="Vantagens para o RH"
      />
      <Advantages
        advantages={PAYROLL_EMPLOYEE_ADVANTAGES}
        backgroundColor={theme.colors.secondary[99]}
        title="Vantagens para o colaborador"
      />
      <PartnerAdvantages partnerAdvantages={PARTNER_ADVANTAGES} />
      <Definition
        loading={isRequestContactLoading}
        onClick={handleRequestContact}
      />
      <FAQ />
    </ContainerWrapper>
  );
}
