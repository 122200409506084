import { trpc } from "../../api/client";

export enum UploadLinkFileType {
  User = "user",
  Bill = "bill",
}

interface GetUploadLinkUseCaseInput {
  companyId: string;
  fileType: UploadLinkFileType;
  keepPreviousData?: boolean;
  onSuccess?: (getUploadLinkUseCaseData: GetUploadLinkUseCaseResponse) => void;
  onError?: () => void;
}

export interface GetUploadLinkUseCaseResponse {
  bucketPath: string;
  signedUrl: string;
}

export const getUploadLinkUseCase = ({
  companyId,
  fileType,
  keepPreviousData = false,
  onSuccess,
  onError,
}: GetUploadLinkUseCaseInput) => {
  const { data, isLoading } = trpc.fileProcessing.getUploadLink.useQuery(
    { companyId, fileType },
    {
      keepPreviousData,
      retry: false,
      retryOnMount: false,
      refetchOnWindowFocus: false,
      onSuccess,
      onError,
    }
  );

  return {
    data,
    isLoading,
  };
};
