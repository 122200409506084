import { trpc } from "../../../api/client";

export const getLoanRequestDetailsUseCase = (
  companyId: string,
  providerLoanId: string
) => {
  const { data, isLoading } = trpc.loans.getLoanRequestDetails.useQuery(
    {
      companyId,
      providerLoanId,
    },
    { refetchOnWindowFocus: false, enabled: !!providerLoanId }
  );

  return {
    data,
    isLoading,
  };
};
