import { z } from "zod";
import { trpc } from "../../../api/client";
import { CreateBillInputSchema } from "server/src/routers/trpc/bills/createBill";

interface CreateBillUseCaseParams {
  onError: (error: any) => void;
  onSuccess: () => void;
}

export const createBillUseCase = ({
  onError,
  onSuccess,
}: CreateBillUseCaseParams) => {
  const createBillMutation = trpc.bills.createBill.useMutation({
    onError,
    onSuccess,
  });

  return {
    handleBillCreate: function (input: z.infer<typeof CreateBillInputSchema>) {
      createBillMutation.mutate(input);
    },
    isLoading: createBillMutation.isLoading,
  };
};
