import { Card } from "@atoms/Card";
import { Grid } from "@mui/material";
import { useUploadBillFileViewModel } from "@pages/CreateBillPage/viewModel/useUploadBillFileViewModel";
import Spacer from "../../../../components/Spacer";
import Typography from "../../../../components/Typography";
import FileUploader from "../../../../components/organisms/FileUploader";

const UploadBillFile = () => {
  const { onFileChange, onUploadError, onUploadedFileClear } =
    useUploadBillFileViewModel();

  return (
    <Card>
      <Typography.Headline8>{"Envie seu arquivo"}</Typography.Headline8>
      <Spacer y="xs5" />
      <Typography.Body3>
        {"Após preencher o arquivo, é só anexar abaixo!"}
      </Typography.Body3>
      <Spacer y="s" />
      <Grid container>
        <Grid item xs={12} lg={7}>
          <FileUploader
            acceptedFileTypes={["xlsx"]}
            onFileChange={onFileChange}
            onUploadedFileClear={onUploadedFileClear}
            onError={onUploadError}
            title={"Seu arquivo"}
          />
        </Grid>
      </Grid>
    </Card>
  );
};

export default UploadBillFile;
