import { Card } from "@atoms/Card";
import FlexBox from "@atoms/FlexBox";
import { Divider, Icons, ShapeIcon } from "@flash-tecnologia/hros-web-ui-v2";
import { Grid } from "@mui/material";
import { CreateBillPageContext } from "@pages/CreateBillPage";
import { toCurrency } from "@utils/masks/toCurrency";
import { useContext, useEffect } from "react";
import { useTheme } from "styled-components";
import Spacer from "../../../../components/Spacer";
import Typography from "../../../../components/Typography";
import { BilletCardStyled } from "./styled";

const BillSummary = () => {
  const theme = useTheme();
  const { bill, enableContinueButton } = useContext(CreateBillPageContext);

  const { employees, value } = bill ?? {};

  const employeesCount = employees?.length ?? 0;
  const billTotalCurrency = toCurrency(value ?? 0);

  useEffect(() => {
    enableContinueButton?.();
  }, [enableContinueButton]);

  return (
    <>
      <Card>
        <Typography.Headline8>{"Resumo da fatura"}</Typography.Headline8>
        <Spacer y={"m"} />
        <Grid container columnSpacing={theme.spacings.m}>
          <Grid item xs={12} lg>
            <Typography.Headline8>{"Itens da fatura"}</Typography.Headline8>
            <Spacer y={"xs"} />
            <Divider orientation={"horizontal"} />
            <Spacer y={"xs"} />
            <Typography.Headline8>
              {"Parcelas com desconto em folha"}
            </Typography.Headline8>
            <Spacer y={"xs"} />
            <FlexBox alignItems={"center"} justifyContent={"space-between"}>
              <Typography.Body3 weight={400} color={theme.colors.neutral30}>
                {"Valor total"}
              </Typography.Body3>
              <Typography.Body3 weight={700}>
                {billTotalCurrency}
              </Typography.Body3>
            </FlexBox>
            <Spacer y={"xs"} />
            <Divider orientation={"horizontal"} />
            <Spacer y={"xs"} />
            <Typography.Headline8>
              {"Pessoas contempladas"}
            </Typography.Headline8>
            <Spacer y={"xs2"} />
            <FlexBox gap={"xs4"} alignItems={"center"}>
              <Icons name="IconUsers" size={24} />
              <Typography.Body3 weight={400} color={theme.colors.neutral30}>
                {employeesCount}
              </Typography.Body3>
            </FlexBox>
          </Grid>
          <Grid item xs={12} lg={5}>
            <Typography.Headline8>{"Método de pagamento"}</Typography.Headline8>
            <Spacer y={"xs"} />
            <BilletCardStyled>
              <ShapeIcon
                name="BarcodeMethod"
                color={theme.colors.secondary[50]}
                size={48}
                variant="default"
              />
              <Spacer y={"xs2"} />
              <Typography.Body3 weight={700}>
                {"Boleto bancário"}
              </Typography.Body3>
              <Spacer y={"xs2"} />
              <Typography.Body4 weight={400} color={theme.colors.neutral30}>
                {
                  "O pagamento pode ser realizado através de uma conta ou agência bancária e tem o prazo de compensação de até 3 dias úteis."
                }
              </Typography.Body4>
            </BilletCardStyled>
            <Spacer y={"s"} />
            <Typography.Headline8>{"Total da fatura"}</Typography.Headline8>
            <Spacer y={"xs4"} />
            <Typography.Headline6 color={theme.colors.secondary[50]}>
              {billTotalCurrency}
            </Typography.Headline6>
          </Grid>
        </Grid>
      </Card>
    </>
  );
};

export default BillSummary;
