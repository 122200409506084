import { useLocation } from "react-router-dom";
import EnvService, { BuildEnvironments } from "../../../api/EnvService";
import { PayrollLoanPageTabs } from "../../../types/payrollLoanPageTabs";
import EmployeesTab from "../components/EmployeesTab";
import InstallmentsTab from "../components/InstallmentsTab";
import LoanRequestsTab from "../components/LoanRequestsTab";
import TerminationsTab from "../components/TerminationTab";
import { useEffect, useState } from "react";
import { TabItens } from "@flash-tecnologia/hros-web-ui-v2";

interface PayrollLoanPageState {
  initialTab: PayrollLoanPageTabs;
}

interface TabSetting {
  title: string;
  component: JSX.Element;
  suporttedEnvironments: BuildEnvironments[];
}

const tabsSettings: { [key in PayrollLoanPageTabs]?: TabSetting } = {
  [PayrollLoanPageTabs.Employees]: {
    title: "Base",
    component: <EmployeesTab />,
    suporttedEnvironments: [
      BuildEnvironments.Production,
      BuildEnvironments.Staging,
      BuildEnvironments.Development,
    ],
  },
  [PayrollLoanPageTabs.LoanRequests]: {
    title: "Solicitações",
    component: <LoanRequestsTab />,
    suporttedEnvironments: [
      BuildEnvironments.Staging,
      BuildEnvironments.Development,
    ],
  },
  [PayrollLoanPageTabs.Installments]: {
    title: "Parcelas",
    component: <InstallmentsTab />,
    suporttedEnvironments: [
      BuildEnvironments.Staging,
      BuildEnvironments.Development,
    ],
  },
  [PayrollLoanPageTabs.Terminations]: {
    title: "Rescisão",
    component: <TerminationsTab />,
    suporttedEnvironments: [
      BuildEnvironments.Staging,
      BuildEnvironments.Development,
    ],
  },
};
export function usePayrollLoanPageTabsViewModel() {
  const location = useLocation();

  const [payrollLoanPageState, setPayrollLoanPageState] =
    useState<PayrollLoanPageState>();

  const visibleTabs = Object.entries(tabsSettings).reduce<{
    [key in PayrollLoanPageTabs]?: TabSetting;
  }>((acc, [tab, settings]) => {
    if (settings.suporttedEnvironments.includes(EnvService.BUILD_ENV)) {
      acc[tab] = settings;
    }
    return acc;
  }, {});

  const tabItens = Object.values(visibleTabs).map<TabItens>((tabSetting) => {
    return {
      label: tabSetting.title,
      component: tabSetting.component,
    };
  });

  const getSelectedTabIndex = () => {
    if (
      payrollLoanPageState?.initialTab &&
      visibleTabs[payrollLoanPageState.initialTab]
    ) {
      const selectedTabIndex = Object.keys(visibleTabs).findIndex(
        (payrollLOanPageTab) =>
          payrollLOanPageTab === payrollLoanPageState.initialTab
      );
      setPayrollLoanPageState(undefined);
      return selectedTabIndex;
    }
  };

  useEffect(() => {
    if (location.state) {
      setPayrollLoanPageState(location.state as PayrollLoanPageState);
    }
  }, [location]);

  return {
    tabItens: tabItens,
    selectedTab: getSelectedTabIndex(),
  };
}
