import { trpc } from "../../../api/client";

export const getLastFileProcessingUseCase = (companyId: string) => {
  const { data, isLoading, refetch } =
    trpc.fileProcessing.getLastFileProcessing.useQuery(
      { companyId },
      { cacheTime: 0, keepPreviousData: false, refetchOnWindowFocus: false }
    );

  return {
    data,
    isLoading,
    refetch,
  };
};
