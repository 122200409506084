import { PartnerAdvantageProps, PartnerCustomIcons } from "./PartnerAdvantage";

export const PARTNER_ADVANTAGES: PartnerAdvantageProps[] = [
  {
    icon: PartnerCustomIcons.USER,
    title: "+ de 300 mil colaboradores",
    description: "com acesso aos produtos",
  },
  {
    icon: PartnerCustomIcons.BAG,
    title: "+ de 2300",
    description: "empresas conveniadas",
  },
  {
    icon: PartnerCustomIcons.INVEST,
    title: "Desde 2018",
    description:
      "oferecendo uma plataforma completa de empréstimo consignado privado",
  },
];
