export const BILL_FILE_GENERIC_ERROR_MESSAGE =
  "Parcela não processada. Prepare o modelo de arquivo de fatura e tente novamente.";

export enum BillsErrorCodes {
  BillFileDuplicatedInstallment = "BILL_FILE_DUPLICATED_INSTALLMENT",
  BillFileInvalidDiscountValue = "BILL_FILE_INVALID_DISCOUNT_VALUE",
  BillFileTemplateInstallmentNotFound = "BILL_FILE_TEMPLATE_INSTALLMENT_NOT_FOUND",
  BillFileUnsupportedDifferentDiscountReason = "BILL_FILE_UNSUPPORTED_DIFFERENT_DISCOUNT_REASON",
  MissingAdminEmail = "MISSING_ADMIN_EMAIL",
  MissingDescription = "MISSING_DESCRIPTION",
}

enum UserFriendlyDifferentDiscountReasons {
  Leave = "Afastamento",
  Deceased = "Falecimento",
  Vacation = "Férias",
  Termination = "Desligamento",
  NoLimitPerInstallment = "Sem margem consignável",
}

export const getBillFileUserFriendlyErrorMessage = (errorCode: string) => {
  return (
    {
      [BillsErrorCodes.BillFileDuplicatedInstallment]: "Parcela duplicada.",
      [BillsErrorCodes.BillFileInvalidDiscountValue]:
        "Valor descontado é maior que o valor da parcela.",
      [BillsErrorCodes.BillFileTemplateInstallmentNotFound]:
        "Parcela não encontrada em nossos registros. Prepare o modelo de arquivo de fatura novamente.",
      [BillsErrorCodes.BillFileUnsupportedDifferentDiscountReason]: `Campo "MOTIVO DE DESCONTO DIFERENTE" é inválido. Utilize apenas os valores ${Object.values(
        UserFriendlyDifferentDiscountReasons
      )
        .map((reason) => `"${reason}"`)
        .join(", ")}`,
      [BillsErrorCodes.MissingAdminEmail]:
        "Não conseguimos encontrar seu e-mail. Finalize seu cadastro e tente novamente",
      [BillsErrorCodes.MissingDescription]:
        'Descontos menores que o valor da parcela devem ser justificados através da coluna "MOTIVO DE DESCONTO DIFERENTE".',
    }[errorCode] ?? BILL_FILE_GENERIC_ERROR_MESSAGE
  );
};
