import FlexBox from "@atoms/FlexBox";
import {
  Button,
  Icons,
  tableControllers,
  Tooltip,
} from "@flash-tecnologia/hros-web-ui-v2";
import { PaginationState } from "@flash-tecnologia/hros-web-ui-v2/dist/components/Table/components/Pagination";
import { useSelectedCompany } from "@flash-tecnologia/hros-web-utility";
import {
  findTerminationEmployeesUseCase,
  FindTerminationEmployeesUseCaseOutput,
} from "@pages/PayrollLoan/components/TerminationTab/useCases/findTerminationEmployeesUseCase";
import { dayjs } from "@utils/dayjs";
import { dispatchToast } from "@utils/dispatchEvents";
import { cpfMask } from "@utils/masks/formatCPF";
import { toCurrency } from "@utils/masks/toCurrency";
import { useDebouncedValue } from "@utils/useDebouncedValue";
import { useState } from "react";
import { PAYROLL_LOAN_GENERIC_ERROR_MESSAGE } from "server/src/utils/errorHandlers/payrollLoan.errors";
import { useTheme } from "styled-components";
import Typography from "../../../../../components/Typography";
import UserTableCell from "../../../../../components/molecules/UserTableCell";
import { CurrencyCellStyled, TerminateButtonWrapperStyled } from "../styled";

export interface TerminationEmployee {
  id: string;
  isTerminated: boolean;
  name: string;
  documentNumber: string;
  maturityDate: string;
  remainingBalance: number;
}

const INITIAL_PAGINATION_STATE = {
  pageNumber: 1,
  pageSize: 10,
};

export function useTerminationEmployeesListViewModel(input: {
  onTerminateEmployeeButtonClick: (
    terminationEmployee: TerminationEmployee
  ) => void;
}) {
  const { onTerminateEmployeeButtonClick } = input;

  const [paginationState, setPaginationState] = useState<PaginationState>(
    INITIAL_PAGINATION_STATE
  );

  const [searchInput, setSearchInput] = useState("");
  const billetDueDate = dayjs().format("YYYY-MM-DD");

  const theme = useTheme();

  const { selectedCompany } = useSelectedCompany();

  const searchInputDebouncedValue = useDebouncedValue<string>({
    inputValue: searchInput,
    delayInMillis: 500,
  });

  const {
    data: findTerminationEmployeesResponse,
    isFetching: isFindTerminationEmployeesFetching,
    refetch: refetchTerminationEmployees,
  } = findTerminationEmployeesUseCase({
    companyId: selectedCompany.id,
    currentPage: paginationState.pageNumber,
    billetDueDate,
    onError: (error) => {
      dispatchToast({
        type: "error",
        content:
          error.userFriendlyMessage ?? PAYROLL_LOAN_GENERIC_ERROR_MESSAGE,
      });
    },
    pageSize: paginationState.pageSize,
    searchInput: searchInputDebouncedValue,
  });

  const handlePaginationChange = ({ pageSize, pageNumber }) => {
    setPaginationState({ ...paginationState, pageSize, pageNumber });
  };

  const handleSearchInputChange = (event) => {
    if (event?.target) {
      setSearchInput(event.target.value);
    }
  };

  const table = tableControllers.useTableColumns<
    FindTerminationEmployeesUseCaseOutput["data"][number]
  >({
    data: findTerminationEmployeesResponse?.data ?? [],
    total: findTerminationEmployeesResponse?.total,
    columns: [
      {
        header: "Nome",
        accessorKey: "name",
        cell: ({ cell }) => {
          const { name, documentNumber } = cell.row.original;
          const documentNumberFormatted = cpfMask(documentNumber);
          return (
            <UserTableCell name={name} document={documentNumberFormatted} />
          );
        },
      },
      {
        header: "Data do fim do contrato",
        accessorKey: "maturityDate",
        cell: ({ cell }) => {
          const maturityDate = cell?.row?.original?.maturityDate;
          const formattedDate = dayjs(maturityDate).isValid()
            ? dayjs(maturityDate).format("DD/MM/YYYY")
            : "-";

          return (
            <FlexBox alignItems={"center"} gap={"xs2"}>
              <Icons
                name="IconCalendar"
                fill={"transparent"}
                size={24}
                color={theme.colors.neutral[30]}
              />
              <Typography.Body3 color={theme.colors.neutral[40]} weight={600}>
                {formattedDate}
              </Typography.Body3>
            </FlexBox>
          );
        },
      },
      {
        header: "Saldo estimado para quitação (R$)",
        accessorKey: "remainingBalance",
        cell: ({ cell }) => {
          const remainingBalance = cell?.row?.original?.remainingBalance;
          const formattedRemainingBalance = toCurrency(remainingBalance, true);

          return (
            <CurrencyCellStyled color={theme.colors.neutral[40]} weight={600}>
              {formattedRemainingBalance}
            </CurrencyCellStyled>
          );
        },
      },
      {
        header: "Criar fatura de rescisão",
        id: "terminate",
        sticky: "right",
        cell: ({ cell }) => {
          const currentTerminationEmployee = cell?.row
            ?.original as TerminationEmployee;

          let tooltipMessage = "";

          if (!currentTerminationEmployee.remainingBalance) {
            tooltipMessage =
              "Não é possível criar fatura, pois esta pessoa não possui saldo para quitação";
          } else if (currentTerminationEmployee.isTerminated) {
            tooltipMessage =
              "Não é possível criar fatura, pois esta pessoa já foi rescindida";
          }

          return (
            <Tooltip title={tooltipMessage}>
              <TerminateButtonWrapperStyled>
                <Button
                  variant="primary"
                  disabled={!!tooltipMessage}
                  onClick={() =>
                    onTerminateEmployeeButtonClick(currentTerminationEmployee)
                  }
                  size={"small"}
                  variantType={"default"}
                >
                  Rescindir
                  <Icons
                    name="IconFileOff"
                    color={theme.colors.neutral[100]}
                    fill={"transparent"}
                  />
                </Button>
              </TerminateButtonWrapperStyled>
            </Tooltip>
          );
        },
      },
    ],
    pagination: paginationState,
    onPaginationChange: handlePaginationChange,
  });

  return {
    isLoading: isFindTerminationEmployeesFetching,
    onSearchInputChange: handleSearchInputChange,
    onPaginationChange: handlePaginationChange,
    paginationState,
    pageSizeOptions: [
      {
        label: "10 itens",
        value: 10,
      },
    ],
    refetchTerminationEmployees,
    table,
    totalCount: findTerminationEmployeesResponse?.total ?? 0,
  };
}
