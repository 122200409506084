import { ShapeIconProps } from "@flash-tecnologia/hros-web-ui-v2";
import { useSelectedCompany } from "@flash-tecnologia/hros-web-utility";
import { validateBillFileUseCase } from "@pages/CreateBillPage/useCases/validateBillFileUseCase";
import { EType } from "@utils/dispatchEvents/types";
import {
  getUploadLinkUseCase,
  UploadLinkFileType,
} from "@utils/useCases/getUploadLinkUseCase";
import { useContext, useEffect, useState } from "react";
import { useTheme } from "styled-components";
import { CreateBillPageContext } from "..";
import { dispatchToast } from "../../../utils/dispatchEvents";
import {
  UploadFileToAWSType,
  uploadFileToAWS,
} from "../../../utils/useCases/uploadFileToAWS";
import { MessageContentProps } from "../components/ValidateBillFile/components/MessageContent";
import ValidateBillFileErrorContent from "../components/ValidateBillFile/components/ValidateBillFileErrorContent";
import ValidateBillFileLoadingContent from "../components/ValidateBillFile/components/ValidateBillFileLoadingContent";
import ValidateBillFileSuccessContent from "../components/ValidateBillFile/components/ValidateBillFileSuccessContent";

type ToastErrorType = {
  type: EType;
  content: string;
};

enum ValidateBillFileStatus {
  ERROR = "ERROR",
  PROCESSING = "PROCESSING",
  SUCCESS = "SUCCESS",
}

const TOAST_ERROR_MESSAGE: ToastErrorType = {
  type: "error",
  content: "Erro ao importar o arquivo. Tente novamente.",
};

export function useValidateBillFileViewModel() {
  const {
    billFileTemplatePath,
    disableContinueButton,
    enableContinueButton,
    onBillChange,
    onBillFilePathChange,
    onReuploadBillFileButtonClick,
    onValidateBillFileError,
    uploadedFile,
  } = useContext(CreateBillPageContext);
  const { selectedCompany } = useSelectedCompany();
  const theme = useTheme();

  const [isUploadBillFileToAWSLoading, setIsUploadBillFileToAWSLoading] =
    useState(false);

  const [validateBillFileStatus, setValidateBillFileStatus] =
    useState<ValidateBillFileStatus>(ValidateBillFileStatus.PROCESSING);

  const getValidateBillFileMessageContentProps = (
    successfullImports?: number,
    failureImports?: number,
    validateBillFileStatus?: ValidateBillFileStatus
  ): MessageContentProps => {
    switch (validateBillFileStatus) {
      case ValidateBillFileStatus.SUCCESS:
        const successIconProps: ShapeIconProps = {
          name: "IconCheck",
          size: 56,
          variant: "success",
          color: theme.colors.feedback.success[40],
        };
        return {
          description: `${successfullImports} parcela(s) importada(s) com sucesso`,
          body: {
            description: `${successfullImports} de ${successfullImports} parcelas importadas`,
            title: "Progresso",
          },
          shapeIconProps: successIconProps,
          title: "Sucesso na importação!",
        };
      case ValidateBillFileStatus.ERROR:
        const errorIconProps: ShapeIconProps = {
          name: "IconAlertTriangle",
          size: 56,
          variant: "info",
          color: theme.colors.feedback.info[40],
        };
        return {
          description: `${successfullImports} parcelas tiveram suas informações processadas. ${failureImports} parcelas não puderam ser processadas.`,
          body: {
            description:
              "Você deve corrigir os erros indicados e reenviar o arquivo ajustado.",
            title: "Confira os erros abaixo",
          },
          shapeIconProps: errorIconProps,
          title: "Erro na importação",
        };
      default:
        const loadingIconProps: ShapeIconProps = {
          name: "IconBusinessplan",
          size: 56,
          variant: "default",
          color: theme.colors.primary,
        };
        return {
          description: "Por favor, aguarde",
          body: {
            description: `Processando dados da planilha...`,
            title: "Progresso",
          },
          shapeIconProps: loadingIconProps,
          title: "Organizando os itens do arquivo...",
        };
    }
  };

  const handleValidateBillFileError = () => {
    dispatchToast(TOAST_ERROR_MESSAGE);
    onValidateBillFileError?.();
  };

  const {
    data: getUploadLinkUseCaseData,
    isLoading: isGetUploadLinkUseCaseLoading,
  } = getUploadLinkUseCase({
    companyId: selectedCompany.id,
    fileType: UploadLinkFileType.Bill,
    onSuccess: (getUploadLinkUseCaseData) => {
      handleUploadBillFileToAWS({
        contentType: uploadedFile?.contentType || "",
        data: uploadedFile?.data || new ArrayBuffer(0),
        url: getUploadLinkUseCaseData?.signedUrl || "",
      });
    },
    onError: handleValidateBillFileError,
  });

  const handleUploadBillFileToAWS = async (
    uploadBillFileToAWSBody: UploadFileToAWSType
  ) => {
    setIsUploadBillFileToAWSLoading(true);
    const response = await uploadFileToAWS(uploadBillFileToAWSBody);
    if (!response.ok) {
      return handleValidateBillFileError();
    }
    setIsUploadBillFileToAWSLoading(false);
    handleValidateBillFile();
  };

  const {
    data: validateBillFileData,
    isLoading: isValidateBillFileUseCaseLoading,
    handleValidateBillFile,
  } = validateBillFileUseCase({
    companyId: selectedCompany.id,
    billFileTemplatePath: billFileTemplatePath ?? "",
    billFilePath: getUploadLinkUseCaseData?.bucketPath || "",
    onSuccess: (data) => {
      if (data) {
        if (data.errors.length) {
          setValidateBillFileStatus(ValidateBillFileStatus.ERROR);
        } else {
          setValidateBillFileStatus(ValidateBillFileStatus.SUCCESS);
          onBillChange?.(data.bill);
          onBillFilePathChange?.(getUploadLinkUseCaseData?.bucketPath || "");
          enableContinueButton?.();
        }
      }
    },
    onError: handleValidateBillFileError,
  });

  const getValidateBillFileContent = (
    validateBillFileMessageContentProps: MessageContentProps,
    validateBillFileStatus?: ValidateBillFileStatus
  ) => {
    switch (validateBillFileStatus) {
      case ValidateBillFileStatus.SUCCESS:
        return (
          <ValidateBillFileSuccessContent
            messageContentProps={validateBillFileMessageContentProps}
          />
        );
      case ValidateBillFileStatus.ERROR:
        return (
          validateBillFileData && (
            <ValidateBillFileErrorContent
              messageContentProps={validateBillFileMessageContentProps}
              onReuploadBillFileButtonClick={onReuploadBillFileButtonClick}
              validateBillFileData={validateBillFileData}
            />
          )
        );
      default:
        return (
          <ValidateBillFileLoadingContent
            messageContentProps={validateBillFileMessageContentProps}
          />
        );
    }
  };

  const validateBillFileMessageContentProps =
    getValidateBillFileMessageContentProps(
      validateBillFileData?.succeededQuantity,
      validateBillFileData?.failedQuantity,
      validateBillFileStatus
    );

  const validateBillFileContent = getValidateBillFileContent(
    validateBillFileMessageContentProps,
    validateBillFileStatus
  );

  useEffect(() => {
    disableContinueButton?.();
  }, []);

  return {
    isLoading:
      isValidateBillFileUseCaseLoading ||
      isGetUploadLinkUseCaseLoading ||
      isUploadBillFileToAWSLoading,
    validateBillFileContent,
  };
}
