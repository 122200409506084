import { BrowserRouter, Routes, Route } from "react-router-dom";

import VerifyPermissionsProductPage from "../components/VerifyPermissions/VerifyPermissionsProductPage";
import VerifyPermissionsLandingPage from "../components/VerifyPermissions/VerifyPermissionsLandingPage";
import { PayrollLoanPage } from "../pages/PayrollLoan";
import { UploadSheetPage } from "../pages/UploadSheet";
import { UploadSheetInfoPage } from "../pages/UploadSheetInfo";
import { LandingPageView } from "../pages/LandingPage";
import LandingPageScheduler from "@pages/LandingPageScheduler";
import CreateBillPage from "@pages/CreateBillPage";

const AppRouter = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route
          path="/payroll-loan/landing-page"
          element={<VerifyPermissionsLandingPage />}
        >
          <Route
            path="/payroll-loan/landing-page"
            element={<LandingPageView />}
          />
          <Route
            path="/payroll-loan/landing-page/scheduler"
            element={<LandingPageScheduler />}
          />
        </Route>
        <Route path="/payroll-loan" element={<VerifyPermissionsProductPage />}>
          <Route path="/payroll-loan" element={<PayrollLoanPage />} />
          <Route
            path="/payroll-loan/upload-sheet"
            element={<UploadSheetPage />}
          />
          <Route
            path="/payroll-loan/upload-sheet/info"
            element={<UploadSheetInfoPage />}
          />
          <Route
            path="/payroll-loan/create-bill"
            element={<CreateBillPage />}
          />
        </Route>
      </Routes>
    </BrowserRouter>
  );
};

export default AppRouter;
