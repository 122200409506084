export type UploadFileToAWSType = {
  data: ArrayBuffer;
  contentType: string;
  url: string;
};

export const uploadFileToAWS = async ({
  data,
  contentType,
  url,
}: UploadFileToAWSType) => {
  const fileBody = new Blob([data]);
  return fetch(url, {
    method: "PUT",
    body: fileBody,
    headers: {
      "Content-Type": contentType,
    },
  });
};
