import { useSelectedCompany } from "@flash-tecnologia/hros-web-utility";
import { useState } from "react";

import {
  HandleUploadImageType,
  handleUploadImage,
} from "../useCases/uploadCompanySheet";
import { handleSendFinishedFileProcessing } from "../useCases/sendFinishedFileProcessing";
import { dispatchToast } from "../../../utils/dispatchEvents";
import { DropzoneFile } from "@flash-tecnologia/hros-web-ui-v2/dist/components/Dropzone/types";
import { useNavigate } from "react-router-dom";
import {
  getUpsertEmployeeTemplateFileLinkUseCase,
  TemplateTypes,
} from "@utils/useCases/getUpsertEmployeeTemplateFileLinkUseCase";
import { useTracking } from "@utils/useTracking";
import {
  getUploadLinkUseCase,
  UploadLinkFileType,
} from "@utils/useCases/getUploadLinkUseCase";

export const useUploadSheetViewModel = () => {
  const initialUploadSheetBody: HandleUploadImageType = {
    contentType: "",
    data: new ArrayBuffer(0),
    url: "",
  };
  const navigate = useNavigate();
  const tracking = useTracking();
  const { selectedCompany } = useSelectedCompany();
  const [uploadSheetBody, setUploadSheetBody] = useState<HandleUploadImageType>(
    initialUploadSheetBody
  );
  const [isUploadImageLoading, setIsUploadImageLoading] = useState(false);
  const [template, setTemplate] = useState<TemplateTypes>(TemplateTypes.Empty);

  const [
    isGetUpsertEmployeeTemplateEnabled,
    setIsGetUpsertEmployeeTemplateEnabled,
  ] = useState(true);

  const {
    data: upsertEmployeeTemplateFile,
    isLoading: isGetUploadSheetLinkLoading,
  } = getUpsertEmployeeTemplateFileLinkUseCase({
    companyId: selectedCompany.id,
    template: template as TemplateTypes,
    keepPreviousData: true,
    enabled: isGetUpsertEmployeeTemplateEnabled,
  });

  const { data: uploadLinkData, isLoading: isGetUploadLinkLoading } =
    getUploadLinkUseCase({
      companyId: selectedCompany.id,
      fileType: UploadLinkFileType.User,
      keepPreviousData: true,
    });

  const uploadImageHandler = async () => {
    setIsUploadImageLoading(true);
    const response = await handleUploadImage(uploadSheetBody);
    setIsUploadImageLoading(false);
    return response;
  };

  const handleSendFinishedFileProcessingSuccess = () => {
    navigate("/payroll-loan/upload-sheet/info", { replace: true });
  };

  const {
    isLoading: isSendFinishedFileLoading,
    sendFinishedFileProcessingMutation,
  } = handleSendFinishedFileProcessing(
    selectedCompany.id,
    uploadLinkData?.bucketPath as string,
    handleSendFinishedFileProcessingSuccess
  );

  const clearUploadedFiles = () => {
    setUploadSheetBody(initialUploadSheetBody);
  };

  const handleDropzoneChange = (dropzoneFiles: DropzoneFile[]) => {
    if (!dropzoneFiles.length) {
      clearUploadedFiles();
      return;
    }
    if (dropzoneFiles[0].error) {
      dispatchToast({
        type: "error",
        content: "Não foi possível processar a planilha",
      });
      return;
    }

    if (dropzoneFiles[0].data) {
      setUploadSheetBody({
        data: dropzoneFiles[0].data as ArrayBuffer,
        contentType: dropzoneFiles[0].mime as string,
        url: uploadLinkData?.signedUrl as string,
      });
      return;
    }
  };

  const handleOpenFileDetailsAccordion = () => {
    tracking.trackEvent({
      name: "payroll_loan_upload_sheet_opened_file_details_accordion",
    });
  };

  const handleChangeRadio = (template: TemplateTypes) => {
    setTemplate(template);
    setIsGetUpsertEmployeeTemplateEnabled(true);
    if (template === TemplateTypes.ActiveOnly) {
      tracking.trackEvent({
        name: "payroll_loan_upload_sheet_selected_active_only_sheet",
      });
    } else if (template === TemplateTypes.Full) {
      tracking.trackEvent({
        name: "payroll_loan_upload_sheet_selected_full_sheet",
      });
    }
  };

  return {
    clearUploadedFiles,
    templateSheetLink: upsertEmployeeTemplateFile?.templateSheetLink as string,
    handleDropzoneChange,
    isLoading:
      isGetUploadLinkLoading ||
      isUploadImageLoading ||
      isSendFinishedFileLoading,
    uploadImageHandler,
    sendFinishedFileProcessingMutation,
    template,
    handleChangeRadio,
    isGetUploadSheetLinkLoading,
    handleOpenFileDetailsAccordion,
  };
};
