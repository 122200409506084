import { Button, LinkButton } from "@flash-tecnologia/hros-web-ui-v2";
import FlexBox from "../../../../../../components/atoms/FlexBox";
import { ContentWrapperStyled } from "./styled";

export interface LoanRequestActionModalFooterContentProps {
  loading: boolean;
  onCancel: () => void;
  onSubmit: () => void;
  submitButtonText: string;
}

const LoanRequestActionModalFooterContent = ({
  loading,
  onCancel,
  onSubmit,
  submitButtonText,
}: LoanRequestActionModalFooterContentProps) => {
  return (
    <ContentWrapperStyled alignItems="center" justifyContent="space-between">
      <FlexBox.Item>
        <LinkButton
          disabled={loading}
          onClick={onCancel}
          variant="neutral"
          size="small"
        >
          Cancelar
        </LinkButton>
      </FlexBox.Item>
      <FlexBox.Item>
        <Button
          variant="primary"
          loading={loading}
          onClick={onSubmit}
          size="small"
          variantType="error"
        >
          {submitButtonText}
        </Button>
      </FlexBox.Item>
    </ContentWrapperStyled>
  );
};

export default LoanRequestActionModalFooterContent;
