import { Grid } from "@mui/material";
import { useTheme } from "styled-components";
import Spacer from "../../../../components/Spacer";
import Typography from "../../../../components/Typography";
import Advantage, { AdvantageProps } from "./Advantage";
import { AdvantagesBackgroundStyled } from "./styled";

interface AdvantagesProps {
  advantages: AdvantageProps[];
  backgroundColor?: string;
  title: string;
}

const Advantages = ({
  advantages,
  backgroundColor,
  title,
}: AdvantagesProps) => {
  const theme = useTheme();
  return (
    <AdvantagesBackgroundStyled color={backgroundColor}>
      <Typography.Headline6 center>{title}</Typography.Headline6>
      <Spacer y={"m"} />
      <Grid
        container
        alignItems={"flex-start"}
        columnSpacing={theme.spacings.l}
        justifyContent={"center"}
        rowSpacing={theme.spacings.xs}
      >
        {advantages.map(({ description, iconName }, index) => (
          <Grid
            key={index}
            container
            item
            xs={12}
            sm={6}
            md={4}
            justifyContent={"center"}
          >
            <Advantage description={description} iconName={iconName} />
          </Grid>
        ))}
      </Grid>
    </AdvantagesBackgroundStyled>
  );
};

export default Advantages;
