import { EmployeeStatus } from "../../types/employees";
import { StyledTag } from "./styled";

export function getEmployeeStatusDescription(employeeStatus: string) {
  return {
    [EmployeeStatus.Active]: "Ativo",
    [EmployeeStatus.Inactive]: "Inativo",
    [EmployeeStatus.Expired]: "Expirado",
  }[employeeStatus];
}

export const StatusLabel = ({ status }) => {
  const translatedStatus = getEmployeeStatusDescription(status);

  return <StyledTag status={status}>{translatedStatus}</StyledTag>;
};
