import { trpc } from "../../../api/client";

interface GetBillFileTemplateInput {
  companyId: string;
  startDate: string;
  endDate: string;
  enabled: boolean;
  onError: (error: any) => void;
  onSuccess: (data: GetBillFileTemplateResponse) => void;
}

export interface GetBillFileTemplateResponse {
  fileName: string;
  billFileTemplateURL: string;
  billFileTemplatePath: string;
}

export const getBillFileTemplateUseCase = ({
  companyId,
  startDate,
  endDate,
  enabled,
  onError,
  onSuccess,
}: GetBillFileTemplateInput) => {
  const { data, isFetching } = trpc.installments.getBillFileTemplate.useQuery(
    {
      companyId,
      startDate,
      endDate,
    },
    {
      refetchOnWindowFocus: false,
      enabled,
      onError,
      onSuccess,
    }
  );

  return {
    data,
    isLoading: isFetching,
  };
};
