import { trpc } from "../../../api/client";
import { Bill } from "../viewModel/useCreateBillPageViewModel";

interface ValidateBillFileUseCaseParams {
  companyId: string;
  billFileTemplatePath: string;
  billFilePath: string;
  onSuccess: (
    validateBillFileUseCaseData: ValidateBillFileUseCaseResponse
  ) => void;
  onError: () => void;
}

export interface ValidateBillFileUseCaseResponse {
  succeededQuantity: number;
  failedQuantity: number;
  errors: BillFileValidationError[];
  bill: Bill;
}

interface BillFileValidationError {
  line: number;
  message?: string;
}

export const validateBillFileUseCase = ({
  companyId,
  billFileTemplatePath,
  billFilePath,
  onSuccess,
  onError,
}: ValidateBillFileUseCaseParams) => {
  const { data, refetch, isRefetching } =
    trpc.fileProcessing.validateBillFile.useQuery(
      {
        companyId,
        billFileTemplatePath,
        billFilePath,
      },
      {
        refetchOnWindowFocus: false,
        enabled: false,
        onSuccess,
        onError,
      }
    );
  return {
    data,
    isLoading: isRefetching,
    handleValidateBillFile: refetch,
  };
};
