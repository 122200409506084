import { Skeleton } from "@flash-tecnologia/hros-web-ui-v2";
import { Grid } from "@mui/material";
import { useTheme } from "styled-components";
import Spacer from "../../../../../../components/Spacer";

const LoadingState = () => {
  const theme = useTheme();
  return (
    <>
      <Skeleton animation={"wave"} width={"100%"} height={"30px"} />
      <Spacer y={"l"} />
      <Grid container rowSpacing={theme.spacings.xs2}>
        {new Array(2).fill(1).map(() => {
          return (
            <>
              <Grid item xs={12}>
                <Skeleton animation={"wave"} width={"60%"} height={"28px"} />
                <Spacer y={"xs1"} />
              </Grid>
              {new Array(4).fill(1).map(() => {
                return (
                  <Grid item xs={6}>
                    <Skeleton
                      animation={"wave"}
                      width={"70%"}
                      height={"20px"}
                    />
                    <Spacer y={"xs4"} />
                    <Skeleton
                      animation={"wave"}
                      width={"30%"}
                      height={"15px"}
                    />
                  </Grid>
                );
              })}
              <Grid item xs={12}>
                <Spacer y={"m"} />
              </Grid>
            </>
          );
        })}
      </Grid>
    </>
  );
};

export default LoadingState;
