import styled, { type DefaultTheme } from "styled-components";

type SpacerProps = {
  x?: keyof DefaultTheme["spacings"];
  y?: keyof DefaultTheme["spacings"];
  useMargin?: boolean;
};

export default styled.div<SpacerProps>`
  ${(p) => (!p.useMargin ? "width" : "margin-right")}: ${(p) =>
    p.x ? p.theme.spacings[p.x] : 0};
  ${(p) => (!p.useMargin ? "height" : "margin-top")}: ${(p) =>
    p.y ? p.theme.spacings[p.y] : 0};
`;
