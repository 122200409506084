import { dayjs } from "@flash-tecnologia/hros-web-ui-v2";
import { useSelectedCompany } from "@flash-tecnologia/hros-web-utility";
import { LoanRequest } from "../../../types/loanRequests";
import { getLoanRequestStatusTagProps } from "../../../utils/loanRequests/getLoanStatusTagProps";
import { cpfMask } from "../../../utils/masks/formatCPF";
import { toCurrency } from "../../../utils/masks/toCurrency";
import {
  EmployeeFields,
  LoanRequestDetailsSection,
  LoanRequestFields,
} from "../components/LoanRequestsTab/LoanRequestDetailsDrawer";
import { getLoanRequestDetailsUseCase } from "../useCases/getLoanRequestDetailsUseCase";

export function useLoanRequestDetailsViewModel(
  selectedLoanRequest?: LoanRequest
) {
  const { selectedCompany } = useSelectedCompany();

  const { data: loanRequestDetails, isLoading } = getLoanRequestDetailsUseCase(
    selectedCompany?.id,
    selectedLoanRequest?.id ?? ""
  );

  const loanRequestStatusTagProps =
    selectedLoanRequest &&
    getLoanRequestStatusTagProps(selectedLoanRequest.status);

  const getLoanDetailsSection = (loanRequestDetails) => {
    const interestRatePercentage = loanRequestDetails.interestRate * 100;
    return {
      iconType: "IconCurrencyDollar",
      title: "Detalhes do empréstimo",
      fields: {
        [LoanRequestFields.INTEREST_RATE]: {
          title: "Taxa (a.m.)",
          description: loanRequestDetails.interestRate
            ? `${interestRatePercentage.toLocaleString("pt-BR", {
                maximumFractionDigits: 2,
                minimumFractionDigits: 2,
              })} %`
            : "---",
        },
        [LoanRequestFields.INSTALLMENTS_QUANTITY]: {
          title: "Número de parcelas",
          description: loanRequestDetails.installmentsQuantity
            ? loanRequestDetails.installmentsQuantity.toString()
            : "---",
        },
        [LoanRequestFields.INSTALLMENT_AMOUNT]: {
          title: "Valor da parcela",
          description: loanRequestDetails.installmentAmount
            ? toCurrency(loanRequestDetails.installmentAmount)
            : "---",
        },
        [LoanRequestFields.CREATED_AT]: {
          title: "Data da solicitação",
          description: dayjs(loanRequestDetails.createdAt).format(
            "DD/MM/YYYY, HH:mm"
          ),
        },
      },
    } as LoanRequestDetailsSection;
  };

  const getEmployeeDetailsSection = (loanRequestEmployee) => {
    return {
      iconType: "IconUser",
      title: "Detalhes da pessoa",
      fields: {
        [EmployeeFields.CPF]: {
          title: "CPF",
          description: cpfMask(loanRequestEmployee.cpf),
        },
        [EmployeeFields.HIRED_AT]: {
          title: "Data de admissão",
          description: dayjs(loanRequestEmployee.hiredAt).format("DD/MM/YYYY"),
        },
        [EmployeeFields.NET_SALARY]: {
          title: "Salário líquido",
          description: toCurrency(loanRequestEmployee.netSalary),
        },
        [EmployeeFields.GROSS_SALARY]: {
          title: "Salário bruto",
          description: toCurrency(loanRequestEmployee.grossSalary),
        },
      },
    } as LoanRequestDetailsSection;
  };

  const detailsSections: LoanRequestDetailsSection[] = [];

  if (loanRequestDetails) {
    const loanDetailsSection = getLoanDetailsSection(loanRequestDetails);
    const employeeDetailsSection = getEmployeeDetailsSection(
      loanRequestDetails.employee
    );

    detailsSections.push(loanDetailsSection, employeeDetailsSection);
  }

  return {
    isLoading,
    employeeName: loanRequestDetails?.employee.name,
    loanRequestValue:
      loanRequestDetails && toCurrency(loanRequestDetails.value),
    statusTagProps: loanRequestStatusTagProps,
    detailsSections,
  };
}
