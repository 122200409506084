import { Card } from "@atoms/Card";
import { Grid, LinearProgress } from "@mui/material";
import { useTheme } from "styled-components";
import Spacer from "../../../../../../components/Spacer";
import Typography from "../../../../../../components/Typography";
import FlexBox from "../../../../../../components/atoms/FlexBox";
import MessageContent, { MessageContentProps } from "../MessageContent";

type ValidateBillFileSuccessContentProps = {
  messageContentProps: MessageContentProps;
};

const ValidateBillFileSuccessContent = (
  props: ValidateBillFileSuccessContentProps
) => {
  const { messageContentProps } = props;
  const theme = useTheme();
  return (
    <>
      <Card>
        <MessageContent
          body={messageContentProps.body}
          shapeIconProps={messageContentProps.shapeIconProps}
          description={messageContentProps.description}
          title={messageContentProps.title}
        />
        <Grid item xs={12} md={6} color={theme.colors.feedback.success[40]}>
          <FlexBox gap={"xs3"} alignItems={"center"}>
            <FlexBox.Item flexGrow={1}>
              <LinearProgress
                variant="determinate"
                value={100}
                color="inherit"
              />
            </FlexBox.Item>
            <FlexBox.Item>
              <Typography.Body4 weight={400}>{`${100}%`}</Typography.Body4>
            </FlexBox.Item>
          </FlexBox>
          <Spacer y="l" />
        </Grid>
      </Card>
    </>
  );
};

export default ValidateBillFileSuccessContent;
