import { trpc } from "../../api/client";

interface GetAdminUseCaseInput {
  companyId: string;
  onError?: (error) => void;
  onSuccess?: () => void;
}

export const getAdminUseCase = ({
  companyId,
  onError,
  onSuccess,
}: GetAdminUseCaseInput) => {
  const { data, refetch, isFetching } = trpc.employees.getAdmin.useQuery(
    { companyId },
    { refetchOnWindowFocus: false, enabled: false, onError, onSuccess }
  );
  return {
    data,
    isLoading: isFetching,
    handleGetAdmin: refetch,
  };
};
