import {
  BigNumber as BaseBigNumber,
  LinkButton,
} from "@flash-tecnologia/hros-web-ui-v2";
import styled from "styled-components";

export const LinkButtonContainer = styled.div`
  text-align: left;
`;

export const LinkButtonStyled = styled(LinkButton)`
  opacity: 1 !important;
  text-align: left;
`;

export const BigNumber = styled(BaseBigNumber)<{ blur?: boolean }>`
  min-width: 30%;
  opacity: ${({ blur: isFiltered }) => (isFiltered ? 0.5 : 1)};
`;
