import { RequestContactInputSchema } from "server/src/routers/trpc/landingPage/requestContact";
import { trpc } from "../../../api/client";
import { z } from "zod";

interface RequestContactUseCaseParams {
  onError?: () => void;
  onSuccess?: () => void;
}

export const requestContactUseCase = ({
  onSuccess,
  onError,
}: RequestContactUseCaseParams) => {
  const requestContactMutation = trpc.landingPage.requestContact.useMutation({
    onSuccess,
    onError,
  });
  return {
    requestContactMutation: function (
      input: z.infer<typeof RequestContactInputSchema>
    ) {
      requestContactMutation.mutate(input);
    },
    isLoading: requestContactMutation.isLoading,
  };
};
