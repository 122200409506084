import { Icons } from "@flash-tecnologia/hros-web-ui-v2";
import { useTheme } from "styled-components";
import Typography from "../../Typography";
import FlexBox from "@atoms/FlexBox";
import { UserTableCellWrapperStyled } from "./styled";

export interface UserTableCellProps {
  document: string;
  name: string;
}

const UserTableCell = ({ document, name }: UserTableCellProps) => {
  const theme = useTheme();
  return (
    <UserTableCellWrapperStyled>
      <Typography.Body3 color={theme.colors.neutral40}>{name}</Typography.Body3>
      <FlexBox gap="xs5">
        <Icons
          name="IconId"
          fill={"transparent"}
          size={20}
          color={theme.colors.neutral70}
        />
        <Typography.Body4 color={theme.colors.neutral60}>
          {document}
        </Typography.Body4>
      </FlexBox>
    </UserTableCellWrapperStyled>
  );
};

export default UserTableCell;
