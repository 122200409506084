import { DateTime } from "luxon";

export function formatDateTime(date: Date): string {
  return DateTime.fromJSDate(date)
    .setZone("America/Sao_Paulo")
    .toFormat("dd/LL/yy' às 'HH:mm");
}

export function formatDate(date: Date): string {
  return DateTime.fromJSDate(date)
    .setZone("America/Sao_Paulo")
    .toFormat("dd/LL/yyyy");
}

export function formatDateWithoutYear(date: Date): string {
  return DateTime.fromJSDate(date)
    .setZone("America/Sao_Paulo")
    .toFormat("dd/LL");
}

export function hasExpiredDate(daysToExpire: number, lastUpdatedAt?: string) {
  if (!lastUpdatedAt) {
    return true;
  }

  const daysFromNow =
    DateTime.fromJSDate(new Date(lastUpdatedAt)).diffNow("days").days * -1;

  return daysFromNow > daysToExpire;
}
