import FlexBox from "@atoms/FlexBox";
import {
  Icons,
  OptionsTagFilter,
  PillButton,
  tableControllers,
  Tooltip,
} from "@flash-tecnologia/hros-web-ui-v2";
import { PaginationState } from "@flash-tecnologia/hros-web-ui-v2/dist/components/Table/components/Pagination";
import { useSelectedCompany } from "@flash-tecnologia/hros-web-utility";
import {
  GetEmployeesOutput,
  getEmployeesUseCase,
} from "@pages/PayrollLoan/useCases/getEmployeesUseCase";
import { DeactivationCandidate } from "@pages/PayrollLoan/viewModel/useDeactivateEmployeesViewModel";
import { dayjs } from "@utils/dayjs";
import { cpfMask } from "@utils/masks/formatCPF";
import { formatDate } from "@utils/masks/formatDate";
import { toCurrency } from "@utils/masks/toCurrency";
import { useDebouncedValue } from "@utils/useDebouncedValue";
import { useEffect, useState } from "react";
import { useTheme } from "styled-components";
import UserTableCell from "../../../../../components/molecules/UserTableCell";
import {
  getEmployeeStatusDescription,
  StatusLabel,
} from "../../../../../components/Tag";
import Typography from "../../../../../components/Typography";
import { EmployeeStatus } from "../../../../../types/employees";
import { getEmployeesCountUseCase } from "@pages/PayrollLoan/useCases/getEmployeesCountUseCase";
import { ButtonsContainer } from "../styled";

const STATUS_COLUMN_ACCESSOR_KEY = "status";
const STATUS_COLUMN_LABEL = "Status";

const SUPPORTED_EMPLOYEE_STATUSES_FILTER = [
  EmployeeStatus.Active,
  EmployeeStatus.Expired,
];

const INITIAL_PAGINATION_STATE = {
  pageNumber: 1,
  pageSize: 10,
};

const TABLE_PAGE_SIZE_OPTIONS = [
  { label: "100 itens", value: 100 },
  { label: "50 itens", value: 50 },
  { label: "25 itens", value: 25 },
  { label: "10 itens", value: 10 },
];

export function useEmployeesTableViewModel(input: {
  onDeactivateClick: (
    deactivationCandidates: DeactivationCandidate[],
    onDeactivateSuccess?: () => void
  ) => void;
  onEditEmployeeClick: (
    employeeInfo: GetEmployeesOutput["employees"][0],
    onUpsertSuccess?: () => void
  ) => void;
}) {
  const { onDeactivateClick, onEditEmployeeClick } = input;
  const { selectedCompany } = useSelectedCompany();
  const theme = useTheme();
  const [searchInput, setSearchInput] = useState("");
  const [statusFilter, setStatusFilter] = useState<string[]>(
    SUPPORTED_EMPLOYEE_STATUSES_FILTER
  );
  const [hideSingleActionButtons, setHideSingleActionButtons] =
    useState<boolean>(false);

  const [forceTableSearchRenderKey, setForceTableSearchRenderKey] =
    useState<string>();
  const [forceTableFilterRenderKey, setForceTableFilterRenderKey] =
    useState<string>();

  const searchInputDebouncedValue = useDebouncedValue<string>({
    inputValue: searchInput,
    delayInMillis: 500,
  });

  const [paginationState, setPaginationState] = useState<PaginationState>(
    INITIAL_PAGINATION_STATE
  );

  useEffect(() => {
    if (searchInputDebouncedValue) {
      setStatusFilter(SUPPORTED_EMPLOYEE_STATUSES_FILTER);
      setForceTableFilterRenderKey(`${dayjs()}`);
    }
    setPaginationState(INITIAL_PAGINATION_STATE);
  }, [searchInputDebouncedValue]);

  const getEmployeesData = getEmployeesUseCase(
    selectedCompany.id,
    searchInputDebouncedValue,
    {
      currentPage: paginationState.pageNumber - 1,
      pageSize: paginationState.pageSize,
    },
    statusFilter
  );

  const getEmployeesOutput: GetEmployeesOutput = getEmployeesData.data || {
    employees: [],
    total: 0,
  };

  const {
    data: employeesCountData,
    isLoading: isGetEmployessCountLoading,
    refetch: refetchEmployeesCount,
  } = getEmployeesCountUseCase(selectedCompany.id);

  const getTooltipText = (status) => {
    if (status === EmployeeStatus.Active) {
      return "O colaborador pode contratar o Consignado";
    } else if (status === EmployeeStatus.Inactive) {
      return "O colaborador ainda não atingiu o tempo mínimo para contratar o Consignado ou está desativado";
    }
    return "O colaborador precisa ser atualizado para contratar o Consignado";
  };

  const handleFilter = (input: any) => {
    const filters = input as { name: string; values: EmployeeStatus[] }[];

    if (!filters.length) {
      setStatusFilter(SUPPORTED_EMPLOYEE_STATUSES_FILTER);
    } else {
      const newStatusFilter = filters.find(
        ({ name }) => name === STATUS_COLUMN_ACCESSOR_KEY
      );

      if (newStatusFilter?.values?.[0]) {
        setSearchInput("");
        setStatusFilter([newStatusFilter.values[0]]);
        setForceTableSearchRenderKey(`${dayjs()}`);
      } else {
        setStatusFilter(SUPPORTED_EMPLOYEE_STATUSES_FILTER);
      }
    }
    setPaginationState(INITIAL_PAGINATION_STATE);
    setForceTableFilterRenderKey(`${dayjs()}`);
    table.resetSelected();
  };

  const handlePaginationChange = ({ pageSize, pageNumber }) => {
    table.resetSelected();
    setPaginationState((prev) => {
      if (pageSize !== prev.pageSize) {
        return {
          ...INITIAL_PAGINATION_STATE,
          pageSize,
        };
      }
      return {
        ...prev,
        pageSize,
        pageNumber,
      };
    });
  };

  const handleSearchInputChange = (event) => {
    if (event?.target) {
      setSearchInput(event.target.value);
    }
  };

  const getTableFilters = () => {
    const label = STATUS_COLUMN_LABEL;
    const name = STATUS_COLUMN_ACCESSOR_KEY;

    const options = SUPPORTED_EMPLOYEE_STATUSES_FILTER.reduce<
      OptionsTagFilter[]
    >((acc, employeeStatus: string) => {
      const employeeStatusDescription =
        getEmployeeStatusDescription(employeeStatus);
      if (employeeStatusDescription) {
        acc.push({ label: employeeStatusDescription, value: employeeStatus });
      }
      return acc;
    }, []);

    return [
      {
        label,
        name,
        options,
        ...(statusFilter.length === 1 && {
          initialSelectedOption: statusFilter[0],
        }),
      },
    ];
  };

  const table = tableControllers.useTableColumns<
    GetEmployeesOutput["employees"][number]
  >({
    data: getEmployeesOutput.employees,
    total: getEmployeesOutput.total,
    options: { selectable: true },
    columns: [
      {
        header: "Nome",
        accessorKey: "name",
        cell: ({ cell }) => {
          const { name, cpf } = cell.row.original;
          const documentNumberFormatted = cpfMask(cpf);
          return (
            <UserTableCell name={name} document={documentNumberFormatted} />
          );
        },
      },
      {
        header: STATUS_COLUMN_LABEL,
        accessorKey: STATUS_COLUMN_ACCESSOR_KEY,
        cell: ({ cell }) => {
          const status = cell.row.original.status;
          const tooltipText = getTooltipText(status);
          return (
            <FlexBox gap={"xs5"} justifyContent="space-between">
              <StatusLabel status={status} />
              <Tooltip title={tooltipText} arrow={true}>
                <div>
                  <Icons
                    name="IconInfoCircle"
                    fill="transparent"
                    color={theme.colors.neutral50}
                  />
                </div>
              </Tooltip>
            </FlexBox>
          );
        },
      },
      {
        header: "Expiração",
        accessorKey: "expireDate",
        cell: ({ cell }) => {
          const expireDate = cell.row.original.expireDate;
          return (
            <Typography.Body4
              color={theme.colors.neutral[40]}
              center
              style={{ whiteSpace: "nowrap" }}
            >
              {expireDate && formatDate(new Date(expireDate))}
            </Typography.Body4>
          );
        },
      },
      {
        header: "Admissão",
        accessorKey: "hiredAt",
        cell: ({ cell }) => {
          const hiredAt = cell.row.original.hiredAt;
          return (
            <Typography.Body4
              color={theme.colors.neutral[40]}
              style={{ whiteSpace: "nowrap" }}
            >
              {hiredAt && formatDate(new Date(hiredAt))}
            </Typography.Body4>
          );
        },
      },
      {
        header: "Salário bruto (R$)",
        accessorKey: "grossSalary",
        cell: ({ cell }) => {
          const grossSalary = cell.row.original.grossSalary;
          return (
            <Typography.Body4
              center
              color={theme.colors.neutral[40]}
              style={{ whiteSpace: "nowrap" }}
            >
              {grossSalary && toCurrency(grossSalary, true)}
            </Typography.Body4>
          );
        },
      },
      {
        header: "Salário líquido (R$)",
        accessorKey: "netSalary",
        cell: ({ cell }) => {
          const netSalary = cell.row.original.netSalary;
          return (
            <Typography.Body4
              center
              color={theme.colors.neutral[40]}
              style={{ whiteSpace: "nowrap" }}
            >
              {netSalary && toCurrency(netSalary, true)}
            </Typography.Body4>
          );
        },
      },
      {
        header: "Margem consignável (R$)",
        accessorKey: "limitPerInstallment",
        cell: ({ cell }) => {
          const limitPerInstallment = cell.row.original.limitPerInstallment;
          return (
            <Typography.Body4
              center
              color={theme.colors.neutral[40]}
              style={{ whiteSpace: "nowrap" }}
            >
              {limitPerInstallment && toCurrency(limitPerInstallment, true)}
            </Typography.Body4>
          );
        },
      },
      {
        header: "Ações",
        id: "actions",
        sticky: "right",
        cell: ({ cell }) => {
          const employee = cell.row.original;
          const { id, name, status, cpf, companyId } = employee;
          const deactivationCandidates: DeactivationCandidate[] = [
            { employeeId: id, name, documentNumber: cpf, companyId },
          ];
          const isDeactivationAllowed =
            !hideSingleActionButtons &&
            [EmployeeStatus.Active, EmployeeStatus.Expired]
              .map((_) => _.toString())
              .includes(status);
          return (
            <FlexBox flexDirection="row">
              {!hideSingleActionButtons && (
                <ButtonsContainer>
                  <PillButton
                    icon="IconPencil"
                    onClick={() =>
                      onEditEmployeeClick(employee, () => {
                        getEmployeesData.refetch();
                        refetchEmployeesCount();
                      })
                    }
                    size="medium"
                    variant="default"
                    type="secondary"
                  />
                </ButtonsContainer>
              )}
              {!hideSingleActionButtons && (
                <ButtonsContainer>
                  <PillButton
                    icon="IconTrash"
                    onClick={() =>
                      onDeactivateClick(deactivationCandidates, () => {
                        getEmployeesData.refetch();
                        refetchEmployeesCount();
                      })
                    }
                    size="medium"
                    variant="default"
                    type="secondary"
                    disabled={!isDeactivationAllowed}
                  />
                </ButtonsContainer>
              )}
            </FlexBox>
          );
        },
      },
    ],
    pagination: paginationState,
    onPaginationChange: handlePaginationChange,
  });

  const handleMassDeactivationSuccess = () => {
    table.setAllSelected(false);
    table.resetSelected();
    getEmployeesData.refetch();
    refetchEmployeesCount();
  };

  const handleMassDeactivationClick = () => {
    let deactivationCandidates: DeactivationCandidate[] = [];
    if (table.selected.allSelected) {
      const candidatesToExclude = table.selected.selected;

      deactivationCandidates = table.rows.reduce<DeactivationCandidate[]>(
        (acc, row) => {
          const shouldExcludeCandidate = candidatesToExclude.find(
            ({ original: { id } }) => id === row.original.id
          );
          if (!shouldExcludeCandidate) {
            const { id, name, cpf, companyId } = row.original;
            acc.push({ employeeId: id, name, documentNumber: cpf, companyId });
          }
          return acc;
        },
        []
      );
    } else {
      deactivationCandidates = table.selected.selected.map(
        ({
          original: { id: employeeId, name, cpf: documentNumber, companyId },
        }) => ({ employeeId, documentNumber, companyId, name })
      );
    }

    if (deactivationCandidates.length) {
      onDeactivateClick(deactivationCandidates, handleMassDeactivationSuccess);
    }
  };

  useEffect(() => {
    setHideSingleActionButtons(
      table.selected.allSelected || !!table.selected.selected.length
    );
  }, [table.selected.allSelected, table.selected.selected]);

  const totalSelected = table.selected.allSelected
    ? table.rows.length - table.selected.selected.length
    : table.selected.selected.length;

  return {
    forceTableFilterRenderKey,
    forceTableSearchRenderKey,
    isLoading: getEmployeesData.isLoading || getEmployeesData.isFetching,
    onPaginationChange: handlePaginationChange,
    paginationState,
    table,
    tableFilters: getTableFilters(),
    totalCount: getEmployeesOutput.total,
    totalSelected,
    onFilter: handleFilter,
    statusFilter,
    onMassDeactivationClick: handleMassDeactivationClick,
    onSearchInputChange: handleSearchInputChange,
    pageSizeOptions: TABLE_PAGE_SIZE_OPTIONS,
    employeesCount: employeesCountData,
    isGetEmployessCountLoading,
  };
}
