export const PAYROLL_LOAN_GENERIC_ERROR_MESSAGE =
  "Tente novamente mais tarde. Se o problema persistir, entre em contato com o suporte.";

export enum PayrollLoanErrorCodes {
  INSUFFICIENT_TERMINATION_DISCOUNT_VALUE = "INSUFFICIENT_TERMINATION_DISCOUNT_VALUE",
  LANDING_PAGE_GET_USER_DATA_FAILED = "LANDING_PAGE_GET_USER_DATA_FAILED",
  LANDING_PAGE_GET_COMPANY_DATA_FAILED = "LANDING_PAGE_GET_COMPANY_DATA_FAILED",
  LOAN_STEP_NOT_FOUND = "LOAN_STEP_NOT_FOUND",
  REJECT_LOAN_REQUEST_FAILED = "REJECT_LOAN_REQUEST_FAILED",
  EMPTY_INSTALLMENTS_BILL_FILE = "EMPTY_INSTALLMENTS_BILL_FILE",
}

export const getPayrollLoanErrorUserFriendlyMessage = (
  loanRequestErrorCode?: string
) => {
  if (!loanRequestErrorCode) {
    return PAYROLL_LOAN_GENERIC_ERROR_MESSAGE;
  }

  return (
    {
      [PayrollLoanErrorCodes.EMPTY_INSTALLMENTS_BILL_FILE]:
        "Não há parcelas para o período solicitado.",
      [PayrollLoanErrorCodes.INSUFFICIENT_TERMINATION_DISCOUNT_VALUE]:
        "Valor descontado é insuficiente para rescisão",
      [PayrollLoanErrorCodes.LOAN_STEP_NOT_FOUND]:
        "Empréstimo não possui etapa de reprovação. Entre em contato com o suporte",
      [PayrollLoanErrorCodes.REJECT_LOAN_REQUEST_FAILED]:
        "Falha na reprovação. Entre em contato com o suporte",
      [PayrollLoanErrorCodes.LANDING_PAGE_GET_USER_DATA_FAILED]:
        "Falha em obter dados de usuário. Entre em contato com o suporte",
      [PayrollLoanErrorCodes.LANDING_PAGE_GET_COMPANY_DATA_FAILED]:
        "Falha em obter dados da empresa. Entre em contato com o suporte",
    }[loanRequestErrorCode] ?? PAYROLL_LOAN_GENERIC_ERROR_MESSAGE
  );
};
